import './ProjectsMinimap.css';
import '../../css/General.css';
import '../Projeto1/Content/Projeto1_Content.css';
import React, { useEffect, useRef, useState, useCallback } from "react";

export const ProjectsMinimap = (props) =>{

  const refBoxMinimap1 = useRef(null);

  const refItemMinimap1 = useRef(null);
  const refItemMinimap2 = useRef(null);
  const refItemMinimap3 = useRef(null);
  const refItemMinimap4 = useRef(null);
  const refItemMinimap5 = useRef(null);
  const refItemMinimap6 = useRef(null);
  const refItemMinimap7 = useRef(null);
  const refItemMinimap8 = useRef(null);
  const refItemMinimap9 = useRef(null);
  const refItemMinimap10 = useRef(null);
  const refItemMinimap11 = useRef(null);
  const refItemMinimap12 = useRef(null);

  const [currentIndex, setCurrentIndex] = useState(props.cIndex);

  var isMobile = window.matchMedia("(max-width: 768px)");
  const multiplicadorMinimapa = isMobile.matches ? 0.07:0.1;

  useEffect(() => {
    setCurrentIndex(props.cIndex);
  });

    /*function handleCallBack(e){
      props.handleCallBack(e, currentIndex);
      setCurrentIndex(e);
        
      refBoxMinimap1.current.style.transform = `translateY(${-(props.scrollPosition*.04)*e}vh)`;
    }*/

    function handleCallBack(e){
      props.handleCallBack(e, currentIndex);
      setCurrentIndex(e);
        
      if(e != currentIndex)
      {
        //console.log(-(props.scrollPosition)*e);
        refBoxMinimap1.current.style.transform = `translateY(${-(props.scrollPosition*multiplicadorMinimapa)*e}vh)`;
        //refBoxMinimap1.current.style.transform = `translateY(${-20}vh)`;
      }
    }

    const sections = props.sections;

    function GetRef(index){
      switch(index){
        case 0:
          return refItemMinimap1;
    
          case 1:
          return refItemMinimap2;
    
          case 2:
          return refItemMinimap3;
    
          case 3:
          return refItemMinimap4;
     
          case 4:
          return refItemMinimap5;
   
          case 5:
          return refItemMinimap6;

          case 6:
          return refItemMinimap7;
 
          case 7:
          return refItemMinimap8;
      
          case 8:
          return refItemMinimap9;
     
          case 9:
          return refItemMinimap10;
     
          case 10:
          return refItemMinimap11;

          case 11:
          return refItemMinimap12;

      }

      return refItemMinimap1;
    }

    return(
        <div className="projects-minimap-container">
            {sections.map((section, index) => (
                <div
                    ref={GetRef(index)}
                    key={index}
                    className={"projects-section-minimap" + (index + 1)}
                    onClick={() => handleCallBack(index)}
                >
                    {section}
                </div>
            ))}

            <div ref={refBoxMinimap1} className='projects-box-minimap' 
            style={{
                        transform: `translateY(${props.scrollPosition > -85 ? 0:-((props.scrollPosition+85) * (0.070))}vh)`,
                    }}
            >
            </div>
        </div>
    );
}