//css
import './App.css';

//react imports
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, NavLink, Navigate } from 'react-router-dom';
import SlideRoutes from 'react-slide-routes';

//main generic components
import { Home } from './components/Home';
import { NavBar } from './components/NavBar';
//import { Intro } from './components/Intro';

//projects components
import { Projeto1 } from './components/Projeto1/Projeto1';
import { Projeto2 } from './components/Projeto2/Projeto2';
import { Projeto3 } from './components/Projeto3/Projeto3';
import { Projeto4 } from './components/Projeto4/Projeto4';
import { Projeto5 } from './components/Projeto5/Projeto5';
import { Projeto6 } from './components/Projeto6/Projeto6';
import { Projeto7 } from './components/Projeto7/Projeto7';
import { Projeto8 } from './components/Projeto8/Projeto8';
import { Projeto9 } from './components/Projeto9/Projeto9';
import { Projeto10 } from './components/Projeto10/Projeto10';

import { Galeria } from './components/Galeria/Galeria';

//main menu components
import { MainMenuSlider } from './components/MainMenuSlider';

//about components
import { AboutSP } from './components/About/AboutSP/AboutSP';
import { LandscapeWarningMobile } from './components/Generic/LandscapeWarningMobile';
import { VihupoleTexto1 } from './components/Tests/VihupoleTexto1';
import { VihupoleTexto2 } from './components/Tests/VihupoleTexto2';
import { Vihupole3 } from './components/Tests/Vihupole3';

//import { TransitionProvider } from './context/TransitionContext';
//import TransitionComponent from './components/Transitions/Transition';

//TESTES
/*import { ParallaxTeste } from './components/Tests/ParallaxTeste';
import { P1 } from './components/Tests/P1';
import { P2 } from './components/Tests/P2';

import TransitionComponent from './components/Tests/TransitionComponent';
import ComponentA from './components/Tests/ComponentA';
import ComponentB from './components/Tests/ComponentB';
import Page1 from './components/Tests/Page1';
import Page2 from './components/Tests/Page2';*/

//Não está a ser utilizado - remover posteriormente
/*const imagens = [
  {url: 'https://images.unsplash.com/photo-1524781289445-ddf8f5695861?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80', title: 'Projeto 1'},
  {url: 'https://images.unsplash.com/photo-1610194352361-4c81a6a8967e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1674&q=80', title: 'Projeto 2'},
  {url: 'https://images.unsplash.com/photo-1618202133208-2907bebba9e1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80', title: 'Projeto 3'},
  {url: 'https://images.unsplash.com/photo-1495805442109-bf1cf975750b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80', title: 'Projeto 4'},
  {url: 'https://images.unsplash.com/photo-1548021682-1720ed403a5b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80', title: 'Projeto 5'},
  {url: 'https://images.unsplash.com/photo-1496753480864-3e588e0269b3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2134&q=80', title: 'Projeto 6'},
  {url: 'https://images.unsplash.com/photo-1613346945084-35cccc812dd5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1759&q=80', title: 'Projeto 7'},
  {url: 'https://images.unsplash.com/photo-1516681100942-77d8e7f9dd97?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80', title: 'Projeto 8'},
];*/

const App = () => {

  //window.$executarIntro = true;
  window.$slideAnimation = 0;
  window.$primeiraEntradaSlider = true;

  const [isMenuSliderVisible, setIsMenuSliderVisible ] = useState(false);
  const [animacao, setAnimacao] = useState('vertical-slide');

  const containerStyles = {
    width: "100%",
    height: "100%",
    margin: "0 auto",
    overflow: "hidden",
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsMenuSliderVisible(true);
      window.$executarIntro = false;
    }, 6000)
 
    return () => clearTimeout(timeout)
}, [])

const callback = () => {
  //TODO: eliminar este método posteriormente
  /*if(window.$slideAnimation === 0)
    setAnimacao('vertical-slide')
  else if(window.$slideAnimation === 1)
    setAnimacao('slide');
  else
    setAnimacao('rotate');*/
};

const [isPortrait, setIsPortrait] = useState(window.matchMedia('(orientation: portrait)').matches);
const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 768px)').matches);
const [linguagem, setLinguagem] = useState(localStorage.getItem('language') || 'pt');

useEffect(() => {
  const handleOrientationChange = () => {
    if(isMobile){
      switch (window.screen.orientation.type) {
        case "landscape-primary":
        case "landscape-secondary":
          setIsPortrait(false);
          break;
        case "portrait-secondary":
        case "portrait-primary":
          setIsPortrait(true);
          break;
        default:
          console.log("API para screen orientation não é suportada neste browser.");
      }
    }
  };

  window.addEventListener('orientationchange', handleOrientationChange);

  return () => {
    window.removeEventListener('orientationchange', handleOrientationChange);
  };
}, []);

function callbackLinguagem(linguagem){
  setLinguagem(linguagem);
}

//TODO
/*const isDarkMode = () => 
  window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

  var elementoTabIcon = document.getElementById("tab-icon");
    if(elementoTabIcon != null && elementoTabIcon != undefined){
      if(isDarkMode){
        console.log("DARK THEME");
        elementoTabIcon.setAttribute("href", "url('../public/logo_icon_no_background-01.ico')");
        //elementoTabIcon.href = '%PUBLIC_URL%/logo_icon_no_background-01.ico';
      }
      else{
        console.log("DEFAULT THEME");
        elementoTabIcon.setAttribute("href", "url('../public/logo_icon_no_background-black-01.ico')");
        //elementoTabIcon.href = '%PUBLIC_URL%/logo_icon_no_background-black-01.ico';
      } 
    }*/

  return (  

    <BrowserRouter>
      <div className="App">
        
        {isMobile && !isPortrait && <LandscapeWarningMobile />}

        {/*----MAIN---*/}
        {isMobile && !isPortrait ? <LandscapeWarningMobile />:isMenuSliderVisible && <NavBar callback={callback} linguagem={linguagem}/>}
      
      <Routes>
      {/*<SlideRoutes animation={animacao} duration={1000} timing='ease-out'>*/}
          <Route path="/" element={isMobile && !isPortrait ? <LandscapeWarningMobile />: <Home callbackLinguagem={callbackLinguagem}/>} />
          <Route path="/about" element={isMobile && !isPortrait ? <LandscapeWarningMobile />:<AboutSP />} />
          <Route path="/vihupole" element={isMobile && !isPortrait ? <LandscapeWarningMobile />:<Projeto1 callback={callback} />} />
          <Route path="/santorini" element={isMobile && !isPortrait ? <LandscapeWarningMobile />:<Projeto2 callback={callback}/>} />
          <Route path="/conservatorio" element={isMobile && !isPortrait ? <LandscapeWarningMobile />:<Projeto3 callback={callback}/>} />
          <Route path="/azorestek" element={isMobile && !isPortrait ? <LandscapeWarningMobile />:<Projeto4 callback={callback}/>} />
          <Route path="/andrik" element={isMobile && !isPortrait ? <LandscapeWarningMobile />:<Projeto5 callback={callback}/>} />
          <Route path="/transtech" element={isMobile && !isPortrait ? <LandscapeWarningMobile />:<Projeto6 callback={callback}/>} />
          <Route path="/sanjotec" element={isMobile && !isPortrait ? <LandscapeWarningMobile />:<Projeto7 callback={callback}/>} />
          <Route path="/energia" element={isMobile && !isPortrait ? <LandscapeWarningMobile />:<Projeto8 callback={callback}/>} />
          <Route path="/aap" element={isMobile && !isPortrait ? <LandscapeWarningMobile />:<Projeto9 callback={callback}/>} />
          <Route path="/joana_beauty" element={isMobile && !isPortrait ? <LandscapeWarningMobile />:<Projeto10 callback={callback}/>} />

          {/*REMOVIDA TEMPORARIAMENTE*/}
          {/*<Route path='/galeria' element={isMobile && !isPortrait ? <LandscapeWarningMobile />:<Galeria callback={callback}/>} />*/}


          {/*<Route path="/projeto7" element={<Projeto7 callback={callback}/>} />
          <Route path="/projeto8" element={<Projeto8 callback={callback}/>} />*/}
          <Route path="/projetos" element={isMobile && !isPortrait ? <LandscapeWarningMobile />:<MainMenuSlider callback={callback}/>} />
          {/*<Route path="/teste-next-project" element={<NextProjectComponent />} />*/}


          {/*<Route path="/vihupole1" element={<VihupoleTexto1 />} />
          <Route path="/vihupole2" element={<VihupoleTexto2 />} />
          <Route path="/vihupole3" element={<Vihupole3 />} />*/}

          {/*TESTES
          <Route path="/page1" element={<Page1 />} />
          <Route path="/page2" element={<Page2 />} />

          <Route path="/teste-parallax" element={<TransitionComponent isActive={true}><P1 /></TransitionComponent>} />
          <Route path="/teste-parallax1" element={<TransitionComponent isActive={true}><P2 /></TransitionComponent>} />

          <Route path="/component-a" element={<TransitionComponent isActive={true}><ComponentA /></TransitionComponent>} />
          <Route path="/component-b" element={<TransitionComponent isActive={true}><ComponentB /></TransitionComponent>} />
          
          <Route path="/teste-parallax" element={<P1 />} />
          <Route path="/teste-parallax1" element={<P2 />} />
          <Route path="/teste-parallax99" element={<ParallaxTeste />} />
          */}
          
          <Route path="/*" element={<Navigate replace to="/" />} />
        {/*</SlideRoutes>*/}
        </Routes>

      </div>
    </BrowserRouter>
  );
};

export default App;
