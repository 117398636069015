import React, { useEffect, useState, useRef, useMemo } from 'react';
import '../../../css/General.css';
import './Projeto6_Header.css';
import ContadorProjetos from '../../ContadorProjetos';
import {PiArrowCircleDownThin} from "react-icons/pi";

export const Projeto6_Header_EN = (props) => {

  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);

    /****RESIZING****/
    const handleResize = () => {
      if(window.matchMedia("(max-width: 768px)").matches){
        setIsMobile(true);
      }
      else{
        setIsMobile(false);
      }
    };

    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);   

    /****-----------****/ 


  let child1SetState;
  const invokeChild1 = (setStateCallback) => {
    if (setStateCallback) {
      child1SetState = setStateCallback[1];
    } else {
      child1SetState(1);
    }
  };

    function ScrollDown(){
      props.scrollDownFromHeader();
    }

    return(
        <div className='projeto6-detail-container'>
            <div className='proj6-d-header'>
              <div className='proj6-header-contador'>
                <ContadorProjetos cbFunction={invokeChild1} limiteMaximo={window.$numeroProjetos}/>
              </div>
                <div className='p6-d-h-texto-container' style={{ transition: `transform 1s cubic-bezier(0.215, 0.61, 0.355, 1)`,  transform: `translateY(${props.scrollPosition * 0.2}vh)` }}>
                    <h1 className='p6-d-h-t-title'>
                        <span className='p6-d-h-t-span txt-anim-1'>Transtech</span>
                    </h1>
                    <div className='p6-d-h-t-b1'>
                        <span className='p6-d-h-t-span txt-anim-2 p6-d-h-f'>Lead Designer</span>
                        <p/>
                        {!isMobile ? 
                          <span className='p6-d-h-t-span txt-anim-3 p6-d-h-f-d'>The <a href='https://www.transtech.pt' style={{display: `inline`}}>TRANSTECH</a> project aims to boost, in the North, Center and </span>
                          :
                          <span className='p6-d-h-t-span txt-anim-3 p6-d-h-f-d'>The <a href='https://www.transtech.pt' style={{display: `inline`}}>TRANSTECH</a> project aims to boost, </span>
                        }
                        {!isMobile ? 
                          <span className='p6-d-h-t-span txt-anim-4 p6-d-h-f-d'>Alentejo Region, a set of initiatives aimed at innovation, digital tra-</span>
                          :
                          <span className='p6-d-h-t-span txt-anim-4 p6-d-h-f-d'>in the North, Center and Alentejo Region, </span>
                        }
                        {!isMobile ? 
                          <span className='p6-d-h-t-span txt-anim-5 p6-d-h-f-d'>nsformation and integration into global value chains of Portuguese </span>
                          :
                          <span className='p6-d-h-t-span txt-anim-5 p6-d-h-f-d'>a set of initiatives aimed at innovation, </span>
                        }
                        {!isMobile ? 
                          <span className='p6-d-h-t-span txt-anim-6 p6-d-h-f-d'>companies. </span>
                          :
                          <span className='p6-d-h-t-span txt-anim-6 p6-d-h-f-d'>digital transformation and integration into </span>
                        }
                        {!isMobile ? 
                          <span className='p6-d-h-t-span txt-anim-7 p6-d-h-f-d'>This project consisted on creating it's entire graphic identity such as </span>
                          :
                          <span className='p6-d-h-t-span txt-anim-7 p6-d-h-f-d'>global value chains of Portuguese </span>
                        }
                        {!isMobile ? 
                          <span className='p6-d-h-t-span txt-anim-8 p6-d-h-f-d'>logo creation, website design, programs, credentials, roll ups, pho-</span>
                          :
                          <span className='p6-d-h-t-span txt-anim-8 p6-d-h-f-d'>companies.</span>
                        }
                        {!isMobile ? 
                          <span className='p6-d-h-t-span txt-anim-9 p6-d-h-f-d'>towalls, giveaways, and social media management.</span>
                          :
                          <span className='p6-d-h-t-span txt-anim-9 p6-d-h-f-d'>This project consisted on creating it's entire </span>
                        }
                        {!isMobile ? 
                          <span className='p6-d-h-t-span txt-anim-10 p6-d-h-f-d'></span>
                          :
                          <span className='p6-d-h-t-span txt-anim-10 p6-d-h-f-d'>graphic identity such as logo creation, web- </span>
                        }
                        {!isMobile ? 
                          <span className='p6-d-h-t-span txt-anim-11 p6-d-h-f-d'></span>
                          :
                          <span className='p6-d-h-t-span txt-anim-11 p6-d-h-f-d'>site design, programs, credentials, roll ups, </span>
                        }
                        {!isMobile ? 
                          <span className='p6-d-h-t-span txt-anim-12 p6-d-h-f-d'></span>
                          :
                          <span className='p6-d-h-t-span txt-anim-12 p6-d-h-f-d'>photowalls, giveaways, and social media </span>
                        }
                        {!isMobile ? 
                          <span className='p6-d-h-t-span txt-anim-13 p6-d-h-f-d'></span>
                          :
                          <span className='p6-d-h-t-span txt-anim-13 p6-d-h-f-d'>management.</span>
                          }
                        <p/>
                        {!isMobile ? 
                          <span className='p6-d-h-t-span txt-anim-14 p6-d-h-f-d'>This project took place for over 2 years, during which dozens of </span>
                          :
                          <span className='p6-d-h-t-span txt-anim-14 p6-d-h-f-d'>This project took place for over 2 years, </span>
                        }
                        {!isMobile ? 
                          <span className='p6-d-h-t-span txt-anim-15 p6-d-h-f-d'>Workshops and guided tours on several companies took place,  </span>
                          :
                          <span className='p6-d-h-t-span txt-anim-15 p6-d-h-f-d'>during which dozens of Workshops and  </span>
                        }
                        {!isMobile ? 
                          <span className='p6-d-h-t-span txt-anim-16 p6-d-h-f-d'>which shared information free of charge to all interested parties.</span>
                          :
                          <span className='p6-d-h-t-span txt-anim-16 p6-d-h-f-d'>guided tours on several companies took </span>
                        }
                        {!isMobile ? <span className='p6-d-h-t-span txt-anim-17 p6-d-h-f-d'></span>:<span className='p6-d-h-t-span txt-anim-17 p6-d-h-f-d'>place, which shared information free of </span>}
                        {!isMobile ? <span className='p6-d-h-t-span txt-anim-18 p6-d-h-f-d'></span>:<span className='p6-d-h-t-span txt-anim-18 p6-d-h-f-d'>charge to all interested parties.</span>}
                        
                    </div>
                    <div className='p6-d-h-t-b2'>
                        {!isMobile ? 
                          <span className='p6-d-h-t-span txt-anim-19 p6-d-h-f'>Developed at the company Outside The Box, for the companies </span>
                          :
                          <span className='p6-d-h-t-span txt-anim-19 p6-d-h-f'>Developed at the company Outside The </span>
                        }
                        {!isMobile ? 
                          <span className='p6-d-h-t-span txt-anim-20 p6-d-h-f'>PortusPark and Tecparques, responsible for the project.</span>
                          :
                          <span className='p6-d-h-t-span txt-anim-20 p6-d-h-f'>Box, for the companies PortusPark and </span>
                        }
                        {!isMobile ? 
                          <span className='p6-d-h-t-span txt-anim-21 p6-d-h-f'>This project also included the contribution of the company Like a pro.</span>
                          :
                          <span className='p6-d-h-t-span txt-anim-21 p6-d-h-f'>Tecparques, responsible for the project.</span>
                        }
                        {!isMobile ? <span className='p6-d-h-t-span txt-anim-22 p6-d-h-f-d'></span>:<span className='p6-d-h-t-span txt-anim-22 p6-d-h-f'>This project also included the contribution </span>}
                        {!isMobile ? <span className='p6-d-h-t-span txt-anim-23 p6-d-h-f-d'></span>:<span className='p6-d-h-t-span txt-anim-23 p6-d-h-f'>of the company Like a pro.</span>}

                    </div>
                    <div className='p6-d-h-t-b3'>
                        <span className='p6-d-h-t-span txt-anim-24 p6-d-h-f s-down-txt' style={{opacity:`0`}} onClick={ScrollDown}>Scroll Down</span>
                        <span className='p6-d-h-t-span txt-anim-24 p6-d-h-f s-down-txt1' style={{opacity:`0`}} onClick={ScrollDown}><PiArrowCircleDownThin size={!isMobile ? 40:30}/></span>
                    </div>
                </div>
            </div>
        </div>
    );
}