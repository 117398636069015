import '../css/Intro.css';

const imagensMobile = [
    {url:"https://live.staticflickr.com/65535/53374082020_b4c27c1818_c.jpg", title:"Santorini"},
    {url:"https://live.staticflickr.com/65535/53373814823_4be3d03ed2_c.jpg", title:"Conservatório"},
    {url:"https://live.staticflickr.com/65535/53373948579_5c3bcba10d_c.jpg", title:"AzoresTek"},
    {url:"https://live.staticflickr.com/65535/53373814813_05ac67705c_c.jpg", title:"Andrik"},
    {url:"https://live.staticflickr.com/65535/53372719962_a5d11d9236_c.jpg", title:"Transtech"},
    {url:"https://live.staticflickr.com/65535/53376297916_59fdb72db2_c.jpg", title:"Intro1"},
    {url:"https://live.staticflickr.com/65535/53375375122_4bf6c034a0_c.jpg", title:"Intro2"},
    {url:"https://live.staticflickr.com/65535/53375375147_4e278dfd14_c.jpg", title:"Intro3"},
    {url:"https://live.staticflickr.com/65535/53375375127_1b4471a15c_c.jpg", title:"Intro4"},
    {url:"https://live.staticflickr.com/65535/53376619809_e451521628_c.jpg", title:"Intro5"},
    {url:"https://live.staticflickr.com/65535/53375375202_09326fe6c7_c.jpg", title:"Intro6"},
    {url:"https://live.staticflickr.com/65535/53376297936_9b9e6c5da7_c.jpg", title:"Intro7"},
    {url:"https://live.staticflickr.com/65535/53376619729_9bdb60e220_c.jpg", title:"Intro8"},
    {url:"https://live.staticflickr.com/65535/53376475488_06662f385a_c.jpg", title:"Intro9"}
];
export const Intro = ({imagens}) => {

    const isMobile = window.matchMedia("(max-width: 768px)").matches;

    return(
        <div id="intro" className="intro">
            {/**col 1 */}
            {!isMobile && <img className="imagem c1r1" src={imagens[1].url} draggable={false}/>}
            {!isMobile && <img className="imagem c1r2" src={imagens[2].url} draggable={false}/>}
            {!isMobile && <img className="imagem c1r3" src={imagens[3].url} draggable={false}/>}
            {!isMobile && <img className="imagem c1r4" src={imagens[4].url} draggable={false}/>}
            {!isMobile && <img className="imagem c1r5" src={imagens[5].url} draggable={false}/>}

            {/**col 2 */}
            <img className="imagem c2r1" src={!isMobile ? imagens[6].url:imagensMobile[9].url} draggable={false}/>
            <img className="imagem c2r2" src={!isMobile ? imagens[7].url:imagensMobile[10].url} draggable={false}/>
            <img className="imagem c2r3" src={!isMobile ? imagens[8].url:imagensMobile[11].url} draggable={false}/>
            <img className="imagem c2r4" src={!isMobile ? imagens[9].url:imagensMobile[12].url} draggable={false}/>
            <img className="imagem c2r5" src={!isMobile ? imagens[10].url:imagensMobile[13].url} draggable={false}/>

            {/**col 3 */}
            <img className="imagem c3r1" src={!isMobile ? imagens[11].url:imagensMobile[0].url} draggable={false}/>
            <img className="imagem c3r2" src={!isMobile ? imagens[12].url:imagensMobile[1].url} draggable={false}/>
            <div className="imagem-centro c3r3">
                {/*<img className="imagem c3r3" src={imagens[0].url} draggable={false}/>*/}
                <div className="intro-menu-overlay">
                    <div className="intro-menu-block i-m-block-1"></div>
                    <div className="intro-menu-block i-m-block-2"></div>
                    <div className="intro-menu-block i-m-block-3"></div>
                    <div className="intro-menu-block i-m-block-4"></div>
                </div>
            </div>

            <img className="imagem c3r4" src={!isMobile ? imagens[13].url:imagensMobile[2].url} draggable={false}/>
            <img className="imagem c3r5" src={!isMobile ? imagens[14].url:imagensMobile[3].url} draggable={false}/>
            
            {/**col 4 */}
            <img className="imagem c4r1" src={!isMobile ? imagens[15].url:imagensMobile[4].url} draggable={false}/>
            <img className="imagem c4r2" src={!isMobile ? imagens[16].url:imagensMobile[5].url} draggable={false}/>
            <img className="imagem c4r3" src={!isMobile ? imagens[17].url:imagensMobile[6].url} draggable={false}/>
            <img className="imagem c4r4" src={!isMobile ? imagens[18].url:imagensMobile[7].url} draggable={false}/>
            <img className="imagem c4r5" src={!isMobile ? imagens[19].url:imagensMobile[8].url} draggable={false}/>

            {/**col 5 */}
            {!isMobile && <img className="imagem c5r1" src={imagens[20].url} draggable={false}/> }
            {!isMobile && <img className="imagem c5r2" src={imagens[21].url} draggable={false}/> }
            {!isMobile && <img className="imagem c5r3" src={imagens[22].url} draggable={false}/> }
            {!isMobile && <img className="imagem c5r4" src={imagens[23].url} draggable={false}/> }
            {!isMobile && <img className="imagem c5r5" src={imagens[24].url} draggable={false}/> }
        </div>
    );
}