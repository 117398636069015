import { NavLink } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';
import '../../css/General.css';
import './Projeto6.css';
import './Content/Projeto6_Content.css';
import {Projeto6_Header} from './Header/Projeto6_Header';
import {Projeto6_Header_EN} from './Header/Projeto6_Header_EN';
import {Projeto6_Content} from './Content/Projeto6_Content';
import { ProjectsMinimap } from '../Generic/ProjectsMinimap';
import { motion, AnimatePresence } from "framer-motion";
import { NextProjectComponent } from '../Generic/TransitionComponents/NextProjectComponent';

var callBackMinimapaCurrentIndex = 1;

const animation_props = {
    animate: {
      y: '0dvh',
      clipPath: 'inset(0% 0% 0% 0%)',
    },
    initial: {
      y: '25dvh',
      clipPath: 'inset(100% 0% 0% 0%)',
    },
    exit: {
      y: '25dvh',
      clipPath: 'inset(100% 0% 0% 0%)',
    },
    transition: {
      duration: 1,
    }
  }

const sections = [
    <Projeto6_Header />,
    <Projeto6_Content />,
  ];

  const imagens = [
    <div className='p6-d-c-i i0' style={{opacity: 1}}/>,
    <div className='p6-d-c-i i1' style={{opacity: 1}}/>,
    <div className='p6-d-c-i i2' style={{opacity: 1}}/>,
    <div className='p6-d-c-i i3' style={{opacity: 1}}/>,
    <div className='p6-d-c-i i4' style={{opacity: 1}}/>,
    <div className='p6-d-c-i i5' style={{opacity: 1}}/>,
    <div className='p6-d-c-i mini-i6' style={{opacity: 1}}/>,
    <div className='p6-d-c-i i7' style={{opacity: 1}}/>
];

export const Projeto6 = ({callback}) => {

    window.$executarIntro = false;
    window.$projetoAtivo = 6;
    window.$slideAnimation = 0;
    window.$primeiraEntradaSlider = false;

    const refContent1 = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [canScrollBackUp, setCanScrollBackUp] = useState(true);

    const language = localStorage.getItem('language') || 'pt';

    //DRAGGING
    const [scrollPosition, setScrollPosition] = useState(0);
    const [dragging, setDragging] = useState(false);
    const startY = useRef(0);

    const isMobile = window.matchMedia("(max-width: 768px)");
    var x = window.matchMedia("(max-width: 740px)");

    var constanteTamanhoParaScroll = 576;//670;
    var constanteTamanhoParaScrollMobile = 190.5;
    const multiplicadorScrollLine = isMobile.matches ? 0.52:0.18;
    if(x.matches){
      constanteTamanhoParaScroll = 20;
    }
    const maxHeightVH = -12 - (constanteTamanhoParaScroll*(sections.length - 1));
    const maxHeightMobileVH = -12 - (constanteTamanhoParaScrollMobile*(sections.length - 1));

    const handleDragStart = (e) => {
      setDragging(true);
      startY.current = e.clientY;
    };

    const handleDragMove = (e) => {
      if (!dragging) return;
      if(!canScrollBackUp){
        return;
      }
      const deltaY = (e.clientY - startY.current) * (e.deltaY > 0 ? 0.2:-0.2);
      startY.current = e.clientY;
      setScrollPosition((prevPosition) => (prevPosition - deltaY) > 0 ? 0:((prevPosition - deltaY)  < maxHeightVH ? maxHeightVH:(prevPosition - deltaY)));
    };

    const handleDragEnd = () => {
      setDragging(false);
    };

    //----------TOUCH DRAG-------
    const handleTouchStart = (e) => {
      setDragging(true);
      startY.current = e.touches[0].clientY;
    };
  
    const handleTouchMove = (e) => {
      if (!dragging) return;
      if(!canScrollBackUp){
        return;
      }

      const deltaY = (e.touches[0].clientY - startY.current) * (e.touches[0].deltaY > 0 ? 0.2:-0.2);
      startY.current = e.touches[0].clientY;
      setScrollPosition((prevPosition) => (prevPosition - deltaY) > 0 ? 0:((prevPosition - deltaY)  < maxHeightMobileVH ? maxHeightMobileVH:(prevPosition - deltaY)));
    };
  
    const handleTouchEnd = () => {
      setDragging(false);
    };

    //----------

    const handleScroll = (e) => {
        
      if(!canScrollBackUp){
        return;
      }

      const deltaY = (e.deltaY > 0 ? -0.1:0.1) * 50; // Positivo - scroll down, negativo - scroll up
      setScrollPosition((prevPosition) => (prevPosition + deltaY) > 0 ? 0:((prevPosition + deltaY) < maxHeightVH ? maxHeightVH:(prevPosition + deltaY)));
    };


    const setHorizontalSlideAnimation = () => {
      window.$slideAnimation = 1;
      callback();
    };

    const setVerticalSlideAnimation = () => {
      window.$slideAnimation = 0;
      callback();
    };
    
    function callbackMinimapa(sIndex, curIndex){
      
        var imagem = document.getElementById("id-p6-content0");
        
        var tamanhoImgEmVH = (imagem.offsetHeight * 100 / window.innerHeight);

        setScrollPosition((prevPosition) => prevPosition + (tamanhoImgEmVH * (callBackMinimapaCurrentIndex - sIndex)));
        setCurrentIndex(sIndex);
        callBackMinimapaCurrentIndex = sIndex;
    }

    function CurrentIndexChanged(currIndex)
    {
        setCurrentIndex(currIndex);
        callBackMinimapaCurrentIndex = currIndex;
    }

    function canScrollBackUpThePage(ableToScrollBackUpPage){
        setCanScrollBackUp(ableToScrollBackUpPage);
    }

    function ScrollDownFromHeader(){
      setScrollPosition(-95);
    }

    return(

        <div className='proj6-detail-container'>
        
        <AnimatePresence>
            {<motion.div {...animation_props} className="portal6" key="portal6!">
                <div className="p6-d-container"
                onMouseDown={handleDragStart}
                onMouseMove={handleDragMove}
                onMouseUp={handleDragEnd}
                onMouseLeave={handleDragEnd}
                onWheel={handleScroll}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                >

                <div className='back-btn' onClick={setVerticalSlideAnimation}>
                        <NavLink to="/" className="slideUp-backbtn">
                          {language === "pt" ? "Anterior":"Back"}
                        </NavLink>
                    </div>

                    <div className="scroll-line" style={{ height: `${-scrollPosition*multiplicadorScrollLine}dvh` }}></div>

                    <div className="projetos-small-menu-slider" style={{opacity: scrollPosition > -85 ? `0`: scrollPosition <= (!isMobile.matches ? -530:-180) ? `0`:`1`}}>
                      <ProjectsMinimap sections={imagens} scrollPosition={scrollPosition} cIndex={currentIndex} handleCallBack={callbackMinimapa}/>
                    </div>

                    <div className="p6-d-s-container"
                        style={{ transform: `translateY(${scrollPosition}vh)` } }
                    >
                            
                        <div
                            className={"p6-s-1"}
                        >
                          {language === "pt" ? 
                              <Projeto6_Header scrollPosition={scrollPosition} scrollDownFromHeader={ScrollDownFromHeader}/>
                              :
                              <Projeto6_Header_EN scrollPosition={scrollPosition} scrollDownFromHeader={ScrollDownFromHeader}/>
                            }
                        </div>
                        <div
                            ref={refContent1}
                            className="p6-s-2"
                            style={{ transition: `transform 1s cubic-bezier(0.215, 0.61, 0.355, 1)`, transform: `translateY(${scrollPosition*.2}vh)`}}
                        >
                            <Projeto6_Content scrollPosition={scrollPosition} handleCallBack={CurrentIndexChanged}/>

                            <div className='next-project6-section'>
                                <NextProjectComponent canMoveNextProject={!isMobile.matches ? scrollPosition <= -576:scrollPosition <= -197} 
                                handleCallBackScroll={canScrollBackUpThePage}
                                nextProject={language === "pt" ? "Painéis Sanjotec":"Sanjotec Panels" }
                                nextProjectRoute="sanjotec"
                                nextProjectImage={!isMobile.matches ? "https://live.staticflickr.com/65535/53413115271_3c4f1ef4ab_h.jpg":"https://live.staticflickr.com/65535/53413448429_7cfc554997_c.jpg"}
                                //nextProjectImage="https://live.staticflickr.com/65535/53358365641_4d70f22f1b_k.jpg" 
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </motion.div>}
        </AnimatePresence>

  </div>
    );
}