import React, { useEffect, useState, useRef, useMemo } from 'react';
import '../../../css/General.css';
import './Projeto2_Header.css';
import ContadorProjetos from '../../ContadorProjetos';
import {PiArrowCircleDownThin} from "react-icons/pi";

export const Projeto2_Header = (props) => {

  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);

  /****RESIZING****/
  const handleResize = () => {
    if(window.matchMedia("(max-width: 768px)").matches){
      setIsMobile(true);
    }
    else{
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);   

  /****-----------****/


  let child1SetState;
  const invokeChild1 = (setStateCallback) => {
    if (setStateCallback) {
      child1SetState = setStateCallback[1];
    } else {
      child1SetState(2);
    }
  };

    function ScrollDown(){
      props.scrollDownFromHeader();
    }

    return(
        <div className='projeto2-detail-container'>
            <div className='proj2-d-header'>
              <div className='proj2-header-contador'>
                <ContadorProjetos cbFunction={invokeChild1} limiteMaximo={window.$numeroProjetos}/>
              </div>
                <div className='p2-d-h-texto-container' style={{ transition: `transform 1s cubic-bezier(0.215, 0.61, 0.355, 1)`,  transform: `translateY(${props.scrollPosition * 0.2}vh)` }}>
                    <h1 className='p2-d-h-t-title'>
                        <span className='p2-d-h-t-span txt-anim-1'>Fotografia</span>
                        <span className='p2-d-h-t-span txt-anim-2'>Santorini</span>
                        <span className='p2-d-h-t-span txt-anim-3'>Shoes</span>
                    </h1>
                    <div className='p2-d-h-t-b1'>
                        <span className='p2-d-h-t-span txt-anim-4 p2-d-h-f'>Lead Designer</span>
                        <p/>
                        {!isMobile ? 
                          <span className='p2-d-h-t-span txt-anim-5 p2-d-h-f-d'><a href="https://santorinishoes.pt/" style={{display:`inline`, pointerEvents:`auto`}}>Santorini shoes</a> é uma marca nacional de design e produção 100%</span>
                          :
                          <span className='p2-d-h-t-span txt-anim-5 p2-d-h-f-d'><a href="https://santorinishoes.pt/" style={{display:`inline`, pointerEvents:`auto`}}>Santorini shoes</a> é uma marca nacional</span>
                        }
                        {!isMobile ? 
                          <span className='p2-d-h-t-span txt-anim-6 p2-d-h-f-d'>portuguesa.</span>
                          :
                          <span className='p2-d-h-t-span txt-anim-6 p2-d-h-f-d'>de design e produção 100% portuguesa.</span>
                        }
                        {!isMobile ? 
                          <span className='p2-d-h-t-span txt-anim-7 p2-d-h-f-d'>Este trabalho consistiu numa sessão fotográfica a cerca de 9 mode-</span>
                          :
                          <span className='p2-d-h-t-span txt-anim-7 p2-d-h-f-d'>Este trabalho consistiu numa sessão</span>
                        }
                        {!isMobile ? 
                          <span className='p2-d-h-t-span txt-anim-8 p2-d-h-f-d'>los da coleção outono/inverno, com o objectivo de criar imagens</span>
                          :
                          <span className='p2-d-h-t-span txt-anim-8 p2-d-h-f-d'>fotográfica a cerca de 9 modelos da co-</span>
                        }
                        {!isMobile ? 
                          <span className='p2-d-h-t-span txt-anim-9 p2-d-h-f-d'>simples, focadas no produto para o website/ loja online.</span>
                          :
                          <span className='p2-d-h-t-span txt-anim-9 p2-d-h-f-d'>leção outono/inverno, com o objectivo de</span>
                        }
                        {!isMobile ? <span className='p2-d-h-t-span txt-anim-10 p2-d-h-f-d'></span>:<span className='p2-d-h-t-span txt-anim-10 p2-d-h-f-d'>criar imagens simples, focadas no pro-</span>}
                        {!isMobile ? <span className='p2-d-h-t-span txt-anim-11 p2-d-h-f-d'></span>:<span className='p2-d-h-t-span txt-anim-11 p2-d-h-f-d'>duto para o website/ loja online.</span>}
                        
                        <p/>

                        {!isMobile ? 
                          <span className='p2-d-h-t-span txt-anim-12 p2-d-h-f-d'>Cada modelo é representado por 4 fotografias em ângulos diferen-</span>
                          :
                          <span className='p2-d-h-t-span txt-anim-12 p2-d-h-f-d'>Cada modelo é representado por 4 foto-</span>
                        }
                        {!isMobile ? 
                          <span className='p2-d-h-t-span txt-anim-13 p2-d-h-f-d'>tes, e editadas de forma a manter a cor e os detalhes autênticos.</span>
                          :
                          <span className='p2-d-h-t-span txt-anim-13 p2-d-h-f-d'>grafias em ângulos diferentes, e editadas</span>
                        }
                        {!isMobile ? <span className='p2-d-h-t-span txt-anim-14 p2-d-h-f-d'></span>:<span className='p2-d-h-t-span txt-anim-14 p2-d-h-f-d'>de forma a manter a cor e os detalhes </span>}
                        {!isMobile ? <span className='p2-d-h-t-span txt-anim-15 p2-d-h-f-d'></span>:<span className='p2-d-h-t-span txt-anim-15 p2-d-h-f-d'>autênticos.</span>}
                        
                    </div>
                    <div className='p2-d-h-t-b2'>
                        <span className='p2-d-h-t-span-b2 txt-anim-16 p2-d-h-f'>Fotografia e-Commerce para Santorini shoes.</span>
                    </div>
                    <div className='p2-d-h-t-b3'>
                        <span className='p2-d-h-t-span-b3 txt-anim-17 p2-d-h-f s-down-txt' style={{opacity:`0`}} onClick={ScrollDown}>Scroll Down</span>
                        <span className='p2-d-h-t-span-b3 txt-anim-17 p2-d-h-f s-down-txt1' style={{opacity:`0`}} onClick={ScrollDown}><PiArrowCircleDownThin size={!isMobile ? 40:30}/></span>
                    </div>
                </div>
            </div>
        </div>
    );
}