import React, { useEffect, useState, useRef, useMemo } from 'react';
import '../../../css/General.css';
import './Projeto9_Header.css';
import ContadorProjetos from '../../ContadorProjetos';
import {PiArrowCircleDownThin} from "react-icons/pi";

export const Projeto9_Header_EN = (props) => {

  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);

    /****RESIZING****/
    const handleResize = () => {
      if(window.matchMedia("(max-width: 768px)").matches){
        setIsMobile(true);
      }
      else{
        setIsMobile(false);
      }
    };

    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);   

    /****-----------****/


  let child1SetState;
  const invokeChild1 = (setStateCallback) => {
    if (setStateCallback) {
      child1SetState = setStateCallback[1];
    } else {
      child1SetState(1);
    }
  };

    function ScrollDown(){
      props.scrollDownFromHeader();
    }

    return(
        <div className='projeto9-detail-container'>
            <div className='proj9-d-header'>
              <div className='proj9-header-contador'>
                <ContadorProjetos cbFunction={invokeChild1} limiteMaximo={window.$numeroProjetos}/>
              </div>
                <div className='p9-d-h-texto-container' style={{ transition: `transform 1s cubic-bezier(0.215, 0.61, 0.355, 1)`,  transform: `translateY(${props.scrollPosition * 0.2}vh)` }}>
                    <h1 className='p9-d-h-t-title'>
                        <span className='p9-d-h-t-span txt-anim-1'>Abílio Pinto </span>
                        <span className='p9-d-h-t-span txt-anim-2'>Cars</span>
                    </h1>
                    <div className='p9-d-h-t-b1'>
                        <span className='p9-d-h-t-span txt-anim-3 p9-d-h-f'>Lead Designer</span>
                        <p/>
                        {!isMobile ? 
                          <span className='p9-d-h-t-span txt-anim-4 p9-d-h-f-d'>Abílio Pinto Cars has been selling new and pre-owned multi-brand </span>
                          :
                          <span className='p9-d-h-t-span txt-anim-4 p9-d-h-f-d'>Abílio Pinto Cars has been selling new </span>
                        }
                        {!isMobile ? 
                          <span className='p9-d-h-t-span txt-anim-5 p9-d-h-f-d'>vehicles for over  25 years and seeks to promote its products through </span>
                          :
                          <span className='p9-d-h-t-span txt-anim-5 p9-d-h-f-d'>and pre-owned multi-brand vehicles for </span>
                        }
                        {!isMobile ? 
                          <span className='p9-d-h-t-span txt-anim-6 p9-d-h-f-d'>campaigns on social media and other platforms. </span>
                          :
                          <span className='p9-d-h-t-span txt-anim-6 p9-d-h-f-d'>over 25 years and seeks to promote its </span>
                        }
                        {!isMobile ? 
                          <span className='p9-d-h-t-span txt-anim-7 p9-d-h-f-d'>In this project, all images were developed for the campaign entitled </span>
                          :
                          <span className='p9-d-h-t-span txt-anim-7 p9-d-h-f-d'>products through campaigns on social </span>
                        }
                        {!isMobile ? 
                          <span className='p9-d-h-t-span txt-anim-8 p9-d-h-f-d'>“Black days”, a car fair of opportunities, offers and discounts, in which </span>
                          :
                          <span className='p9-d-h-t-span txt-anim-8 p9-d-h-f-d'>media and other platforms.</span>
                        }
                        {!isMobile ? 
                          <span className='p9-d-h-t-span txt-anim-9 p9-d-h-f-d'>digital marketing work was also carried out with audience segmentation </span>
                          :
                          <span className='p9-d-h-t-span txt-anim-9 p9-d-h-f-d'>In this project, all images were developed </span>
                        }
                        {!isMobile ? 
                          <span className='p9-d-h-t-span txt-anim-10 p9-d-h-f-d'>and the creation of Facebook ads, organic publications and images for </span>
                          :
                          <span className='p9-d-h-t-span txt-anim-10 p9-d-h-f-d'>for the campaign entitled “Black days”, a </span>
                        }
                        {!isMobile ? 
                          <span className='p9-d-h-t-span txt-anim-11 p9-d-h-f-d'>the platform “Stand Virtual”.</span>
                          :
                          <span className='p9-d-h-t-span txt-anim-11 p9-d-h-f-d'>car fair of opportunities, offers & discounts,</span>
                        }
                        {!isMobile ? 
                          <span className='p9-d-h-t-span txt-anim-12 p9-d-h-f-d'>In addition to this online content, some physical promotional materials  </span>
                          :
                          <span className='p9-d-h-t-span txt-anim-12 p9-d-h-f-d'>in which digital marketing work was also </span>
                        }
                        {!isMobile ? 
                          <span className='p9-d-h-t-span txt-anim-13 p9-d-h-f-d'>were developed, such as stickers and “triangles” (outdoor advertising </span>
                          :
                          <span className='p9-d-h-t-span txt-anim-13 p9-d-h-f-d'>carried out with audience segmentation </span>
                        }
                        {!isMobile ? 
                          <span className='p9-d-h-t-span txt-anim-14 p9-d-h-f-d'>objects).</span>
                          :
                          <span className='p9-d-h-t-span txt-anim-14 p9-d-h-f-d'>and the creation of Facebook ads, organic </span>
                        }

                        {!isMobile ? 
                          <span className='p9-d-h-t-span txt-anim-15 p9-d-h-f-d'></span>
                          :
                          <span className='p9-d-h-t-span txt-anim-15 p9-d-h-f-d'>publications and images for the platform </span>}

                        {!isMobile ? 
                          <span className='p9-d-h-t-span txt-anim-16 p9-d-h-f-d'></span>
                          :
                          <span className='p9-d-h-t-span txt-anim-16 p9-d-h-f-d'>“Stand Virtual”.</span>}

                        {!isMobile ? <span className='p9-d-h-t-span txt-anim-17 p9-d-h-f-d'></span>:<span className='p9-d-h-t-span txt-anim-17 p9-d-h-f-d'>In addition to this online content, some </span>}
                        {!isMobile ? <span className='p9-d-h-t-span txt-anim-18 p9-d-h-f-d'></span>:<span className='p9-d-h-t-span txt-anim-18 p9-d-h-f-d'>physical promotional materials were de-</span>}
                        {!isMobile ? <span className='p9-d-h-t-span txt-anim-19 p9-d-h-f-d'></span>:<span className='p9-d-h-t-span txt-anim-19 p9-d-h-f-d'>veloped, such as stickers and “triangles” </span>}
                        {!isMobile ? <span className='p9-d-h-t-span txt-anim-20 p9-d-h-f-d'></span>:<span className='p9-d-h-t-span txt-anim-20 p9-d-h-f-d'>(outdoor advertising objects).</span>}
                        
                    </div>
                    <div className='p9-d-h-t-b2'>
                        <span className='p9-d-h-t-span txt-anim-21 p9-d-h-f'>Developed at <a href="https://www.outsidethebox.pt" style={{display:`inline`, pointerEvents:`auto`}}>Outside The Box</a>, <br></br>for <a href="https://www.standabiliopinto.com/" style={{display:`inline`, pointerEvents:`auto`}}>Abílio Pinto Cars.</a></span>
                    </div>
                    <div className='p9-d-h-t-b3'>
                        <span className='p9-d-h-t-span txt-anim-22 p9-d-h-f s-down-txt' style={{opacity:`0`}} onClick={ScrollDown}>Scroll Down</span>
                        <span className='p9-d-h-t-span txt-anim-22 p9-d-h-f s-down-txt1' style={{opacity:`0`}} onClick={ScrollDown}><PiArrowCircleDownThin size={!isMobile ? 40:30}/></span>
                    </div>
                </div>
            </div>
        </div>
    );
}