import React, { useEffect, useState, useRef, useMemo } from 'react';
import '../../../css/General.css';
import './Projeto3_Header.css';
import ContadorProjetos from '../../ContadorProjetos';
import {PiArrowCircleDownThin} from "react-icons/pi";

export const Projeto3_Header = (props) => {

  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);

    /****RESIZING****/
    const handleResize = () => {
      if(window.matchMedia("(max-width: 768px)").matches){
        setIsMobile(true);
      }
      else{
        setIsMobile(false);
      }
    };

    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);   

    /****-----------****/

  let child1SetState;
  const invokeChild1 = (setStateCallback) => {
    if (setStateCallback) {
      child1SetState = setStateCallback[1];
    } else {
      child1SetState(3);
    }
  };

    function ScrollDown(){
      props.scrollDownFromHeader();
    }

    return(
        <div className='projeto3-detail-container'>
            <div className='proj3-d-header'>
              <div className='proj3-header-contador'>
                <ContadorProjetos cbFunction={invokeChild1} limiteMaximo={window.$numeroProjetos}/>
              </div>
                <div className='p3-d-h-texto-container' style={{ transition: `transform 1s cubic-bezier(0.215, 0.61, 0.355, 1)`,  transform: `translateY(${props.scrollPosition * 0.2}vh)` }}>
                    <h1 className='p3-d-h-t-title'>
                        <span className='p3-d-h-t-span txt-anim-1'>Curso Básico</span>
                        <span className='p3-d-h-t-span txt-anim-2'>de Música</span>
                        <span className='p3-d-h-t-span txt-anim-3'>Conservatório {!isMobile ? "TSMF":""}</span>
                        {!isMobile ? <span className='p3-d-h-t-span txt-anim-4'></span>:<span className='p3-d-h-t-span txt-anim-4'>Terras SMF</span>}
                    </h1>
                    <div className='p3-d-h-t-b1'>
                        <span className='p3-d-h-t-span txt-anim-5 p3-d-h-f'>Lead Designer</span>
                        <p/>
                        {!isMobile ? 
                          <span className='p3-d-h-t-span txt-anim-6 p3-d-h-f-d'>O desenvolvimento da imagem para o Curso básico de Música do</span>
                          :
                          <span className='p3-d-h-t-span txt-anim-6 p3-d-h-f-d'>O desenvolvimento da imagem para</span>
                        }
                        {!isMobile ? 
                          <span className='p3-d-h-t-span txt-anim-7 p3-d-h-f-d'>Conservatório de Terras de Santa Maria da Feira, nasce da nece-</span>
                          :
                          <span className='p3-d-h-t-span txt-anim-7 p3-d-h-f-d'>o Curso básico de Música do Con-</span>
                        }
                        {!isMobile ? 
                          <span className='p3-d-h-t-span txt-anim-8 p3-d-h-f-d'>ssidade de promover e inspirar para a música os mais pequenos.</span>
                          :
                          <span className='p3-d-h-t-span txt-anim-8 p3-d-h-f-d'>servatório de Terras de Santa Maria </span>
                        }
                        {!isMobile ? 
                          <span className='p3-d-h-t-span txt-anim-9 p3-d-h-f-d'></span>
                          :
                          <span className='p3-d-h-t-span txt-anim-9 p3-d-h-f-d'>da Feira, nasce da necessidade de </span>
                        }
                        {!isMobile ? 
                          <span className='p3-d-h-t-span txt-anim-10 p3-d-h-f-d'>Foi criada uma imagem infantil, com cores e elementos de simples</span>
                          :
                          <span className='p3-d-h-t-span txt-anim-10 p3-d-h-f-d'>promover e inspirar para a música os </span>
                        }
                        {!isMobile ? 
                          <span className='p3-d-h-t-span txt-anim-11 p3-d-h-f-d'>compreensão que cativassem, mas também elementos mais abs-</span>
                          :
                          <span className='p3-d-h-t-span txt-anim-11 p3-d-h-f-d'>mais pequenos.</span>
                        }
                        {!isMobile ? 
                          <span className='p3-d-h-t-span txt-anim-12 p3-d-h-f-d'>tratos que ilustrassem a parte abstrata da música.</span>
                          :
                          <span className='p3-d-h-t-span txt-anim-12 p3-d-h-f-d'>Foi criada uma imagem infantil, com</span>
                        }
                        {!isMobile ? 
                          <span className='p3-d-h-t-span txt-anim-13 p3-d-h-f-d'>Neste projeto foram desenvolvidos diversos materiais como: fichas</span>
                          :
                          <span className='p3-d-h-t-span txt-anim-13 p3-d-h-f-d'>cores e elementos de simples com-</span>
                        }
                        {!isMobile ? 
                          <span className='p3-d-h-t-span txt-anim-14 p3-d-h-f-d'>de inscrição, flyers para as diversas escolas, roll ups e pop ups.</span>
                          :
                          <span className='p3-d-h-t-span txt-anim-14 p3-d-h-f-d'>preensão que cativassem, mas tam-</span>
                        }
                        {!isMobile ? <span className='p3-d-h-t-span txt-anim-15 p3-d-h-f-d'></span>:<span className='p3-d-h-t-span txt-anim-15 p3-d-h-f-d'>bém elementos mais abstratos que </span>}
                        {!isMobile ? <span className='p3-d-h-t-span txt-anim-16 p3-d-h-f-d'></span>:<span className='p3-d-h-t-span txt-anim-16 p3-d-h-f-d'>ilustrassem a parte abstrata da música.</span>}
                        {!isMobile ? <span className='p3-d-h-t-span txt-anim-17 p3-d-h-f-d'></span>:<span className='p3-d-h-t-span txt-anim-17 p3-d-h-f-d'>Neste projeto foram desenvolvidos </span>}
                        {!isMobile ? <span className='p3-d-h-t-span txt-anim-18 p3-d-h-f-d'></span>:<span className='p3-d-h-t-span txt-anim-18 p3-d-h-f-d'>diversos materiais como: fichas de </span>}
                        {!isMobile ? <span className='p3-d-h-t-span txt-anim-19 p3-d-h-f-d'></span>:<span className='p3-d-h-t-span txt-anim-19 p3-d-h-f-d'>inscrição, flyers para as diversas</span>}
                        {!isMobile ? <span className='p3-d-h-t-span txt-anim-20 p3-d-h-f-d'></span>:<span className='p3-d-h-t-span txt-anim-20 p3-d-h-f-d'>escolas, roll ups e pop ups.</span>}

                    </div>
                    <div className='p3-d-h-t-b2'>
                        <span className='p3-d-h-t-span txt-anim-21 p3-d-h-f'>Desenvolvido na Outside The Box</span>
                        {!isMobile ? <span className='p3-d-h-t-span txt-anim-22 p3-d-h-f'>Para o cliente Conservatório de Música Terras</span>:<span className='p3-d-h-t-span txt-anim-22 p3-d-h-f'>Para o cliente Conservatório de</span>}
                        {!isMobile ? <span className='p3-d-h-t-span txt-anim-23 p3-d-h-f'>de Santa Maria da Feira.</span>:<span className='p3-d-h-t-span txt-anim-23 p3-d-h-f'>Música Terras de Santa Maria da</span>}
                        {!isMobile ? <span className='p3-d-h-t-span txt-anim-24 p3-d-h-f-d'></span>:<span className='p3-d-h-t-span txt-anim-24 p3-d-h-f'>Feira.</span>}
                    </div>
                    <div className='p3-d-h-t-b3'>
                        <span className='p3-d-h-t-span txt-anim-25 p3-d-h-f s-down-txt' style={{opacity:`0`}} onClick={ScrollDown}>Scroll Down</span>
                        <span className='p3-d-h-t-span txt-anim-25 p3-d-h-f s-down-txt1' style={{opacity:`0`}} onClick={ScrollDown}><PiArrowCircleDownThin size={!isMobile ? 40:30}/></span>
                    </div>
                </div>
            </div>
        </div>
    );
}