import { useState, useEffect } from 'react';
import { Intro } from './Intro';
import { Main } from './Main';

const imagens = [
    {url: 'https://live.staticflickr.com/65535/53348107305_4329d697e8_b.jpg', title: 'Projeto 1'},
    {url: 'https://live.staticflickr.com/65535/53347947323_5aedbc6fca_b.jpg', title: 'Projeto 2'},
    {url: 'https://live.staticflickr.com/65535/53348166755_e16e9b70f5_b.jpg', title: 'Projeto 3'},
    {url: 'https://live.staticflickr.com/65535/53346782882_e5ddf5237b_b.jpg', title: 'Projeto 4'},
    {url: 'https://live.staticflickr.com/65535/53357773749_bb8bb35a11_k.jpg', title: 'Projeto 5'},
    {url: 'https://live.staticflickr.com/65535/53358824965_b6524ffa52_k.jpg', title: 'Projeto 6'},
    {url: 'https://live.staticflickr.com/65535/53347945428_de792f4af8_b.jpg', title: 'Projeto 7'},
    {url: 'https://live.staticflickr.com/65535/53357773824_51a6dc6a84_k.jpg', title: 'Projeto 8'},
    {url: 'https://live.staticflickr.com/65535/53347889878_7f987b10c7_b.jpg', title: 'Projeto 9'},
    {url: 'https://live.staticflickr.com/65535/53347947278_ebfbdc8992_b.jpg', title: 'Projeto 10'},
    {url: 'https://live.staticflickr.com/65535/53357896625_a1d127c5e5_k.jpg', title: 'Projeto 11'},
    {url: 'https://live.staticflickr.com/65535/53358698354_4f5a538ddd_k.jpg', title: 'Projeto 12'},
    {url: 'https://live.staticflickr.com/65535/53358698344_a4ce1c4a3f_k.jpg', title: 'Projeto 13'},
    {url: 'https://live.staticflickr.com/65535/53357489817_f655a07dcf_k.jpg', title: 'Projeto 14'},
    {url: 'https://live.staticflickr.com/65535/53348159440_a0a143b454_b.jpg', title: 'Projeto 15'},
    {url: 'https://live.staticflickr.com/65535/53357663228_666c62b184_k.jpg', title: 'Projeto 16'},
    {url: 'https://live.staticflickr.com/65535/53347715711_9ca0de95ea_b.jpg', title: 'Projeto 17'},
    {url: 'https://live.staticflickr.com/65535/53357439521_4458a67c39_k.jpg', title: 'Projeto 18'},
    {url: 'https://live.staticflickr.com/65535/53348037244_0db96fbd16_b.jpg', title: 'Projeto 19'},
    {url: 'https://live.staticflickr.com/65535/53346782877_811e3bacf0_b.jpg', title: 'Projeto 20'},
    {url: 'https://live.staticflickr.com/65535/53347551986_530a6dba7c_b.jpg', title: 'Projeto 21'},
    {url: 'https://live.staticflickr.com/65535/53357773869_e9215f4c14_k.jpg', title: 'Projeto 22'},
    {url: 'https://live.staticflickr.com/65535/53347551996_5b5019c390_b.jpg', title: 'Projeto 23'},
    {url: 'https://live.staticflickr.com/65535/53357773824_51a6dc6a84_k.jpg', title: 'Projeto 24'},
    {url: 'https://live.staticflickr.com/65535/53357773869_e9215f4c14_k.jpg', title: 'Projeto 25'},
  ];

  /*BACKUP 27/11/2023*/
  /*const imagens = [
    {url: 'https://camillemormal.com/static/media/d/work/gucci/home.jpg?8', title: 'Projeto 1'},
    {url: 'https://camillemormal.com/static/media/d/load/0.jpg?8', title: 'Projeto 2'},
    {url: 'https://camillemormal.com/static/media/d/load/1.jpg?8', title: 'Projeto 3'},
    {url: 'https://camillemormal.com/static/media/d/load/2.jpg?8', title: 'Projeto 4'},
    {url: 'https://camillemormal.com/static/media/d/load/3.jpg?8', title: 'Projeto 5'},
    {url: 'https://camillemormal.com/static/media/d/load/4.jpg?8', title: 'Projeto 6'},
    {url: 'https://camillemormal.com/static/media/d/load/5.jpg?8', title: 'Projeto 7'},
    {url: 'https://camillemormal.com/static/media/d/load/6.jpg?8', title: 'Projeto 8'},
    {url: 'https://camillemormal.com/static/media/d/load/7.jpg?8', title: 'Projeto 9'},
    {url: 'https://camillemormal.com/static/media/d/load/8.jpg?8', title: 'Projeto 10'},
    {url: 'https://camillemormal.com/static/media/d/load/9.jpg?8', title: 'Projeto 11'},
    {url: 'https://camillemormal.com/static/media/d/load/10.jpg?8', title: 'Projeto 12'},
    {url: 'https://camillemormal.com/static/media/d/load/11.jpg?8', title: 'Projeto 13'},
    {url: 'https://camillemormal.com/static/media/d/load/13.jpg?8', title: 'Projeto 14'},
    {url: 'https://camillemormal.com/static/media/d/load/14.jpg?8', title: 'Projeto 15'},
    {url: 'https://camillemormal.com/static/media/d/load/15.jpg?8', title: 'Projeto 16'},
    {url: 'https://camillemormal.com/static/media/d/load/16.jpg?8', title: 'Projeto 17'},
    {url: 'https://camillemormal.com/static/media/d/load/17.jpg?8', title: 'Projeto 18'},
    {url: 'https://camillemormal.com/static/media/d/load/18.jpg?8', title: 'Projeto 19'},
    {url: 'https://camillemormal.com/static/media/d/load/19.jpg?8', title: 'Projeto 20'},
    {url: 'https://camillemormal.com/static/media/d/load/20.jpg?8', title: 'Projeto 21'},
    {url: 'https://camillemormal.com/static/media/d/load/21.jpg?8', title: 'Projeto 22'},
    {url: 'https://camillemormal.com/static/media/d/load/22.jpg?8', title: 'Projeto 23'},
    {url: 'https://camillemormal.com/static/media/d/load/23.jpg?8', title: 'Projeto 24'},
    {url: 'https://camillemormal.com/static/media/d/load/24.jpg?8', title: 'Projeto 25'},
  ];*/

  /*BACKUP*/
  /*const imagens = [
    {url: 'https://images.unsplash.com/photo-1524781289445-ddf8f5695861?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80', title: 'Projeto 1'},
    {url: 'https://images.unsplash.com/photo-1610194352361-4c81a6a8967e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1674&q=80', title: 'Projeto 2'},
    {url: 'https://images.unsplash.com/photo-1618202133208-2907bebba9e1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80', title: 'Projeto 3'},
    {url: 'https://images.unsplash.com/photo-1495805442109-bf1cf975750b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80', title: 'Projeto 4'},
    {url: 'https://images.unsplash.com/photo-1548021682-1720ed403a5b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80', title: 'Projeto 5'},
    {url: 'https://images.unsplash.com/photo-1496753480864-3e588e0269b3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2134&q=80', title: 'Projeto 6'},
    {url: 'https://images.unsplash.com/photo-1613346945084-35cccc812dd5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1759&q=80', title: 'Projeto 7'},
    {url: 'https://images.unsplash.com/photo-1516681100942-77d8e7f9dd97?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80', title: 'Projeto 8'},
  ];*/

export const Home = ({callbackLinguagem}) => {

    const [ isMenuSliderVisible, setIsMenuSliderVisible ] = useState(!window.$executarIntro);
    const [ hideIntro, setHideIntroState ] = useState(!window.$executarIntro);

    const containerStyles = {
      width: "100dvw",
      height: "100dvh",
      margin: "0 auto",
      overflow: "hidden",
      position: "absolute",
    };

    
    useEffect(() => {
        const timeout = setTimeout(() => {
          setIsMenuSliderVisible(true);
          setHideIntroState(true);
        }, 5500)
     
        return () => clearTimeout(timeout)
    }, [])

    return(
      <div style={containerStyles}>
        {/*<Intro imagens={imagens}/>*/}
        {!hideIntro && <Intro imagens={imagens}/>}
        <Main intro={window.$primeiraEntradaSlider} loading={!isMenuSliderVisible} callbackLinguagem={callbackLinguagem}/>
      </div>
    );
}