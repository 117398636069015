import React from "react";
import './LandscapeWarningMobile.css';

export const LandscapeWarningMobile = () => {

    return(
        <div className="landscape-warning-mobile">
            Rode o dispositivo.
        </div>
    );
}