import React, { useEffect, useState, useRef, useMemo } from 'react';
import '../../../css/General.css';
import './Projeto9_Header.css';
import ContadorProjetos from '../../ContadorProjetos';
import {PiArrowCircleDownThin} from "react-icons/pi";

export const Projeto9_Header = (props) => {

  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);

    /****RESIZING****/
    const handleResize = () => {
      if(window.matchMedia("(max-width: 768px)").matches){
        setIsMobile(true);
      }
      else{
        setIsMobile(false);
      }
    };

    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);   

    /****-----------****/ 

  let child1SetState;
  const invokeChild1 = (setStateCallback) => {
    if (setStateCallback) {
      child1SetState = setStateCallback[1];
    } else {
      child1SetState(1);
    }
  };

    function ScrollDown(){
      props.scrollDownFromHeader();
    }

    return(
        <div className='projeto9-detail-container'>
            <div className='proj9-d-header'>
              <div className='proj9-header-contador'>
                <ContadorProjetos cbFunction={invokeChild1} limiteMaximo={window.$numeroProjetos}/>
              </div>
                <div className='p9-d-h-texto-container' style={{ transition: `transform 1s cubic-bezier(0.215, 0.61, 0.355, 1)`,  transform: `translateY(${props.scrollPosition * 0.2}vh)` }}>
                    <h1 className='p9-d-h-t-title'>
                        <span className='p9-d-h-t-span txt-anim-1'>Automóveis</span>
                        <span className='p9-d-h-t-span txt-anim-2'>Abílio Pinto</span>
                    </h1>
                    <div className='p9-d-h-t-b1'>
                        <span className='p9-d-h-t-span txt-anim-3 p9-d-h-f'>Lead Designer</span>
                        <p/>

                        {!isMobile ? 
                          <span className='p9-d-h-t-span txt-anim-4 p9-d-h-f-d'>A Automóveis Abílio Pinto comercializa viaturas novas e semi-novas </span>
                          :
                          <span className='p9-d-h-t-span txt-anim-4 p9-d-h-f-d'>A Automóveis Abílio Pinto comercia-</span>
                        }

                        {!isMobile ? 
                          <span className='p9-d-h-t-span txt-anim-5 p9-d-h-f-d'>multimarcas há mais de 25 anos e procura divulgar através de </span>
                          :
                          <span className='p9-d-h-t-span txt-anim-5 p9-d-h-f-d'>liza viaturas novas e semi-novas multi-</span>
                        }

                        {!isMobile ? 
                          <span className='p9-d-h-t-span txt-anim-6 p9-d-h-f-d'>campanhas nas redes sociais e outras plataformas os seus produtos.</span>
                          :
                          <span className='p9-d-h-t-span txt-anim-6 p9-d-h-f-d'>marcas há mais de 25 anos e procura </span>
                        }
                        {!isMobile ? 
                          <span className='p9-d-h-t-span txt-anim-7 p9-d-h-f-d'>Neste projeto foram desenvolvidas todas as imagens para a campanha </span>
                          :
                          <span className='p9-d-h-t-span txt-anim-7 p9-d-h-f-d'>divulgar através de campanhas nas </span>
                          }
                        {!isMobile ? 
                          <span className='p9-d-h-t-span txt-anim-8 p9-d-h-f-d'>intitulada “Black days”, uma feira automóvel de oportunidades, ofertas </span>
                          :
                          <span className='p9-d-h-t-span txt-anim-8 p9-d-h-f-d'>redes sociais e outras plataformas os </span>
                          }
                        {!isMobile ? 
                          <span className='p9-d-h-t-span txt-anim-9 p9-d-h-f-d'>e descontos, na qual foi realizado também um trabalho de marketing </span>
                          :
                          <span className='p9-d-h-t-span txt-anim-9 p9-d-h-f-d'>seus produtos. Neste projeto foram </span>
                          }
                        {!isMobile ? 
                          <span className='p9-d-h-t-span txt-anim-10 p9-d-h-f-d'>digital com segmentação do público e criação de facebook ads, publi-</span>
                          :
                          <span className='p9-d-h-t-span txt-anim-10 p9-d-h-f-d'>desenvolvidas todas as imagens para </span>
                          }
                        {!isMobile ? 
                          <span className='p9-d-h-t-span txt-anim-11 p9-d-h-f-d'>cações orgânicas e imagens para o stand virtual.</span>
                          :
                          <span className='p9-d-h-t-span txt-anim-11 p9-d-h-f-d'>a campanha intitulada “Black days”, </span>
                          }
                        {!isMobile ? 
                          <span className='p9-d-h-t-span txt-anim-12 p9-d-h-f-d'>Além deste conteúdo online, foram desenvolvidos alguns materiais </span>
                          :
                          <span className='p9-d-h-t-span txt-anim-12 p9-d-h-f-d'>uma feira automóvel de oportunidades, </span>
                          }
                        {!isMobile ? 
                          <span className='p9-d-h-t-span txt-anim-13 p9-d-h-f-d'>físicos de divulgação como autocolantes e “triângulos” (objetos de </span>
                          :
                          <span className='p9-d-h-t-span txt-anim-13 p9-d-h-f-d'>ofertas e descontos na qual foi realiza-</span>
                          }
                        {!isMobile ? 
                          <span className='p9-d-h-t-span txt-anim-14 p9-d-h-f-d'>publicidade exterior).</span>
                          :
                          <span className='p9-d-h-t-span txt-anim-14 p9-d-h-f-d'>do também um trabalho de marketing </span>
                          }

                        {!isMobile ? 
                          <span className='p9-d-h-t-span txt-anim-15 p9-d-h-f-d'></span>
                          :
                          <span className='p9-d-h-t-span txt-anim-15 p9-d-h-f-d'>digital com segmentação do público e </span>
                        }

                        {!isMobile ? 
                            <span className='p9-d-h-t-span txt-anim-16 p9-d-h-f-d'></span>
                            :
                            <span className='p9-d-h-t-span txt-anim-16 p9-d-h-f-d'>criação de facebook ads, publicações </span>
                        }

                        {!isMobile ? <span className='p9-d-h-t-span txt-anim-17 p9-d-h-f-d'></span>:<span className='p9-d-h-t-span txt-anim-17 p9-d-h-f-d'>orgânicas e imagens para o Stand </span>}
                        {!isMobile ? <span className='p9-d-h-t-span txt-anim-18 p9-d-h-f-d'></span>:<span className='p9-d-h-t-span txt-anim-18 p9-d-h-f-d'>Virtual. Além deste conteúdo online, </span>}
                        {!isMobile ? <span className='p9-d-h-t-span txt-anim-19 p9-d-h-f-d'></span>:<span className='p9-d-h-t-span txt-anim-19 p9-d-h-f-d'>foram desenvolvidos alguns materiais </span>}
                        {!isMobile ? <span className='p9-d-h-t-span txt-anim-20 p9-d-h-f-d'></span>:<span className='p9-d-h-t-span txt-anim-20 p9-d-h-f-d'>físicos de divulgação como autocolantes </span>}
                        {!isMobile ? <span className='p9-d-h-t-span txt-anim-21 p9-d-h-f-d'></span>:<span className='p9-d-h-t-span txt-anim-21 p9-d-h-f-d'>e “triângulos” (objetos de publicidade </span>}
                        {!isMobile ? <span className='p9-d-h-t-span txt-anim-22 p9-d-h-f-d'></span>:<span className='p9-d-h-t-span txt-anim-22 p9-d-h-f-d'>exterior).</span>}

                    </div>
                    <div className='p9-d-h-t-b2'>
                        <span className='p9-d-h-t-span txt-anim-23 p9-d-h-f'>Desenvolvido na <a href="https://www.outsidethebox.pt" style={{display:`inline`, pointerEvents:`auto`}}>Outside The Box</a>, <br></br>para o cliente <a href="https://www.standabiliopinto.com/" style={{display:`inline`, pointerEvents:`auto`}}>Automóveis Abílio Pinto</a></span>
                    </div>
                    <div className='p9-d-h-t-b3'>
                        <span className='p9-d-h-t-span txt-anim-24 p9-d-h-f s-down-txt' style={{opacity:`0`}} onClick={ScrollDown}>Scroll Down</span>
                        <span className='p9-d-h-t-span txt-anim-24 p9-d-h-f s-down-txt1' style={{opacity:`0`}} onClick={ScrollDown}><PiArrowCircleDownThin size={!isMobile ? 40:30}/></span>
                    </div>
                </div>
            </div>
        </div>
    );
}