import React, { useEffect, useState, useRef, useMemo } from 'react';
import '../../../css/General.css';
import './Projeto5_Header.css';
import ContadorProjetos from '../../ContadorProjetos';
import {PiArrowCircleDownThin} from "react-icons/pi";

export const Projeto5_Header_EN = (props) => {

  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);

    /****RESIZING****/
    const handleResize = () => {
      if(window.matchMedia("(max-width: 768px)").matches){
        setIsMobile(true);
      }
      else{
        setIsMobile(false);
      }
    };

    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);   

    /****-----------****/


  let child1SetState;
  const invokeChild1 = (setStateCallback) => {
    if (setStateCallback) {
      child1SetState = setStateCallback[1];
    } else {
      child1SetState(1);
    }
  };

    function ScrollDown(){
      props.scrollDownFromHeader();
    }

    return(
        <div className='projeto5-detail-container'>
            <div className='proj5-d-header'>
              <div className='proj5-header-contador'>
                <ContadorProjetos cbFunction={invokeChild1} limiteMaximo={window.$numeroProjetos}/>
              </div>
                <div className='p5-d-h-texto-container' style={{ transition: `transform 1s cubic-bezier(0.215, 0.61, 0.355, 1)`,  transform: `translateY(${props.scrollPosition * 0.2}vh)` }}>
                    <h1 className='p5-d-h-t-title'>
                        <span className='p5-d-h-t-span txt-anim-1'>Andrik </span>
                        <span className='p5-d-h-t-span txt-anim-2'>Logo</span>
                    </h1>
                    <div className='p5-d-h-t-b1'>
                        <span className='p5-d-h-t-span txt-anim-3 p5-d-h-f'>Lead Designer</span>
                        <p/>
                        {!isMobile ? 
                          <span className='p5-d-h-t-span txt-anim-4 p5-d-h-f-d'>Andrik is a footwear company with over 25 years of expe-</span>
                          :
                          <span className='p5-d-h-t-span txt-anim-4 p5-d-h-f-d'>Andrik is a footwear company with </span>
                        }
                        {!isMobile ? 
                          <span className='p5-d-h-t-span txt-anim-5 p5-d-h-f-d'>rience, its focus is the production of footwear for companies.</span>
                          :
                          <span className='p5-d-h-t-span txt-anim-5 p5-d-h-f-d'>over 25 years of experience, its </span>
                        }
                        {!isMobile ? 
                          <span className='p5-d-h-t-span txt-anim-6 p5-d-h-f-d'>The company sought to give a new image to its business, </span>
                          :
                          <span className='p5-d-h-t-span txt-anim-6 p5-d-h-f-d'>focus is the production of footwear </span>
                        }
                        {!isMobile ? 
                          <span className='p5-d-h-t-span txt-anim-7 p5-d-h-f-d'>through the rebranding of the logo and the creation of statio-</span>
                          :
                          <span className='p5-d-h-t-span txt-anim-7 p5-d-h-f-d'>for companies.</span>
                        }
                        {!isMobile ? 
                          <span className='p5-d-h-t-span txt-anim-8 p5-d-h-f-d'>nary, notebooks, pens and other materials essential to its craft.</span>
                          :
                          <span className='p5-d-h-t-span txt-anim-8 p5-d-h-f-d'>The company sought to give a new </span>
                        }
                        {!isMobile ? <span className='p5-d-h-t-span txt-anim-9 p5-d-h-f-d'></span>:<span className='p5-d-h-t-span txt-anim-9 p5-d-h-f-d'>image to its business, through the </span>}
                        {!isMobile ? <span className='p5-d-h-t-span txt-anim-10 p5-d-h-f-d'></span>:<span className='p5-d-h-t-span txt-anim-10 p5-d-h-f-d'>rebranding of the logo and the cre-</span>}
                        {!isMobile ? <span className='p5-d-h-t-span txt-anim-11 p5-d-h-f-d'></span>:<span className='p5-d-h-t-span txt-anim-11 p5-d-h-f-d'>ation of stationary, notebooks, pens </span>}
                        {!isMobile ? <span className='p5-d-h-t-span txt-anim-12 p5-d-h-f-d'></span>:<span className='p5-d-h-t-span txt-anim-12 p5-d-h-f-d'>and other materials essential to its </span>}
                        {!isMobile ? <span className='p5-d-h-t-span txt-anim-13 p5-d-h-f-d'></span>:<span className='p5-d-h-t-span txt-anim-13 p5-d-h-f-d'>craft.</span>}

                    </div>
                    <div className='p5-d-h-t-b2'>
                        <span className='p5-d-h-t-span txt-anim-14 p5-d-h-f'>Developed at Outside The Box.</span>
                    </div>
                    <div className='p5-d-h-t-b3'>
                        <span className='p5-d-h-t-span txt-anim-15 p5-d-h-f s-down-txt' style={{opacity:`0`}} onClick={ScrollDown}>Scroll Down</span>
                        <span className='p5-d-h-t-span txt-anim-15 p5-d-h-f s-down-txt1' style={{opacity:`0`}} onClick={ScrollDown}><PiArrowCircleDownThin size={!isMobile ? 40:30}/></span>
                    </div>
                </div>
            </div>
        </div>
    );
}