import './AboutContent_Skills.css';
import './AboutContent_Skills.scss';
import '../../../css/General.css';
import {useEffect, useRef, useState, useMemo} from 'react';

export const AboutContent_Skills = (props) =>{

    const language = localStorage.getItem('language') || 'pt';
    const timerRef = useRef(null);
    const sufixo = props.minimapa ? "s":"r";

    const ref99 = useRef(null); //title

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const ref6 = useRef(null);

    useIsInViewport(ref99); //title

    useIsInViewport(ref1);
    useIsInViewport(ref2);
    useIsInViewport(ref3);
    useIsInViewport(ref4);
    useIsInViewport(ref5);
    useIsInViewport(ref6);

    function useIsInViewport(ref) {
        const [isIntersecting, setIsIntersecting] = useState(false);
      
        const observer = useMemo(
          () =>
            new IntersectionObserver(([entry]) =>
              setIsIntersecting(sufixo == "r" && entry.isIntersecting),
            ),
          [],
        );
    
    useEffect(() => {
            observer.observe(ref.current);
        
            return () => {
              observer.disconnect();
            };
          }, [ref, observer]);

          var languages_levels = document.getElementsByClassName("s-i-active");

          if(isIntersecting){
            if(ref.current.style?.animation === undefined || ref.current.style?.animation === null || ref.current.style?.animation === ""){
                ref.current.style.animation = "abt-slide-up-text .5s ease-in-out 0.1s forwards";
            }

            if(languages_levels != null && languages_levels.length > 0){
                var i = 1;

                for (const skill of languages_levels) {
                    setTimeout(function() {
                        skill.style.backgroundColor='#FFF0F5';
                      }, 35*i);

                      i++;
                  }
            }
          }
        
          return () => clearTimeout(timerRef.current);
    }

    return(
        <div className='abt-s-container-grid'>

            <div ref={ref99} className={'abt-s-t-' + sufixo} style={{opacity:0}}>{language === "pt" ? "Competências":"Skills"}</div>

            <div className={'abt-s-content-' + sufixo}>

                <div className='abt-s-r-1'>
                <div ref={ref1} className={'abt-s-l1' + sufixo} style={{opacity:0}}>{language === "pt" ? "Línguas":"Languages"}</div>
                            <hr ref={ref2} className={'abt-s-l2' + sufixo} style={{opacity:0}}/>
                    <div ref={ref3} id={'abt-s-l3' + sufixo} className="skills" style={{opacity:0}}>
                    
                        <div className="skill-column">
                            
                            <div className="skill-label">{language === "pt" ? "Português":"Portuguese"}</div>
                            <div id={"s-i-" + sufixo} className={"skill-icons-" + sufixo}>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                            </div>
                        </div>
                        <div className="skill-column">
                            <div className="skill-label">{language === "pt" ? "Inglês":"English"}</div>
                            <div id={"s-i-" + sufixo} className={"skill-icons-" + sufixo}>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div className="skill-column">
                            <div className="skill-label">{language === "pt" ? "Francês":"French"}</div>
                            <div id={"s-i-" + sufixo} className={"skill-icons-" + sufixo}>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='abt-s-r-2'>
                <div ref={ref4} className={'abt-s-l4' + sufixo} style={{opacity:0}}>Software</div>
                            <hr ref={ref5} className={'abt-s-l5' + sufixo} style={{opacity:0}}/>
                    <div ref={ref6} id={'abt-s-l6' + sufixo} className="skills" style={{opacity:0}}>
                    
                        <div className="skill-column">
                            
                            <div className="skill-label">Photoshop</div>
                            <div id={"s-i-" + sufixo} className={"skill-icons-" + sufixo}>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div className="skill-column">
                            <div className="skill-label">Corel Draw</div>
                            <div id={"s-i-" + sufixo} className={"skill-icons-" + sufixo}>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div className="skill-column">
                            <div className="skill-label">Illustrator</div>
                            <div id={"s-i-" + sufixo} className={"skill-icons-" + sufixo}>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                            </div>
                        </div>

                        <div className="skill-column">
                            <div className="skill-label">LightRoom</div>
                            <div id={"s-i-" + sufixo} className={"skill-icons-" + sufixo}>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div className="skill-column">
                            <div className="skill-label">Indesign</div>
                            <div id={"s-i-" + sufixo} className={"skill-icons-" + sufixo}>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div className="skill-column">
                            <div className="skill-label">Blender 3D</div>
                            <div id={"s-i-" + sufixo} className={"skill-icons-" + sufixo}>
                                <span className="s-i-active"></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>

                        <div className="skill-column">
                            <div className="skill-label">AfterEffects</div>
                            <div id={"s-i-" + sufixo} className={"skill-icons-" + sufixo}>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div className="skill-column">
                            <div className="skill-label">Figma</div>
                            <div id={"s-i-" + sufixo} className={"skill-icons-" + sufixo}>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div className="skill-column">
                            <div className="skill-label">Adobe XD</div>
                            <div id={"s-i-" + sufixo} className={"skill-icons-" + sufixo}>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span className="s-i-active"></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}