import React, { useEffect, useState, useRef, useMemo } from 'react';
import '../../../css/General.css';
import './Projeto1_Header.css';
import ContadorProjetos from '../../ContadorProjetos';
import {PiArrowCircleDownThin} from "react-icons/pi";

export const Projeto1_Header_EN = (props) => {

  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);

  /****RESIZING****/
  const handleResize = () => {
    if(window.matchMedia("(max-width: 768px)").matches){
      setIsMobile(true);
    }
    else{
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);   

  /****-----------****/


  let child1SetState;
  const invokeChild1 = (setStateCallback) => {
    if (setStateCallback) {
      child1SetState = setStateCallback[1];
    } else {
      child1SetState(1);
    }
  };

    function ScrollDown(){
      props.scrollDownFromHeader();
    }

    return(
        <div className='projeto1-detail-container'>
            <div className='proj1-d-header'>
              <div className='proj1-header-contador'>
                <ContadorProjetos cbFunction={invokeChild1} limiteMaximo={window.$numeroProjetos}/>
              </div>
                <div className='p1-d-h-texto-container' style={{ transition: `transform 1s cubic-bezier(0.215, 0.61, 0.355, 1)`,  transform: `translateY(${props.scrollPosition * 0.2}vh)` }}>
                    <h1 className='p1-d-h-t-title'>
                        <span className='p1-d-h-t-span txt-anim-1'>Vihupole </span>
                        <span className='p1-d-h-t-span txt-anim-2'>Catalog</span>
                    </h1>
                    <div className='p1-d-h-t-b1'>
                        <span className='p1-d-h-t-span txt-anim-3 p1-d-h-f'>Lead Designer</span>
                        <p/>
                        {!isMobile ? 
                          <span className='p1-d-h-t-span txt-anim-4 p1-d-h-f-d'>Vihupole is an innovative, versatile and sustainable product, created </span>
                          :
                          <span className='p1-d-h-t-span txt-anim-4 p1-d-h-f-d'>Vihupole is an innovative, versatile </span>
                        }
                        {!isMobile ? 
                          <span className='p1-d-h-t-span txt-anim-5 p1-d-h-f-d'>by Tibesystems.</span>
                          :
                          <span className='p1-d-h-t-span txt-anim-5 p1-d-h-f-d'>and sustainable product, created by</span>
                        }
                        {!isMobile ? 
                          <span className='p1-d-h-t-span txt-anim-6 p1-d-h-f-d'>It is a protection and security solution that is able to adapt to the most </span>
                          :
                          <span className='p1-d-h-t-span txt-anim-6 p1-d-h-f-d'>Tibesystems.</span>
                        }
                        {!isMobile ? 
                          <span className='p1-d-h-t-span txt-anim-7 p1-d-h-f-d'>remote locations.</span>
                          :
                          <span className='p1-d-h-t-span txt-anim-7 p1-d-h-f-d'>It is a protection and security solu-</span>
                        }
                        {!isMobile ? 
                          <span className='p1-d-h-t-span txt-anim-8 p1-d-h-f-d'>This catalog's purpose is to demonstrate the different applications in </span>
                          :
                          <span className='p1-d-h-t-span txt-anim-8 p1-d-h-f-d'>tion that is able to adapt to the most </span>
                        }
                        {!isMobile ? 
                          <span className='p1-d-h-t-span txt-anim-9 p1-d-h-f-d'>various spaces, through photographic montages, as well as to pre-</span>
                          :
                          <span className='p1-d-h-t-span txt-anim-9 p1-d-h-f-d'>remote locations.</span>
                        }
                        {!isMobile ? 
                          <span className='p1-d-h-t-span txt-anim-10 p1-d-h-f-d'>sent their characteristics and added value of acquisition through the </span>
                          :
                          <span className='p1-d-h-t-span txt-anim-10 p1-d-h-f-d'>This catalog's purpose is to demo-</span>
                        }
                        {!isMobile? 
                          <span className='p1-d-h-t-span txt-anim-11 p1-d-h-f-d'>layout and descriptions.</span>
                          :
                          <span className='p1-d-h-t-span txt-anim-11 p1-d-h-f-d'>nstrate the different applications in </span>
                        }
                        {!isMobile ? <span className='p1-d-h-t-span txt-anim-12 p1-d-h-f-d'></span>
                        :<span className='p1-d-h-t-span txt-anim-12 p1-d-h-f-d'>various spaces, through photogra-</span>}
                        {!isMobile ? <span className='p1-d-h-t-span txt-anim-13 p1-d-h-f-d'></span>
                        :<span className='p1-d-h-t-span txt-anim-13 p1-d-h-f-d'>phic montages, as well as present</span>}
                        {!isMobile ? <span className='p1-d-h-t-span txt-anim-14 p1-d-h-f-d'></span>
                        :<span className='p1-d-h-t-span txt-anim-14 p1-d-h-f-d'>their characteristics and added val-</span>}
                        {!isMobile ? <span className='p1-d-h-t-span txt-anim-15 p1-d-h-f-d'></span>
                        :<span className='p1-d-h-t-span txt-anim-15 p1-d-h-f-d'>ue of acquisition through the layout </span>}
                        {!isMobile ? <span className='p1-d-h-t-span txt-anim-16 p1-d-h-f-d'></span>
                        :<span className='p1-d-h-t-span txt-anim-16 p1-d-h-f-d'>and descriptions.</span>}

                    </div>
                    <div className='p1-d-h-t-b2'>
                      {!isMobile ? 
                        <span className='p1-d-h-t-span txt-anim-17 p1-d-h-f'>This catalog was developed at <a href="https://www.outsidethebox.pt" style={{display:`inline`, pointerEvents:`auto`}}>Outside The Box</a></span>
                        :
                        <span className='p1-d-h-t-span txt-anim-17 p1-d-h-f'>This catalog was developed at </span>
                      }
                      {!isMobile ?
                        <span className='p1-d-h-t-span txt-anim-18 p1-d-h-f-d'></span>
                        :
                        <span className='p1-d-h-t-span txt-anim-18 p1-d-h-f'><a href="https://www.outsidethebox.pt" style={{display:`inline`, pointerEvents:`auto`}}>Outside The Box</a></span>
                      }
                        <span className='p1-d-h-t-span txt-anim-19 p1-d-h-f'>For the client Tibesystems.</span>
                    </div>
                    <div className='p1-d-h-t-b3'>
                        <span className='p1-d-h-t-span txt-anim-20 p1-d-h-f s-down-txt' style={{opacity:`0`}} onClick={ScrollDown}>Scroll Down</span>
                        <span className='p1-d-h-t-span txt-anim-20 p1-d-h-f s-down-txt99' style={{opacity:`0`}} onClick={ScrollDown}><PiArrowCircleDownThin size={!isMobile ? 40:30}/></span>
                    </div>
                </div>
            </div>
        </div>
    );
}