import React, { useEffect, useState, useRef, useMemo } from 'react';
import '../../../css/General.css';
import './Projeto7_Header.css';
import ContadorProjetos from '../../ContadorProjetos';
import {PiArrowCircleDownThin} from "react-icons/pi";

export const Projeto7_Header_EN = (props) => {
  
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);

    /****RESIZING****/
    const handleResize = () => {
      if(window.matchMedia("(max-width: 768px)").matches){
        setIsMobile(true);
      }
      else{
        setIsMobile(false);
      }
    };

    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);   

    /****-----------****/

  let child1SetState;
  const invokeChild1 = (setStateCallback) => {
    if (setStateCallback) {
      child1SetState = setStateCallback[1];
    } else {
      child1SetState(1);
    }
  };

    function ScrollDown(){
      props.scrollDownFromHeader();
    }

    return(
        <div className='projeto7-detail-container'>
            <div className='proj7-d-header'>
              <div className='proj7-header-contador'>
                <ContadorProjetos cbFunction={invokeChild1} limiteMaximo={window.$numeroProjetos}/>
              </div>
                <div className='p7-d-h-texto-container' style={{ transition: `transform 1s cubic-bezier(0.215, 0.61, 0.355, 1)`,  transform: `translateY(${props.scrollPosition * 0.2}vh)` }}>
                    <h1 className='p7-d-h-t-title'>
                        <span className='p7-d-h-t-span txt-anim-1'>Sanjotec </span>
                        <span className='p7-d-h-t-span txt-anim-2'>Panels</span>
                    </h1>
                    <div className='p7-d-h-t-b1'>
                        <span className='p7-d-h-t-span txt-anim-3 p7-d-h-f'>Lead Designer</span>
                        <p/>

                        {!isMobile ? 
                          <span className='p7-d-h-t-span txt-anim-4 p7-d-h-f-d' style={{pointerEvents:`none`}}>A <a title='' href="https://www.sanjotec.com/" style={{display:`inline`, pointerEvents:`auto`}}>SANJOTEC</a> is a Science and Technology Park focused on sup-</span>
                          :
                          <span className='p7-d-h-t-span txt-anim-4 p7-d-h-f-d'><a href="https://www.sanjotec.com/" style={{display:`inline`}}>SANJOTEC</a> is a Science and Technology </span>
                        }

                        {!isMobile ? 
                          <span className='p7-d-h-t-span txt-anim-5 p7-d-h-f-d'>porting entrepreneurship and accelerating projects in the techno-</span>
                          :
                          <span className='p7-d-h-t-span txt-anim-5 p7-d-h-f-d'>Park focused on supporting entrepreneur-</span>
                        }

                        {!isMobile ? 
                          <span className='p7-d-h-t-span txt-anim-6 p7-d-h-f-d'>logical and creative sectors.</span>
                          :
                          <span className='p7-d-h-t-span txt-anim-6 p7-d-h-f-d'>ship and accelerating projects in the techo-</span>
                        }
                        {!isMobile ? 
                          <span className='p7-d-h-t-span txt-anim-7 p7-d-h-f-d'>In this project, I’ve developed the visual renewal of informational </span>
                          :
                          <span className='p7-d-h-t-span txt-anim-7 p7-d-h-f-d'>logical and creative sectors.</span>
                          }
                        {!isMobile ? 
                          <span className='p7-d-h-t-span txt-anim-8 p7-d-h-f-d'>panels inside the building, aiming to express moments and values </span>
                          :
                          <span className='p7-d-h-t-span txt-anim-8 p7-d-h-f-d'>In this project, I’ve developed a visual rene-</span>
                        }
                        {!isMobile ? 
                          <span className='p7-d-h-t-span txt-anim-9 p7-d-h-f-d'>of Sanjotec as an institution.</span>
                          :
                          <span className='p7-d-h-t-span txt-anim-9 p7-d-h-f-d'>wal of informational panels inside the build-</span>
                        }
                        {!isMobile ? 
                          <span className='p7-d-h-t-span txt-anim-10 p7-d-h-f-d'>Based on and inspired by the hexagons present in its logo, a more </span>
                          :
                          <span className='p7-d-h-t-span txt-anim-10 p7-d-h-f-d'>ing, aiming to express moments and the </span>
                        }
                        {!isMobile ? 
                          <span className='p7-d-h-t-span txt-anim-11 p7-d-h-f-d'>modern and simple visual representation was conceived conveying </span>
                          :
                          <span className='p7-d-h-t-span txt-anim-11 p7-d-h-f-d'>values of Sanjotec as a great institution.</span>
                        }
                        {!isMobile ? 
                          <span className='p7-d-h-t-span txt-anim-12 p7-d-h-f-d'>its essence in a harmonious way.</span>
                          :
                          <span className='p7-d-h-t-span txt-anim-12 p7-d-h-f-d'>Based on and inspired by the hexagons </span>
                        }
                        {!isMobile ?
                          <span className='p7-d-h-t-span txt-anim-13 p7-d-h-f-d'></span>
                          :
                          <span className='p7-d-h-t-span txt-anim-13 p7-d-h-f-d'>present in its logo, a more modern and </span>
                        }
                        {!isMobile ? 
                          <span className='p7-d-h-t-span txt-anim-14 p7-d-h-f-d'></span>
                          :
                          <span className='p7-d-h-t-span txt-anim-14 p7-d-h-f-d'>simple visual representation was conceived, </span>
                        }
                        {!isMobile ? 
                          <span className='p7-d-h-t-span txt-anim-15 p7-d-h-f-d'></span>
                          :
                          <span className='p7-d-h-t-span txt-anim-15 p7-d-h-f-d'>conveying its essence in a harmonious way.</span>
                        }

                    </div>
                    <div className='p7-d-h-t-b2'>
                        <span className='p7-d-h-t-span txt-anim-16 p7-d-h-f'>Developed at <a href="https://www.outsidethebox.pt/" style={{display:`inline`, pointerEvents:`auto`}}>Outside The Box</a></span>
                        <span className='p7-d-h-t-span txt-anim-17 p7-d-h-f'>For Sanjotec</span>
                    </div>
                    <div className='p7-d-h-t-b3'>
                        <span className='p7-d-h-t-span txt-anim-18 p7-d-h-f s-down-txt' style={{opacity:`0`}} onClick={ScrollDown}>Scroll Down</span>
                        <span className='p7-d-h-t-span txt-anim-18 p7-d-h-f s-down-txt1' style={{opacity:`0`}} onClick={ScrollDown}><PiArrowCircleDownThin size={!isMobile ? 40:30}/></span>
                    </div>
                </div>
            </div>
        </div>
    );
}