import { useEffect, useRef, useState } from 'react';
import '../css/SmallMenuSlider.css';
import {useLocation} from 'react-router-dom';

import { FaAngleDoubleRight } from "react-icons/fa";

export const SmallMenuSlider = (props) => {

    const [transicaoInicial, setTransicaoInicial] = useState(!props.showMainMenu);
    const track = document.getElementById("image-track");

    /*const handleOnDown = e => track.dataset.mouseDownAt = e.clientX;

    const handleOnUp = () => {
    track.dataset.mouseDownAt = "0";  
    track.dataset.prevPercentage = track.dataset.percentage;
    }

    const handleOnMove = e => {
    if(track.dataset.mouseDownAt === "0") return;
    
    const mouseDelta = parseFloat(track.dataset.mouseDownAt) - e.clientX,
            maxDelta = window.innerWidth / 2;
    
    const percentage = (mouseDelta / maxDelta) * -100,
            nextPercentageUnconstrained = parseFloat(track.dataset.prevPercentage) + percentage,
            nextPercentage = Math.max(Math.min(nextPercentageUnconstrained, 0), -100);
    
    track.dataset.percentage = nextPercentage;
    
    track.animate({
        transform: `translate(${nextPercentage}%, -50%)`
    }, { duration: 1200, fill: "forwards" });
    
    for(const image of track.getElementsByClassName("image")) {
        image.animate({
        objectPosition: `${100 + nextPercentage}% center`
        }, { duration: 1200, fill: "forwards" });
    }
    }*/

    const location = useLocation();

    const timerRef = useRef(null);
    var canChangeToMainMenu = false;

    useEffect(() => {
        if(transicaoInicial){
            var imagens = document.getElementsByClassName("image");
            if(imagens != null && imagens.length > 0){
                var i = 1;

                for (const imagem of imagens) {
                    setTimeout(function() {
                        imagem.style.animation = `slideInTrack1 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s forwards`;
                      }, 100*i);

                      i++;
                  }
                setTransicaoInicial(false);
            }
        }
        else{

            if(props.showMainMenu && location.pathname === '/'){
                var imagens = document.getElementsByClassName("image");
                if(imagens != null && imagens.length > 0){
                    var i = 1;
    
                    for (const imagem of imagens) {
                        setTimeout(function() {
                            imagem.style.animation = `slideInTrack2 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s forwards`;
                          }, 100*i);
    
                          canChangeToMainMenu = i === window.$numeroProjetos;
                          i++;
                      }
                }
    
                if(canChangeToMainMenu){
                    setTimeout(function() {
                        props.callbackMainMenu();
                      }, 800);
                }
            }
        }
        clearTimeout(timerRef.current);
    });

    return(


        <div id="image-track-small" data-mouse-down-at="0" data-prev-percentage="0">
                {/*<div id="image-track-small-larrow" className='image'>←</div>*/}

                <img className="image" alt="Vihupole" onClick={() => {props.handleCallback(0)}} src="https://live.staticflickr.com/65535/53371696504_fd4e92c5ef_o.jpg" draggable="false"/>
                <img className="image" alt="Santorini Shoes" onClick={() => {props.handleCallback(1)}} src="https://live.staticflickr.com/65535/53358824965_b6524ffa52_k.jpg" draggable="false"/>
                <img className="image" alt="Conservatório Música" onClick={() => {props.handleCallback(2)}} src="https://live.staticflickr.com/65535/53358698349_4deef9c547_k.jpg" draggable="false"/>
                <img className="image" alt="Azores Tek" onClick={() => {props.handleCallback(3)}} src="https://live.staticflickr.com/65535/53358698354_4f5a538ddd_k.jpg" draggable="false" />
                <img className="image" alt="Andrik" onClick={() => {props.handleCallback(4)}} src="https://live.staticflickr.com/65535/53358698344_a4ce1c4a3f_k.jpg" draggable="false" />
                <img className="image" alt="Transtech" onClick={() => {props.handleCallback(5)}} src="https://live.staticflickr.com/65535/53357489817_f655a07dcf_k.jpg" draggable="false" />
                <img className="image" alt="Sanjotec" onClick={() => {props.handleCallback(6)}} src="https://live.staticflickr.com/65535/53411229118_2e18d53dff_k.jpg" draggable="false" />
                <img className="image" alt="Energia.pt" onClick={() => {props.handleCallback(7)}} src="https://live.staticflickr.com/65535/53905940222_9fb68a8efa_k.jpg" draggable="false" />
                <img className="image" alt="Stand Abílio Pinto" onClick={() => {props.handleCallback(8)}} src="https://live.staticflickr.com/65535/53907277175_76e768579e_k.jpg" draggable="false" />
                <img className="image" alt="Joana. beauty" onClick={() => {props.handleCallback(9)}} src="https://live.staticflickr.com/65535/53906836611_8f15402094_k.jpg" draggable="false" />

                {/*<div id="image-track-small-rarrow" className='image'><FaAngleDoubleRight size={32}/></div>*/}
            </div>

        /*https://live.staticflickr.com/65535/53358365641_4d70f22f1b_k.jpg*/
        /*<div className='image-track-container'>
            <div id="image-track-small" data-mouse-down-at="0" data-prev-percentage="0">
                <div className='s-m-s-larrow'>←</div>

                <img className="image" alt="Vihupole" onClick={() => {props.handleCallback(0)}} src="https://live.staticflickr.com/65535/53371696504_fd4e92c5ef_o.jpg" draggable="false"/>
                <img className="image" alt="Santorini Shoes" onClick={() => {props.handleCallback(1)}} src="https://live.staticflickr.com/65535/53358824965_b6524ffa52_k.jpg" draggable="false"/>
                <img className="image" alt="Conservatório Música" onClick={() => {props.handleCallback(2)}} src="https://live.staticflickr.com/65535/53358698349_4deef9c547_k.jpg" draggable="false"/>
                <img className="image" alt="Azores Tek" onClick={() => {props.handleCallback(3)}} src="https://live.staticflickr.com/65535/53358698354_4f5a538ddd_k.jpg" draggable="false" />
                <img className="image" alt="Andrik" onClick={() => {props.handleCallback(4)}} src="https://live.staticflickr.com/65535/53358698344_a4ce1c4a3f_k.jpg" draggable="false" />
                <img className="image" alt="Transtech" onClick={() => {props.handleCallback(5)}} src="https://live.staticflickr.com/65535/53357489817_f655a07dcf_k.jpg" draggable="false" />
                <img className="image" alt="Sanjotec" onClick={() => {props.handleCallback(6)}} src="https://live.staticflickr.com/65535/53411229118_2e18d53dff_k.jpg" draggable="false" />
                <img className="image" alt="Energia.pt" onClick={() => {props.handleCallback(7)}} src="https://live.staticflickr.com/65535/53905940222_9fb68a8efa_k.jpg" draggable="false" />
                <img className="image" alt="Stand Abílio Pinto" onClick={() => {props.handleCallback(8)}} src="https://live.staticflickr.com/65535/53907277175_76e768579e_k.jpg" draggable="false" />
                <img className="image" alt="Joana. beauty" onClick={() => {props.handleCallback(9)}} src="https://live.staticflickr.com/65535/53906836611_8f15402094_k.jpg" draggable="false" />

                <div className='s-m-s-rarrow'>→</div>
            </div>
        </div>*/

        
        
    );
}