import './Projeto6_Content.css';
import {useEffect, useRef, useState, useMemo} from 'react';

export const Projeto6_Content = (props) =>{

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const ref6 = useRef(null);
    const ref7 = useRef(null);
    const ref8 = useRef(null);

    useIsInViewport(ref1);
    useIsInViewport(ref2);
    useIsInViewport(ref3);
    useIsInViewport(ref4);
    useIsInViewport(ref5);
    useIsInViewport(ref6);
    useIsInViewport(ref7);
    useIsInViewport(ref8);

    const videoId = 'IguSHak7EvU';

    // Event handlers
    const onReady = (event) => {
      // Access to player in all event handlers via event.target
      event.target.pauseVideo();
    };

    function useIsInViewport(ref) {
        const [isIntersecting, setIsIntersecting] = useState(false);
      
        const observer = useMemo(
          () =>
            new IntersectionObserver(([entry]) =>
              setIsIntersecting(entry.isIntersecting),
            ),
          [],
        );
    
    useEffect(() => {
            observer.observe(ref.current);
        
            return () => {
              observer.disconnect();
            };
          }, [ref, observer]);

          if(isIntersecting){
            if(ref.current.style?.animation === undefined || ref.current.style?.animation === null || ref.current.style?.animation === ""){
                ref.current.style.opacity = "1";
                //ref.current.style.filter = "blur(0px)";
            }

            props.handleCallBack(ref.current.id.charAt(ref.current.id.length-1));
          }
        
          return isIntersecting;
    }
   

    return(
        <div className='p6-d-c-container'>

            <div className='p6-d-c-images'>

            {/*<div className="projetos-small-menu-slider">
              <ProjectsMinimap sections={sections_minimap} scrollPosition={props.scrollPosition} handleCallBack={callbackMinimapa}/>
            </div>*/}

            {/*props.sections.map((section, index) => (
              
                <div
                    ref={getRef(index)}
                    key={index}
                    className={"p1-d-c-i i" + index}
                >
                    {section}
                </div>
                
            ))*/}

                <div ref={ref1} id='id-p6-content0' className='p6-d-c-i i0'/>
                <div ref={ref2} id='id-p6-content1' className='p6-d-c-i i1'/>
                <div ref={ref3} id='id-p6-content2' className='p6-d-c-i i2'/>
                <div ref={ref4} id='id-p6-content3' className='p6-d-c-i i3'/>
                <div ref={ref5} id='id-p6-content4' className='p6-d-c-i i4'/>
                <div ref={ref6} id='id-p6-content5' className='p6-d-c-i i5'/>
                <div ref={ref7} id='id-p6-content6' className='p6-d-c-i i6'>
                  <iframe frameborder="0" allowfullscreen="" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                  title="Projeto Transtech" width="100%" height="100%" 
                  style={{pointerEvents: `none`}}
                  src="https://www.youtube.com/embed/IguSHak7EvU?autoplay=1&amp;mute=1&amp;controls=0&amp;loop=1&amp;playlist=IguSHak7EvU&amp;enablejsapi=1&amp;origin=http%3A%2F%2Flocalhost%3A3000&amp;widgetid=3" 
                  ></iframe>
                </div>
                <div ref={ref8} id='id-p6-content7' className='p6-d-c-i i7'/>
            </div>

            
        </div>
    );
}