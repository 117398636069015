import React, { useEffect, useState, useRef, useMemo } from 'react';
import '../../../css/General.css';
import './Projeto4_Header.css';
import ContadorProjetos from '../../ContadorProjetos';
import {PiArrowCircleDownThin} from "react-icons/pi";

export const Projeto4_Header_EN = (props) => {

  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);

    /****RESIZING****/
    const handleResize = () => {
      if(window.matchMedia("(max-width: 768px)").matches){
        setIsMobile(true);
      }
      else{
        setIsMobile(false);
      }
    };

    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);   

    /****-----------****/

  let child1SetState;
  const invokeChild1 = (setStateCallback) => {
    if (setStateCallback) {
      child1SetState = setStateCallback[1];
    } else {
      child1SetState(4);
    }
  };

    function ScrollDown(){
      props.scrollDownFromHeader();
    }

    return(
        <div className='projeto4-detail-container'>
            <div className='proj4-d-header'>
              <div className='proj4-header-contador'>
                <ContadorProjetos cbFunction={invokeChild1} limiteMaximo={window.$numeroProjetos}/>
              </div>
                <div className='p4-d-h-texto-container' style={{ transition: `transform 1s cubic-bezier(0.215, 0.61, 0.355, 1)`,  transform: `translateY(${props.scrollPosition * 0.2}vh)` }}>
                    <h1 className='p4-d-h-t-title'>
                        <span className='p4-d-h-t-span txt-anim-1'>Azores Tek</span>
                    </h1>
                    <div className='p4-d-h-t-b1'>
                        <span className='p4-d-h-t-span txt-anim-2 p4-d-h-f'>Lead Designer</span>
                        <p/>
                        {!isMobile ? 
                          <span className='p4-d-h-t-span txt-anim-3 p4-d-h-f-d'>AZORESTEK is an event dedicated to technology, companies, </span>
                          :
                          <span className='p4-d-h-t-span txt-anim-3 p4-d-h-f-d'>AZORESTEK is an event dedicated to </span>
                        }
                        {!isMobile ? 
                          <span className='p4-d-h-t-span txt-anim-4 p4-d-h-f-d'>innovation and entrepreneurship, which took place in the city of </span>
                          :
                          <span className='p4-d-h-t-span txt-anim-4 p4-d-h-f-d'>technology, companies, innovation and </span>
                        }
                        {!isMobile ? 
                          <span className='p4-d-h-t-span txt-anim-5 p4-d-h-f-d'>Lagoa, on the island of São Miguel.</span>
                          :
                          <span className='p4-d-h-t-span txt-anim-5 p4-d-h-f-d'>entrepreneurship, which took place in </span>
                        }
                        {!isMobile ? 
                          <span className='p4-d-h-t-span txt-anim-6 p4-d-h-f-d'>The event aimed to boost projects and businesses, bringing the </span>
                          :
                          <span className='p4-d-h-t-span txt-anim-6 p4-d-h-f-d'>the city of Lagoa, on the island of São </span>
                        }
                        {!isMobile ? 
                          <span className='p4-d-h-t-span txt-anim-7 p4-d-h-f-d'>most diverse speakers and technology projects to the city.</span>
                          :
                          <span className='p4-d-h-t-span txt-anim-7 p4-d-h-f-d'>Miguel.</span>
                        }
                        {!isMobile ? 
                          <span className='p4-d-h-t-span txt-anim-8 p4-d-h-f-d'></span>
                          :
                          <span className='p4-d-h-t-span txt-anim-8 p4-d-h-f-d'>The event aimed to boost projects and </span>
                        }
                        {!isMobile ? 
                          <span className='p4-d-h-t-span txt-anim-9 p4-d-h-f-d'>This project consisted of creating the entire graphic image for the </span>
                          :
                          <span className='p4-d-h-t-span txt-anim-9 p4-d-h-f-d'>businesses, bringing the most diverse </span>
                        }
                        {!isMobile ? 
                          <span className='p4-d-h-t-span txt-anim-10  p4-d-h-f-d'>event, from the logo, program, outdoors, space decoration, online </span>
                          :
                          <span className='p4-d-h-t-span txt-anim-10 p4-d-h-f-d'>speakers and technology projects to </span>
                        }
                        {!isMobile ? 
                          <span className='p4-d-h-t-span txt-anim-11 p4-d-h-f-d'>and offline advertising, website, T-shirts for staff, credentials and </span>
                          :
                          <span className='p4-d-h-t-span txt-anim-11 p4-d-h-f-d'>the city.</span>
                        }
                        {!isMobile ? 
                          <span className='p4-d-h-t-span txt-anim-12 p4-d-h-f-d'>even gifts such as pencils, notebooks, totebags etc.</span>
                          :
                          <span className='p4-d-h-t-span txt-anim-12 p4-d-h-f-d'>This project consisted of creating the </span>
                        }
                        {!isMobile ? <span className='p4-d-h-t-span txt-anim-13 p4-d-h-f-d'></span>:<span className='p4-d-h-t-span txt-anim-13 p4-d-h-f-d'>entire graphic image for the event, </span>}
                        {!isMobile ? <span className='p4-d-h-t-span txt-anim-14 p4-d-h-f-d'></span>:<span className='p4-d-h-t-span txt-anim-14 p4-d-h-f-d'>from the logo, program, outdoors, </span>}
                        {!isMobile ? <span className='p4-d-h-t-span txt-anim-15 p4-d-h-f-d'></span>:<span className='p4-d-h-t-span txt-anim-15 p4-d-h-f-d'>space decoration, online and offline </span>}
                        {!isMobile ? <span className='p4-d-h-t-span txt-anim-16 p4-d-h-f-d'></span>:<span className='p4-d-h-t-span txt-anim-16 p4-d-h-f-d'>advertising, website, T-shirts for staff, </span>}
                        {!isMobile ? <span className='p4-d-h-t-span txt-anim-17 p4-d-h-f-d'></span>:<span className='p4-d-h-t-span txt-anim-17 p4-d-h-f-d'>credentials and even gifts such as </span>}
                        {!isMobile ? <span className='p4-d-h-t-span txt-anim-18 p4-d-h-f-d'></span>:<span className='p4-d-h-t-span txt-anim-18 p4-d-h-f-d'>pencils, notebooks, totebags etc.</span>}

                    </div>
                    <div className='p4-d-h-t-b2'>
                        {!isMobile ? 
                          <span className='p4-d-h-t-span txt-anim-19 p4-d-h-f'>Developed at the company Outside The Box</span>
                          :
                          <span className='p4-d-h-t-span txt-anim-19 p4-d-h-f'>Developed at Outside The Box</span>
                        }
                        {!isMobile ? 
                          <span className='p4-d-h-t-span txt-anim-20 p4-d-h-f'>With scenography by designers Joana Santos and Hugo Silva</span>
                          :
                          <span className='p4-d-h-t-span txt-anim-20 p4-d-h-f'>With scenography by designers </span>
                        }
                        {!isMobile ? 
                          <span className='p4-d-h-t-span txt-anim-21 p4-d-h-f'>Event organized by Like a Pro and with support from Nonagon </span>
                          :
                          <span className='p4-d-h-t-span txt-anim-21 p4-d-h-f'>Joana Santos and Hugo Silva. </span>
                        }
                        {!isMobile ? 
                          <span className='p4-d-h-t-span txt-anim-22 p4-d-h-f'>São Miguel Science and Technology Park</span>
                          :
                          <span className='p4-d-h-t-span txt-anim-22 p4-d-h-f'>Event organized by Like a Pro and </span>
                        }
                        {!isMobile ? <span className='p4-d-h-t-span txt-anim-23 p4-d-h-f-d'></span>:<span className='p4-d-h-t-span txt-anim-23 p4-d-h-f'>with support from Nonagon, São </span>}
                        {!isMobile ? <span className='p4-d-h-t-span txt-anim-23 p4-d-h-f-d'></span>:<span className='p4-d-h-t-span txt-anim-23 p4-d-h-f'>Miguel Science and Technology Park</span>}
                      
                    </div>
                    <div className='p4-d-h-t-b3'>
                        <span className='p4-d-h-t-span txt-anim-24 p4-d-h-f s-down-txt' style={{opacity:`0`}} onClick={ScrollDown}>Scroll Down</span>
                        <span className='p4-d-h-t-span txt-anim-24 p4-d-h-f s-down-txt1' style={{opacity:`0`}} onClick={ScrollDown}><PiArrowCircleDownThin size={!isMobile ? 40:30}/></span>
                    </div>
                </div>
            </div>
        </div>
    );
}