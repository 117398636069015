import React, { useEffect, useState, useRef, useMemo } from 'react';
import '../../../css/General.css';
import './Projeto10_Header.css';
import ContadorProjetos from '../../ContadorProjetos';
import {PiArrowCircleDownThin} from "react-icons/pi";

export const Projeto10_Header_EN = (props) => {

  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);

    /****RESIZING****/
    const handleResize = () => {
      if(window.matchMedia("(max-width: 768px)").matches){
        setIsMobile(true);
      }
      else{
        setIsMobile(false);
      }
    };

    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);   

    /****-----------****/


  let child1SetState;
  const invokeChild1 = (setStateCallback) => {
    if (setStateCallback) {
      child1SetState = setStateCallback[1];
    } else {
      child1SetState(1);
    }
  };

    function ScrollDown(){
      props.scrollDownFromHeader();
    }

    return(
        <div className='projeto10-detail-container'>
            <div className='proj10-d-header'>
              <div className='proj10-header-contador'>
                <ContadorProjetos cbFunction={invokeChild1} limiteMaximo={window.$numeroProjetos}/>
              </div>
                <div className='p10-d-h-texto-container' style={{ transition: `transform 1s cubic-bezier(0.215, 0.61, 0.355, 1)`,  transform: `translateY(${props.scrollPosition * 0.2}vh)` }}>
                    <h1 className='p10-d-h-t-title'>
                        <span className='p10-d-h-t-span txt-anim-1'>Joana. beauty </span>
                        <span className='p10-d-h-t-span txt-anim-2'>Cosmetics</span>
                    </h1>
                    <div className='p10-d-h-t-b1'>
                        <span className='p10-d-h-t-span txt-anim-3 p10-d-h-f'>Lead Designer</span>
                        <p/>
                        {!isMobile ? 
                          <span className='p10-d-h-t-span txt-anim-4 p10-d-h-f-d'>Joana.beauty is a brand of cosmetic products for children and pre-</span>
                          :
                          <span className='p10-d-h-t-span txt-anim-4 p10-d-h-f-d'>Joana.beauty is a brand of cosmetic </span>
                        }
                        {!isMobile ? 
                          <span className='p10-d-h-t-span txt-anim-5 p10-d-h-f-d'>teens, produced in Portugal. It’s a brand created by little Joaninha, </span>
                          :
                          <span className='p10-d-h-t-span txt-anim-5 p10-d-h-f-d'>products for children and pre-teens, </span>
                        }
                        {!isMobile ? 
                          <span className='p10-d-h-t-span txt-anim-6 p10-d-h-f-d'>a 10-year-old who wanted to create her own line of products for </span>
                          :
                          <span className='p10-d-h-t-span txt-anim-6 p10-d-h-f-d'>produced in Portugal. It is a brand </span>
                        }
                        {!isMobile ? 
                          <span className='p10-d-h-t-span txt-anim-7 p10-d-h-f-d'>herself and her friends. </span>
                          :
                          <span className='p10-d-h-t-span txt-anim-7 p10-d-h-f-d'>created by little Joaninha, a 10-year-</span>
                        }
                        {!isMobile ? 
                          <span className='p10-d-h-t-span txt-anim-8 p10-d-h-f-d'>In this project, I've developed the logo, labeling, product boxes, pho-</span>
                          :
                          <span className='p10-d-h-t-span txt-anim-8 p10-d-h-f-d'>old who wanted to create her own line </span>
                        }
                        {!isMobile ? 
                          <span className='p10-d-h-t-span txt-anim-9 p10-d-h-f-d'>tos as well as content creation for her Instagram account.</span>
                          :
                          <span className='p10-d-h-t-span txt-anim-9 p10-d-h-f-d'>of products for herself and her friends. </span>
                        }
                        {!isMobile ? <span className='p10-d-h-t-span txt-anim-10 p10-d-h-f-d'></span>:<span className='p10-d-h-t-span txt-anim-10 p10-d-h-f-d'>In this project, I've developed the logo, </span>}
                        {!isMobile ? <span className='p10-d-h-t-span txt-anim-11 p10-d-h-f-d'></span>:<span className='p10-d-h-t-span txt-anim-11 p10-d-h-f-d'>labeling, product boxes, photos as well </span>}
                        {!isMobile ? <span className='p10-d-h-t-span txt-anim-12 p10-d-h-f-d'></span>:<span className='p10-d-h-t-span txt-anim-12 p10-d-h-f-d'>as content creation for her Instagram </span>}
                        {!isMobile ? <span className='p10-d-h-t-span txt-anim-13 p10-d-h-f-d'></span>:<span className='p10-d-h-t-span txt-anim-13 p10-d-h-f-d'>account.</span>}
                        
                    </div>
                    <div className='p10-d-h-t-b2'>
                        <span className='p10-d-h-t-span txt-anim-14 p10-d-h-f'>Developed at <a href="https://alphanna.com/" style={{display:`inline`, pointerEvents:`auto`}}>Alphanna</a></span>
                    </div>
                    <div className='p10-d-h-t-b3'>
                        <span className='p10-d-h-t-span txt-anim-15 p10-d-h-f s-down-txt' style={{opacity:`0`}} onClick={ScrollDown}>Scroll Down</span>
                        <span className='p10-d-h-t-span txt-anim-15 p10-d-h-f s-down-txt1' style={{opacity:`0`}} onClick={ScrollDown}><PiArrowCircleDownThin size={!isMobile ? 40:30}/></span>
                    </div>
                </div>
            </div>
        </div>
    );
}