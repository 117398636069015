import './Projeto5_Content.css';
import {useEffect, useRef, useState, useMemo} from 'react';

//imagens
/*import PS_0 from '../../../assets/Projeto1/P1_0.jpg';
import PS_1 from '../../../assets/Projeto1/P1_1.jpg';
import PS_2 from '../../../assets/Projeto1/P1_2.jpg';*/

/*const sections_minimap = [
    <div className='p1-d-c-i i0' style={{opacity: 1, filter:`blur(0px)`}}/>,
];*/

export const Projeto5_Content = (props) =>{

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);

    useIsInViewport(ref1);
    useIsInViewport(ref2);
    useIsInViewport(ref3);
    useIsInViewport(ref4);
    useIsInViewport(ref5);

    function useIsInViewport(ref) {
        const [isIntersecting, setIsIntersecting] = useState(false);
      
        const observer = useMemo(
          () =>
            new IntersectionObserver(([entry]) =>
              setIsIntersecting(entry.isIntersecting),
            ),
          [],
        );
    
    useEffect(() => {
            observer.observe(ref.current);
        
            return () => {
              observer.disconnect();
            };
          }, [ref, observer]);

          if(isIntersecting){
            if(ref.current.style?.animation === undefined || ref.current.style?.animation === null || ref.current.style?.animation === ""){
                ref.current.style.opacity = "1";
                //ref.current.style.filter = "blur(0px)";
            }

            props.handleCallBack(ref.current.id.charAt(ref.current.id.length-1));
          }
        
          return isIntersecting;
    }
   

    return(
        <div className='p5-d-c-container'>

            <div className='p5-d-c-images'>

            {/*<div className="projetos-small-menu-slider">
              <ProjectsMinimap sections={sections_minimap} scrollPosition={props.scrollPosition} handleCallBack={callbackMinimapa}/>
            </div>*/}

            {/*props.sections.map((section, index) => (
              
                <div
                    ref={getRef(index)}
                    key={index}
                    className={"p1-d-c-i i" + index}
                >
                    {section}
                </div>
                
            ))*/}

                <div ref={ref1} id='id-p5-content0' className='p5-d-c-i i0'/>
                <div ref={ref2} id='id-p5-content1' className='p5-d-c-i i1'/>
                <div ref={ref3} id='id-p5-content2' className='p5-d-c-i i2'/>
                <div ref={ref4} id='id-p5-content3' className='p5-d-c-i i3'/>
                <div ref={ref5} id='id-p5-content4' className='p5-d-c-i i4'/>
                
            </div>

            
        </div>
    );
}