import './AboutContent.css';
import './../../../css/General.css';
import { useEffect, useRef, useState, useMemo } from 'react';

export const AboutContent_Header = (props) =>{

  const language = localStorage.getItem('language') || 'pt';

  const sufixo = props.minimapa ? "s":"r";

  const ref99 = useRef(null); //title

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);
  const ref9 = useRef(null);

  useIsInViewport(ref99); //title

  useIsInViewport(ref1);
  useIsInViewport(ref2);
  useIsInViewport(ref3);
  useIsInViewport(ref4);
  useIsInViewport(ref5);
  useIsInViewport(ref6);
  useIsInViewport(ref7);
  useIsInViewport(ref8);
  useIsInViewport(ref9);

  function useIsInViewport(ref) {
      const [isIntersecting, setIsIntersecting] = useState(false);
    
      const observer = useMemo(
        () =>
          new IntersectionObserver(([entry]) =>
            setIsIntersecting(sufixo == "r" && entry.isIntersecting),
          ),
        [],
      );
  
  useEffect(() => {
          observer.observe(ref.current);
      
          return () => {
            observer.disconnect();
          };
        }, [ref, observer]);

        if(isIntersecting){
          if(ref.current.style?.animation === undefined || ref.current.style?.animation === null || ref.current.style?.animation === ""){
              ref.current.style.animation = "abt-slide-up-text .5s ease-in-out 0.1s forwards";
          }
        }
      
        return isIntersecting;
  }

    return(
        
        <div className='abt-content-header-grid'>
          <div ref={ref99} className={'abt-h-t-' + sufixo}>{language === "pt" ? "Olá!":"Hi there!"}</div>

          {
            language === "pt" ?

            <div className={'abt-h-content-' + sufixo}>
                <div className='abt-h-r-1'>
                    <div ref={ref1} className={'a-h-l1' + sufixo} style={{opacity:0}}>Eu sou a Sara e sou designer gráfica!</div>
                    <div ref={ref2} className={'a-h-l2' + sufixo} style={{opacity:0}}>Sou apaixonada por cor, formas e </div>
                    <div ref={ref3} className={'a-h-l3' + sufixo} style={{opacity:0}}>layouts e adoro colaborar com os </div>
                    <div ref={ref4} className={'a-h-l4' + sufixo} style={{opacity:0}}>clientes e dar vida à sua visão.</div>
                </div>
                <div className='abt-h-r-2'>
                    <div ref={ref5} className={'a-h-l5' + sufixo} style={{opacity:0}}>Para além do design gráfico, tenho </div>
                    <div ref={ref6} className={'a-h-l6' + sufixo} style={{opacity:0}}>interesse em UX/UI design. Gosto de</div>
                    <div ref={ref7} className={'a-h-l7' + sufixo} style={{opacity:0}}>criar novas e cativantes experiências </div>
                    <div ref={ref8} className={'a-h-l8' + sufixo} style={{opacity:0}}>digitais que possam fazer a diferença </div>
                    <div ref={ref9} className={'a-h-l9' + sufixo} style={{opacity:0}}>na vida dos utilizadores.</div>
                </div>
            </div>

            :

            <div className={'abt-h-content-' + sufixo}>
                <div className='abt-h-r-1'>
                    <div ref={ref1} className={'a-h-l1' + sufixo} style={{opacity:0}}>I am Sara and I'm a graphic designer!</div>
                    <div ref={ref2} className={'a-h-l2' + sufixo} style={{opacity:0}}>I’m passionate for color, shapes and </div>
                    <div ref={ref3} className={'a-h-l3' + sufixo} style={{opacity:0}}>layouts and I love collaborating with</div>
                    <div ref={ref4} className={'a-h-l4' + sufixo} style={{opacity:0}}>clients to bring their vision to life!</div>
                </div>
                <div className='abt-h-r-2'>
                    <div ref={ref5} className={'a-h-l5' + sufixo} style={{opacity:0}}>In addition to graphic design, I've</div>
                    <div ref={ref6} className={'a-h-l6' + sufixo} style={{opacity:0}}>developed an interest in UX/UI design.</div>
                    <div ref={ref7} className={'a-h-l7' + sufixo} style={{opacity:0}}>I'm excited to create new and engaging</div>
                    <div ref={ref8} className={'a-h-l8' + sufixo} style={{opacity:0}}>digital experiences that can  make a</div>
                    <div ref={ref9} className={'a-h-l9' + sufixo} style={{opacity:0}}>difference in users lives.</div>
                </div>
            </div>
          }
        </div>
    );
}