import React, { useEffect, useState, useRef, useMemo } from 'react';
import '../../../css/General.css';
import './Projeto5_Header.css';
import ContadorProjetos from '../../ContadorProjetos';
import {PiArrowCircleDownThin} from "react-icons/pi";

export const Projeto5_Header = (props) => {

  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);

    /****RESIZING****/
    const handleResize = () => {
      if(window.matchMedia("(max-width: 768px)").matches){
        setIsMobile(true);
      }
      else{
        setIsMobile(false);
      }
    };

    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);   

    /****-----------****/ 

  let child1SetState;
  const invokeChild1 = (setStateCallback) => {
    if (setStateCallback) {
      child1SetState = setStateCallback[1];
    } else {
      child1SetState(1);
    }
  };

    function ScrollDown(){
      props.scrollDownFromHeader();
    }

    return(
        <div className='projeto5-detail-container'>
            <div className='proj5-d-header'>
              <div className='proj5-header-contador'>
                <ContadorProjetos cbFunction={invokeChild1} limiteMaximo={window.$numeroProjetos}/>
              </div>
                <div className='p5-d-h-texto-container' style={{ transition: `transform 1s cubic-bezier(0.215, 0.61, 0.355, 1)`,  transform: `translateY(${props.scrollPosition * 0.2}vh)` }}>
                    <h1 className='p5-d-h-t-title'>
                        <span className='p5-d-h-t-span txt-anim-1'>Logótipo</span>
                        <span className='p5-d-h-t-span txt-anim-2'>Andrik</span>
                    </h1>
                    <div className='p5-d-h-t-b1'>
                        <span className='p5-d-h-t-span txt-anim-3 p5-d-h-f'>Lead Designer</span>
                        <p/>

                        {!isMobile ? 
                          <span className='p5-d-h-t-span txt-anim-4 p5-d-h-f-d'>Andrik é uma empresa de calçado com mais de 25 anos de expe-</span>
                          :
                          <span className='p5-d-h-t-span txt-anim-4 p5-d-h-f-d'>Andrik é uma empresa de calçado com</span>
                        }

                        {!isMobile ? 
                          <span className='p5-d-h-t-span txt-anim-5 p5-d-h-f-d'>riência, o seu foco é o desenvolvimento de todo o tipo de calçado</span>
                          :
                          <span className='p5-d-h-t-span txt-anim-5 p5-d-h-f-d'>mais de 25 anos de experiência, o seu</span>
                        }

                        {!isMobile ? 
                          <span className='p5-d-h-t-span txt-anim-6 p5-d-h-f-d'>para empresas.</span>
                          :
                          <span className='p5-d-h-t-span txt-anim-6 p5-d-h-f-d'>foco é o desenvolvimento de todo o tipo</span>
                        }
                        {!isMobile ? 
                          <span className='p5-d-h-t-span txt-anim-7 p5-d-h-f-d'>A empresa procurou dar uma nova imagem ao seu negócio, atra-</span>
                          :
                          <span className='p5-d-h-t-span txt-anim-7 p5-d-h-f-d'>de calçado para empresas.</span>
                          }
                        {!isMobile ? 
                          <span className='p5-d-h-t-span txt-anim-8 p5-d-h-f-d'>vés do rebranding do logótipo e criação de estacionário, blocos, </span>
                          :
                          <span className='p5-d-h-t-span txt-anim-8 p5-d-h-f-d'>A empresa procurou dar uma nova ima-</span>
                        }
                        {!isMobile ? 
                          <span className='p5-d-h-t-span txt-anim-9 p5-d-h-f-d'>canetas entre outros materiais essenciais ao seu ofício.</span>
                          :
                          <span className='p5-d-h-t-span txt-anim-9 p5-d-h-f-d'>gem ao seu negócio, através do rebran-</span>
                        }
                        {!isMobile ? 
                          <span className='p5-d-h-t-span txt-anim-10 p5-d-h-f-d'></span>
                          :
                          <span className='p5-d-h-t-span txt-anim-10 p5-d-h-f-d'>ding do logótipo e criação de estacionário,</span>
                        }
                        {!isMobile ? 
                          <span className='p5-d-h-t-span txt-anim-11 p5-d-h-f-d'></span>
                          :
                          <span className='p5-d-h-t-span txt-anim-11 p5-d-h-f-d'>blocos, canetas entre outros materiais</span>
                        }
                        {!isMobile ? 
                          <span className='p5-d-h-t-span txt-anim-12 p5-d-h-f-d'></span>
                          :
                          <span className='p5-d-h-t-span txt-anim-12 p5-d-h-f-d'>essenciais ao seu ofício.</span>
                        }

                    </div>
                    <div className='p5-d-h-t-b2'>
                        <span className='p5-d-h-t-span txt-anim-13 p5-d-h-f'>Desenvolvido na empresa Outside The Box.</span>
                    </div>
                    <div className='p5-d-h-t-b3'>
                        <span className='p5-d-h-t-span txt-anim-14 p5-d-h-f s-down-txt' style={{opacity:`0`}} onClick={ScrollDown}>Scroll Down</span>
                        <span className='p5-d-h-t-span txt-anim-14 p5-d-h-f s-down-txt1' style={{opacity:`0`}} onClick={ScrollDown}><PiArrowCircleDownThin size={!isMobile ? 40:30}/></span>
                    </div>
                </div>
            </div>
        </div>
    );
}