import './Projeto1_Content.css';
import {useEffect, useRef, useState, useMemo} from 'react';
import { ProjectsMinimap } from '../../Generic/ProjectsMinimap';

/*import Img1 from '../../../assets/Projeto1/projeto1_content1.png';
import Img2 from '../../../assets/Projeto1/projeto1_content2.png';
import Img3 from '../../../assets/Projeto1/projeto1_content3.png';
import Img4 from '../../../assets/Projeto1/projeto1_content4.png';
import Img5 from '../../../assets/Projeto1/projeto1_content5.png';
import Img6 from '../../../assets/Projeto1/projeto1_content6.png';*/

//imagens
/*import PS_0 from '../../../assets/Projeto1/P1_0.jpg';
import PS_1 from '../../../assets/Projeto1/P1_1.jpg';
import PS_2 from '../../../assets/Projeto1/P1_2.jpg';*/

const sections_minimap = [
    <div className='p1-d-c-i i0' style={{opacity: 1, filter:`blur(0px)`}}/>,
    <div className='p1-d-c-i i1' style={{opacity: 1, filter:`blur(0px)`}}/>,
    <div className='p1-d-c-i i2' style={{opacity: 1, filter:`blur(0px)`}}/>,
    <div className='p1-d-c-i i3' style={{opacity: 1, filter:`blur(0px)`}}/>,
    <div className='p1-d-c-i i4' style={{opacity: 1, filter:`blur(0px)`}}/>,
    <div className='p1-d-c-i i5' style={{opacity: 1, filter:`blur(0px)`}}/>,
    /*<div className='p1-d-c-i i6' style={{opacity: 1, filter:`blur(0px)`}}/>,
    <div className='p1-d-c-i i7' style={{opacity: 1, filter:`blur(0px)`}}/>,
    <div className='p1-d-c-i i8' style={{opacity: 1, filter:`blur(0px)`}}/>,
    <div className='p1-d-c-i i9' style={{opacity: 1, filter:`blur(0px)`}}/>,
    <div className='p1-d-c-i i10' style={{opacity: 1, filter:`blur(0px)`}}/>,
    <div className='p1-d-c-i i11' style={{opacity: 1, filter:`blur(0px)`}}/>*/
];

export const Projeto1_Content = (props) =>{

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const ref6 = useRef(null);
    const ref7 = useRef(null);
    /*const ref8 = useRef(null);
    const ref9 = useRef(null);
    const ref10 = useRef(null);
    const ref11 = useRef(null);
    const ref12 = useRef(null);*/

    useIsInViewport(ref1);
    useIsInViewport(ref2);
    useIsInViewport(ref3);
    useIsInViewport(ref4);
    useIsInViewport(ref5);
    useIsInViewport(ref6);
    useIsInViewport(ref7);
    /*useIsInViewport(ref8);
    useIsInViewport(ref9);
    useIsInViewport(ref10);
    useIsInViewport(ref11);
    useIsInViewport(ref12);*/

    const videoId = 'URRX1R1zI44';

    // Event handlers
    const onReady = (event) => {
      // Access to player in all event handlers via event.target
      event.target.pauseVideo();
    };

    function useIsInViewport(ref) {
        const [isIntersecting, setIsIntersecting] = useState(false);
      
        const observer = useMemo(
          () =>
            new IntersectionObserver(([entry]) =>
              setIsIntersecting(entry.isIntersecting),
            ),
          [],
        );
    
    useEffect(() => {
            observer.observe(ref.current);
        
            return () => {
              observer.disconnect();
            };
          }, [ref, observer]);

          if(isIntersecting){
            if(ref.current.style?.animation === undefined || ref.current.style?.animation === null || ref.current.style?.animation === ""){
                ref.current.style.opacity = "1";
                //ref.current.style.filter = "blur(0px)";
            }

            props.handleCallBack(ref.current.id.charAt(ref.current.id.length-1));
          }
        
          return isIntersecting;
    }
   

    return(
        <div className='p1-d-c-container'>

            <div className='p1-d-c-images'>

            {/*<div className="projetos-small-menu-slider">
              <ProjectsMinimap sections={sections_minimap} scrollPosition={props.scrollPosition} handleCallBack={callbackMinimapa}/>
            </div>*/}

            {/*props.sections.map((section, index) => (
              
                <div
                    ref={getRef(index)}
                    key={index}
                    className={"p1-d-c-i i" + index}
                >
                    {section}
                </div>
                
            ))*/}

                {/*PRELOAD TESTES*/}
                {/*<div ref={ref1} id='id-p1-content0' style={{position:`relative`}}><img style={{height: `300px`, width: `300px`}} src={Img1}/></div>
                <div ref={ref2} id='id-p1-content1' style={{position:`relative`}}><img style={{height: `300px`, width: `300px`}} src={Img2}/></div>
                <div ref={ref3} id='id-p1-content2' style={{position:`relative`}}><img style={{height: `300px`, width: `300px`}} src={Img3}/></div>
                <div ref={ref4} id='id-p1-content3' style={{position:`relative`}}><img style={{height: `300px`, width: `300px`}} src={Img4}/></div>
                <div ref={ref5} id='id-p1-content4' style={{position:`relative`}}><img style={{height: `300px`, width: `300px`}} src={Img5}/></div>
                <div ref={ref6} id='id-p1-content5' style={{position:`relative`}}><img style={{height: `300px`, width: `300px`}} src={Img6}/></div>*/}

                <div ref={ref1} id='id-p1-content0' className='p1-d-c-i i0'/>
                <div ref={ref2} id='id-p1-content1' className='p1-d-c-i i1'/>
                <div ref={ref3} id='id-p1-content2' className='p1-d-c-i i2'/>
                <div ref={ref4} id='id-p1-content3' className='p1-d-c-i i3'/>
                <div ref={ref5} id='id-p1-content4' className='p1-d-c-i i4'/>
                <div ref={ref6} id='id-p1-content5' className='p1-d-c-i i5'/>
                <div ref={ref7} id='id-p1-content6' className='p1-d-c-i i6'>
                  <iframe frameborder="0" allowFullScreen="" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                  title="Catálogo Vihupole" width="100%" height="100%" 
                  style={{pointerEvents: `none`}}
                  src="https://www.youtube.com/embed/URRX1R1zI44?autoplay=1&amp;mute=1&amp;controls=0&amp;loop=1&amp;playlist=URRX1R1zI44&amp;enablejsapi=1&amp;origin=http%3A%2F%2Flocalhost%3A3000&amp;widgetid=3" 
                  >
                  </iframe>
                </div>


                {/*<div ref={ref7} id='id-p1-content6' className='p1-d-c-i i6'/>
                <div ref={ref8} id='id-p1-content7' className='p1-d-c-i i7'/>
                <div ref={ref9} id='id-p1-content8' className='p1-d-c-i i8'/>
                <div ref={ref10} id='id-p1-content9' className='p1-d-c-i i9'/>
                <div ref={ref11} id='id-p1-content10' className='p1-d-c-i i10'/>
                <div ref={ref12} id='id-p1-content11' className='p1-d-c-i i11'/>*/}
                
            </div>

            
        </div>
    );
}