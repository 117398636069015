import { useRef, useState, useCallback } from 'react';
import {useNavigate} from 'react-router-dom';
import './NextProjectComponent.css';
import './NextProjectComponent.scss';
import { useEffect } from 'react';

export const NextProjectComponent = (props) => {

    /*var geoBanner = document.getElementsByClassName("geometric-banner");
    if(geoBanner != null)
    {
        geoBanner.style.backgroundImage = "url('https://camillemormal.com/static/media/d/work/samsung/home.jpg?8')";
    }*/

    const [scrollPosition, setScrollPosition] = useState(80);
    const [contadorScale, setContadorScale] = useState(.01);
    const [dragging, setDragging] = useState(false);
    const [contadorAtual, setContadorAtual] = useState(0);

    const startY = useRef(0);

    const refBlock1 = useRef(null);
    const refBlock2 = useRef(null);
    const refBlock3 = useRef(null);
    const refBlock4 = useRef(null);
    const refImageDiv = useRef(null);

    useEffect(() => {
        var geoBanner = document.getElementById("geo-banner-next-project");
        if(geoBanner != null)
        {
            geoBanner.style.backgroundImage = `url('${props.nextProjectImage}')`;

            //https://camillemormal.com/static/media/d/work/samsung/home.jpg?8
        }
    });
    //const navigate = useNavigate();

    /*useEffect(() => {
        if(contadorAtual >= 100){
            useNavigate('/' + props.nextProjectRoute);
        }
    });*/

    function CheckProximoProjeto(){
        /*if(contadorAtual >= 100){
            useNavigate('/' + props.nextProjectRoute);
        }*/

        /*if(contadorAtual >= 100){
            useCallback(() => navigate('/projeto2', {replace: true}), [navigate]);
        }*/
    }

    const navigate = useNavigate();
    const handleOnClick = useCallback(() => navigate('/' + props.nextProjectRoute, {replace: true}), [navigate]);

    function HandleClick(){

        refBlock1.current.style.transition = 'height 1s cubic-bezier(0.215, 0.61, 0.355, 1)'
        refBlock1.current.style.height = '0%';
        refBlock2.current.style.transition = 'width 1s cubic-bezier(0.215, 0.61, 0.355, 1)'
        refBlock2.current.style.width = '0%';
        refBlock3.current.style.transition = 'width 1s cubic-bezier(0.215, 0.61, 0.355, 1)'
        refBlock3.current.style.width = '0%';
        refBlock4.current.style.transition = 'height 1s cubic-bezier(0.215, 0.61, 0.355, 1)'
        refBlock4.current.style.height = '0%';

        setContadorAtual(100);

        const timeoutId = setTimeout(handleOnClick, 1200);
        return () => clearTimeout(timeoutId);
    } 

    const handleScroll = (e) => {
        if(!props.canMoveNextProject){
            return;
        }

        const deltaY = (e.deltaY > 0 ? -0.1:0.1) * 50; // Positivo - scroll down, negativo - scroll up
        setScrollPosition((prevPosition) => (prevPosition + deltaY));

        if(scrollPosition > 80){
            setScrollPosition(80);
        }

        var nextPerc = scrollPosition/4;

        if(scrollPosition === 0)
            return;

        if(nextPerc < 0){
            nextPerc = 0;
            setScrollPosition(0);
        }

        if(nextPerc > 20){
            nextPerc = 20;
            setScrollPosition(80);
        }
        
        refBlock1.current.style.height = (nextPerc) + '%';
        refBlock2.current.style.width = (nextPerc*2) + '%';
        refBlock3.current.style.width = (nextPerc*2) + '%';
        refBlock4.current.style.height = (nextPerc) + '%';

        var proximoContador = e.deltaY > 0 ? contadorAtual + 6.25:contadorAtual-6.25;
        if(proximoContador < 0){proximoContador = 0;}
        if(proximoContador > 100){proximoContador = 100;}
        setContadorAtual(proximoContador);

        if(contadorAtual >= 100){
            handleOnClick();
        }

        /*if(contadorAtual >= 100){
            useNavigate('/' + props.nextProjectRoute);
        }*/
        
        var nextScale = e.deltaY > 0 ? 1.02 + contadorScale:1.02 - contadorScale;

        if(nextScale >= 1.27){
            nextScale = 1.27;
        }

        var contador1 = contadorScale + 0.02;
        var nextScaleY = nextScale*0.9;

        if(nextPerc >= 20){
            props.handleCallBackScroll(true);
        }
        else{
            props.handleCallBackScroll(false);
        }

        if(nextScaleY < 1){
            nextScaleY = 1;
        }

        if(nextScale < 1){
            nextScale = 1;     
        }

        

        refImageDiv.current.style.transform = `scale(${nextScale},${nextScaleY})`;
        setContadorScale(contador1);
      };


    //----------TOUCH DRAG-------
    const handleTouchStart = (e) => {
        setDragging(true);
        startY.current = e.touches[0].clientY;
      };
    
      const handleTouchMove = (e) => {
        if (!dragging){ return;}
        if(!props.canMoveNextProject){
            return;
        }
  
        var mult = (e.touches[0].clientY - startY.current); //Positivo - drag down, negativo - drag up
        //console.log("Mult: " + mult);
        const deltaY = mult * 0.1; //(mult > 0 ? -0.1:0.1);// * 50;
        //console.log("DeltaY: " + deltaY);
        startY.current = e.touches[0].clientY;
        //const deltaY = (e.touches[0].deltaY > 0 ? -0.1:0.1) * 50; //Positivo - scroll down, negativo - scroll up
        setScrollPosition((prevPosition) => (prevPosition + deltaY));

        if(scrollPosition > 80){
            setScrollPosition(80);
        }

        var nextPerc = scrollPosition/4;

        if(scrollPosition === 0)
            return;

        var cont = 2;
        if(nextPerc < 0){
            nextPerc = 0;
            cont = 100;
            setScrollPosition(0);
        }

        if(nextPerc > 20){
            nextPerc = 20;
            cont = 0;
            setScrollPosition(80);
        }
        
        refBlock1.current.style.height = (nextPerc) + '%';
        refBlock2.current.style.width = (nextPerc*2) + '%';
        refBlock3.current.style.width = (nextPerc*2) + '%';
        refBlock4.current.style.height = (nextPerc) + '%';

        

        var proximoContador = deltaY > 0 ? contadorAtual - cont:contadorAtual + cont;
        //console.log(proximoContador);
        if(proximoContador < 0){proximoContador = 0;}
        if(proximoContador > 100){proximoContador = 100;}
        setContadorAtual(proximoContador);

        if(contadorAtual >= 100){
            handleOnClick();
        }
        
        var nextScale = deltaY > 0 ? 1.02 - contadorScale:1.02 + contadorScale;

        if(nextScale >= 1.27){
            nextScale = 1.27;
        }

        var contador1 = contadorScale + 0.02;
        var nextScaleY = nextScale*0.9;

        if(nextPerc >= 20){
            props.handleCallBackScroll(true);
        }
        else{
            props.handleCallBackScroll(false);
        }

        if(nextScaleY < 1){
            nextScaleY = 1;
        }

        if(nextScale < 1){
            nextScale = 1;     
        }

        refImageDiv.current.style.transform = `scale(${nextScale},${nextScaleY})`;
        setContadorScale(contador1);
      };
    
      const handleTouchEnd = () => {
        setDragging(false);
      };

    return(
        <div className='next-project-container' onWheel={handleScroll} 
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            <div id="geo-banner-next-project" ref={refImageDiv} className="geometric-banner">
                <div className="overlay">
                    <div ref={refBlock1} id="block1" className="block block-1">
                    </div>
                    <div ref={refBlock2} id="block2" className="block block-2">
                    </div>
                    <div ref={refBlock3} id="block3" className="block block-3">
                    </div>
                    <div ref={refBlock4} id="block4" className="block block-4">
                    </div>
                </div>
            </div>

            <div className='next-project-text' style={{pointerEvents: props.canMoveNextProject ? `all`:`none`}}>
                    <div className='next-project-l1'>
                        <div className='next-project-title' onClick={HandleClick}>{props.nextProject}</div>
                        <div className='next-project-counter' onClick={HandleClick}>{Math.round(contadorAtual)/*(contadorAtual * Math.abs(scrollPosition*-1))/100*/}</div>
                    </div>
                    <div className='next-project-label' onClick={HandleClick}>Next Project</div>
                </div>
        </div>
    );
}