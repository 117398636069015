import './AboutContent_AcademicExp.css';
import '../../../css/General.css';
import {useEffect, useRef, useState, useMemo} from 'react';

export const AboutContent_AcademicExp = (props) =>{

    const sufixo = props.minimapa ? "s":"r";

    const ref99 = useRef(null); //title

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const ref6 = useRef(null);
    const ref7 = useRef(null);
    const ref8 = useRef(null);
    const ref9 = useRef(null);
    const ref10 = useRef(null);
    const ref11 = useRef(null);
    const ref12 = useRef(null);
    const ref13 = useRef(null);
    const ref14 = useRef(null);
    const ref15 = useRef(null);
    const ref16 = useRef(null);
    const ref17 = useRef(null);
    const ref18 = useRef(null);
    const ref19 = useRef(null);
    const ref20 = useRef(null);
    const ref21 = useRef(null);
    const ref22 = useRef(null);
    const ref23 = useRef(null);
    const ref24 = useRef(null);
    const ref25 = useRef(null);
    const ref26 = useRef(null);
    const ref27 = useRef(null);
    const ref28 = useRef(null);

    useIsInViewport(ref99); //title

    useIsInViewport(ref1);
    useIsInViewport(ref2);
    useIsInViewport(ref3);
    useIsInViewport(ref4);
    useIsInViewport(ref5);
    useIsInViewport(ref6);
    useIsInViewport(ref7);
    useIsInViewport(ref8);
    useIsInViewport(ref9);
    useIsInViewport(ref10);
    useIsInViewport(ref11);
    useIsInViewport(ref12);
    useIsInViewport(ref13);
    useIsInViewport(ref14);
    useIsInViewport(ref15);
    useIsInViewport(ref16);
    useIsInViewport(ref17);
    useIsInViewport(ref18);
    useIsInViewport(ref19);
    useIsInViewport(ref20);
    useIsInViewport(ref21);
    useIsInViewport(ref22);
    useIsInViewport(ref23);
    useIsInViewport(ref24);
    useIsInViewport(ref25);
    useIsInViewport(ref26);
    useIsInViewport(ref27);
    useIsInViewport(ref28);

    function useIsInViewport(ref) {
        const [isIntersecting, setIsIntersecting] = useState(false);
      
        const observer = useMemo(
          () =>
            new IntersectionObserver(([entry]) =>
              setIsIntersecting(sufixo == "r" && entry.isIntersecting),
            ),
          [],
        );
    
    useEffect(() => {
            observer.observe(ref.current);
        
            return () => {
              observer.disconnect();
            };
          }, [ref, observer]);

          if(isIntersecting){
            if(ref.current.style?.animation === undefined || ref.current.style?.animation === null || ref.current.style?.animation === ""){
                ref.current.style.animation = "abt-slide-up-text .5s ease-in-out 0.1s forwards";
            }
          }
        
          return isIntersecting;
    }

    return(
        <div className='abt-a-e-container-grid'>

            <div ref={ref99} className={'abt-a-e-t-' + sufixo} style={{opacity:0}}>{window.$linguagemAtiva === "pt" ? "Experiência Académica":"Academic Experience"}</div>

            { 
                window.$linguagemAtiva === "pt" ? 

                <div className={'abt-a-e-content-' + sufixo}>

                    <div className='abt-a-e-r-1'>
                        <div ref={ref1} className={'abt-a-e-l1' + sufixo} style={{opacity:0}}><b>Google Digital Marketing & E-commerce</b></div>
                        <div ref={ref2} className={'abt-a-e-l2' + sufixo} style={{opacity:0}}>2023 - 2023</div>
                        <div ref={ref3} className={'abt-a-e-l3' + sufixo} style={{opacity:0}}><i>Coursera online courses</i></div>
                    </div>
                    <div className='abt-a-e-r-2'>
                        <div ref={ref4} className={'abt-a-e-l4' + sufixo} style={{opacity:0}}><b>Google UX design</b></div>
                        <div ref={ref5} className={'abt-a-e-l5' + sufixo} style={{opacity:0}}>2021 - 2022</div>
                        <div ref={ref6} className={'abt-a-e-l6' + sufixo} style={{opacity:0}}><i>Coursera online courses</i></div>
                    </div>
                    <div className='abt-a-e-r-3'>
                        <div ref={ref7} className={'abt-a-e-l7' + sufixo} style={{opacity:0}}><b>Certificação Business & Political Couching</b></div>
                        <div ref={ref8} className={'abt-a-e-l8' + sufixo} style={{opacity:0}}>2019 - 2019</div>
                        <div ref={ref9} className={'abt-a-e-l9' + sufixo} style={{opacity:0}}><i>Like a Pro Events no Axis Porto</i></div>
                    </div>
                    <div className='abt-a-e-r-4'>
                        <div ref={ref10} className={'abt-a-e-l10' + sufixo} style={{opacity:0}}><b>Licenciatura Design Gráfico e Multimédia</b></div>
                        <div ref={ref11} className={'abt-a-e-l11' + sufixo} style={{opacity:0}}>2012 - 2015</div>
                        <div ref={ref12} className={'abt-a-e-l12' + sufixo} style={{opacity:0}}><i>Escola Superior Artes e Design Caldas da Rainha</i></div>
                    </div>
                    <div className='abt-a-e-r-5'>
                        <div ref={ref13} className={'abt-a-e-l13' + sufixo} style={{opacity:0}}><b>Certificado Ascentis level 2</b></div>
                        <div ref={ref14} className={'abt-a-e-l14' + sufixo} style={{opacity:0}}>2012 - 2012</div>
                        <div ref={ref15} className={'abt-a-e-l15' + sufixo} style={{opacity:0}}><i>ESOL International nív. C1 - língua inglesa, S.João Madeira</i></div>
                    </div>
                    <div className='abt-a-e-r-6'>
                        <div ref={ref16} className={'abt-a-e-l16' + sufixo} style={{opacity:0}}><b>Curso Técnico Especialista em Design calçado</b></div>
                        <div ref={ref17} className={'abt-a-e-l17' + sufixo} style={{opacity:0}}>2010 - 2011</div>
                        <div ref={ref18} className={'abt-a-e-l18' + sufixo} style={{opacity:0}}><i>Centro formação de Calçado - S. João da Madeira</i></div>
                    </div>
                    <div className='abt-a-e-r-7'>
                        <div ref={ref19} className={'abt-a-e-l19' + sufixo} style={{opacity:0}}><b>Formação modular</b></div>
                        <div ref={ref20} className={'abt-a-e-l20' + sufixo} style={{opacity:0}}>2010 - 2011</div>
                        <div ref={ref21} className={'abt-a-e-l21' + sufixo} style={{opacity:0}}><b>Processos de fabrico de Calçado;</b></div>
                        <div ref={ref22} className={'abt-a-e-l22' + sufixo} style={{opacity:0}}><b>Modelação Básica de Calçado de Homem;</b></div>
                        <div ref={ref23} className={'abt-a-e-l23' + sufixo} style={{opacity:0}}><b>Modelação Básica de Calçado de Senhora;</b></div>
                        <div ref={ref24} className={'abt-a-e-l24' + sufixo} style={{opacity:0}}><i>Centro formação de Calçado - S. João da Madeira</i></div>
                    </div>
                    <div className='abt-a-e-r-8'>
                        <div ref={ref25} className={'abt-a-e-l25' + sufixo} style={{opacity:0}}><b>Artes Visuais</b></div>
                        <div ref={ref26} className={'abt-a-e-l26' + sufixo} style={{opacity:0}}>2008 - 2011</div>
                        <div ref={ref27} className={'abt-a-e-l27' + sufixo} style={{opacity:0}}><b>Curso Ciêntifico Humanístico</b></div>
                        <div ref={ref28} className={'abt-a-e-l28' + sufixo} style={{opacity:0}}><i>Escola D. Moisés Alves de Pinho</i></div>
                    </div>
                </div>
                :
                <div className={'abt-a-e-content-' + sufixo}>

                    <div className='abt-a-e-r-1'>
                        <div ref={ref1} className={'abt-a-e-l1' + sufixo} style={{opacity:0}}><b>Google Digital Marketing & E-commerce</b></div>
                        <div ref={ref2} className={'abt-a-e-l2' + sufixo} style={{opacity:0}}>2023 - 2023</div>
                        <div ref={ref3} className={'abt-a-e-l3' + sufixo} style={{opacity:0}}><i>Coursera online courses</i></div>
                    </div>
                    <div className='abt-a-e-r-2'>
                        <div ref={ref4} className={'abt-a-e-l4' + sufixo} style={{opacity:0}}><b>Google UX design</b></div>
                        <div ref={ref5} className={'abt-a-e-l5' + sufixo} style={{opacity:0}}>2021 - 2022</div>
                        <div ref={ref6} className={'abt-a-e-l6' + sufixo} style={{opacity:0}}><i>Coursera online courses</i></div>
                    </div>
                    <div className='abt-a-e-r-3'>
                        <div ref={ref7} className={'abt-a-e-l7' + sufixo} style={{opacity:0}}><b>Business and Political Couching</b></div>
                        <div ref={ref8} className={'abt-a-e-l8' + sufixo} style={{opacity:0}}>2019 - 2019</div>
                        <div ref={ref9} className={'abt-a-e-l9' + sufixo} style={{opacity:0}}><i>Like a Pro Events in Axis Porto</i></div>
                    </div>
                    <div className='abt-a-e-r-4'>
                        <div ref={ref10} className={'abt-a-e-l10' + sufixo} style={{opacity:0}}><b>Degree in Multimedia and Graphic Design</b></div>
                        <div ref={ref11} className={'abt-a-e-l11' + sufixo} style={{opacity:0}}>2012 - 2015</div>
                        <div ref={ref12} className={'abt-a-e-l12' + sufixo} style={{opacity:0}}><i>Escola Superior Artes e Design in Caldas da Rainha</i></div>
                    </div>
                    <div className='abt-a-e-r-5'>
                        <div ref={ref13} className={'abt-a-e-l13' + sufixo} style={{opacity:0}}><b>Ascentis level 2 certificate</b></div>
                        <div ref={ref14} className={'abt-a-e-l14' + sufixo} style={{opacity:0}}>2012 - 2012</div>
                        <div ref={ref15} className={'abt-a-e-l15' + sufixo} style={{opacity:0}}><i>ESOL International level C1 - English language, S.João Madeira</i></div>
                    </div>
                    <div className='abt-a-e-r-6'>
                        <div ref={ref16} className={'abt-a-e-l16' + sufixo} style={{opacity:0}}><b>Specialized Technical Course in Shoe Design</b></div>
                        <div ref={ref17} className={'abt-a-e-l17' + sufixo} style={{opacity:0}}>2010 - 2011</div>
                        <div ref={ref18} className={'abt-a-e-l18' + sufixo} style={{opacity:0}}><i>Academia de Calçado de São João da Madeira</i></div>
                    </div>
                    <div className='abt-a-e-r-7'>
                        <div ref={ref19} className={'abt-a-e-l19' + sufixo} style={{opacity:0}}><b>Modular Training</b></div>
                        <div ref={ref20} className={'abt-a-e-l20' + sufixo} style={{opacity:0}}>2010 - 2011</div>
                        <div ref={ref21} className={'abt-a-e-l21' + sufixo} style={{opacity:0}}><b>Shoe-making Process;</b></div>
                        <div ref={ref22} className={'abt-a-e-l22' + sufixo} style={{opacity:0}}><b>Man Basic Shoe Modeling;</b></div>
                        <div ref={ref23} className={'abt-a-e-l23' + sufixo} style={{opacity:0}}><b>Woman Basic Shoe Modeling;</b></div>
                        <div ref={ref24} className={'abt-a-e-l24' + sufixo} style={{opacity:0}}><i>Academia de Calçado de São João da Madeira</i></div>
                    </div>
                    <div className='abt-a-e-r-8'>
                        <div ref={ref25} className={'abt-a-e-l25' + sufixo} style={{opacity:0}}><b>Visual Arts</b></div>
                        <div ref={ref26} className={'abt-a-e-l26' + sufixo} style={{opacity:0}}>2008 - 2011</div>
                        <div ref={ref27} className={'abt-a-e-l27' + sufixo} style={{opacity:0}}><b>Scientific Humanistic Course</b></div>
                        <div ref={ref28} className={'abt-a-e-l28' + sufixo} style={{opacity:0}}><i>D. Moisés Alves de Pinho High School</i></div>
                    </div>
                </div>
            }
        </div>
    );
}