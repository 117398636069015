import React, { useEffect, useState, useRef, useMemo } from 'react';
import '../../../css/General.css';
import './Projeto8_Header.css';
import ContadorProjetos from '../../ContadorProjetos';
import {PiArrowCircleDownThin} from "react-icons/pi";

export const Projeto8_Header = (props) => {

  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);

    /****RESIZING****/
    const handleResize = () => {
      if(window.matchMedia("(max-width: 768px)").matches){
        setIsMobile(true);
      }
      else{
        setIsMobile(false);
      }
    };

    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);   

    /****-----------****/ 

  let child1SetState;
  const invokeChild1 = (setStateCallback) => {
    if (setStateCallback) {
      child1SetState = setStateCallback[1];
    } else {
      child1SetState(1);
    }
  };

    function ScrollDown(){
      props.scrollDownFromHeader();
    }

    return(
        <div className='projeto8-detail-container'>
            <div className='proj8-d-header'>
              <div className='proj8-header-contador'>
                <ContadorProjetos cbFunction={invokeChild1} limiteMaximo={window.$numeroProjetos}/>
              </div>
                <div className='p8-d-h-texto-container' style={{ transition: `transform 1s cubic-bezier(0.215, 0.61, 0.355, 1)`,  transform: `translateY(${props.scrollPosition * 0.2}vh)` }}>
                    <h1 className='p8-d-h-t-title'>
                        <span className='p8-d-h-t-span txt-anim-1'>Redes Sociais</span>
                        <span className='p8-d-h-t-span txt-anim-2'>Energia</span>
                    </h1>
                    <div className='p8-d-h-t-b1'>
                        <span className='p8-d-h-t-span txt-anim-3 p8-d-h-f'>Lead Designer</span>
                        <p/>

                        {!isMobile ? 
                          <span className='p8-d-h-t-span txt-anim-4 p8-d-h-f-d'>A energia.pt é uma empresa de energias renováveis focada na </span>
                          :
                          <span className='p8-d-h-t-span txt-anim-4 p8-d-h-f-d'>A energia.pt é uma empresa de </span>
                        }

                        {!isMobile ? 
                          <span className='p8-d-h-t-span txt-anim-5 p8-d-h-f-d'>sustentabilidade.</span>
                          :
                          <span className='p8-d-h-t-span txt-anim-5 p8-d-h-f-d'>energias renováveis focada na </span>
                        }

                        {!isMobile ? 
                          <span className='p8-d-h-t-span txt-anim-6 p8-d-h-f-d'>Neste projeto foi solicitado a criação de uma campanha de paid </span>
                          :
                          <span className='p8-d-h-t-span txt-anim-6 p8-d-h-f-d'>sustentabilidade. </span>
                        }
                        {!isMobile ? 
                          <span className='p8-d-h-t-span txt-anim-7 p8-d-h-f-d'>media para as redes sociais facebook e instagram.</span>
                          :
                          <span className='p8-d-h-t-span txt-anim-7 p8-d-h-f-d'>Neste projeto foi-me solicitado </span>
                        }
                        {!isMobile ? 
                          <span className='p8-d-h-t-span txt-anim-8 p8-d-h-f-d'>A tarefa consistiu em aumentar o reconhecimento da marca, ge-</span>
                          :
                          <span className='p8-d-h-t-span txt-anim-8 p8-d-h-f-d'>a criação de uma campanha de </span>
                          }
                        {!isMobile ? 
                          <span className='p8-d-h-t-span txt-anim-9 p8-d-h-f-d'>rar leads e impulsionar vendas, para além de montar a estratégia, </span>
                          :
                          <span className='p8-d-h-t-span txt-anim-9 p8-d-h-f-d'>paid media para as redes sociais </span>
                        }
                        {!isMobile ? 
                          <span className='p8-d-h-t-span txt-anim-10 p8-d-h-f-d'>com a identificação do público-alvo e objetivos, desenvolvi também </span>
                          :
                          <span className='p8-d-h-t-span txt-anim-10 p8-d-h-f-d'>facebook e instagram.</span>
                        }
                        {!isMobile ? 
                          <span className='p8-d-h-t-span txt-anim-11 p8-d-h-f-d'>o conteúdo criativo. Durante a campanha verifiquei o desempenho </span>
                          :
                          <span className='p8-d-h-t-span txt-anim-11 p8-d-h-f-d'>A tarefa consistiu em aumentar o </span>
                        }
                        {!isMobile ? 
                          <span className='p8-d-h-t-span txt-anim-12 p8-d-h-f-d'>dos anúncios e fiz os ajustes necessários para otimizar os resulta-</span>
                          :
                          <span className='p8-d-h-t-span txt-anim-12 p8-d-h-f-d'>reconhecimento da marca, gerar </span>
                        }
                        {!isMobile ? 
                          <span className='p8-d-h-t-span txt-anim-13 p8-d-h-f-d'>dos da campanha.</span>
                          :
                          <span className='p8-d-h-t-span txt-anim-13 p8-d-h-f-d'>leads e impulsionar vendas, para </span>
                        }
                        {!isMobile ? 
                          <span className='p8-d-h-t-span txt-anim-14 p8-d-h-f-d'></span>
                          :
                          <span className='p8-d-h-t-span txt-anim-14 p8-d-h-f-d'>além de montar a estratégia, com </span>
                        }
                        {!isMobile ? 
                          <span className='p8-d-h-t-span txt-anim-15 p8-d-h-f-d'></span>
                          :
                          <span className='p8-d-h-t-span txt-anim-15 p8-d-h-f-d'>a identificação do público-alvo e </span>
                        }

                        {!isMobile ? <span className='p8-d-h-t-span txt-anim-16 p8-d-h-f-d'></span>:<span className='p8-d-h-t-span txt-anim-16 p8-d-h-f-d'>objetivos, desenvolvi também o </span>}
                        {!isMobile ? <span className='p8-d-h-t-span txt-anim-17 p8-d-h-f-d'></span>:<span className='p8-d-h-t-span txt-anim-17 p8-d-h-f-d'>conteúdo criativo.</span>}
                        {!isMobile ? <span className='p8-d-h-t-span txt-anim-18 p8-d-h-f-d'></span>:<span className='p8-d-h-t-span txt-anim-18 p8-d-h-f-d'>Durante a campanha verifiquei o </span>}
                        {!isMobile ? <span className='p8-d-h-t-span txt-anim-19 p8-d-h-f-d'></span>:<span className='p8-d-h-t-span txt-anim-19 p8-d-h-f-d'>desempenho dos anúncios e fiz </span>}
                        {!isMobile ? <span className='p8-d-h-t-span txt-anim-20 p8-d-h-f-d'></span>:<span className='p8-d-h-t-span txt-anim-20 p8-d-h-f-d'>os ajustes necessários para otimi-</span>}
                        {!isMobile ? <span className='p8-d-h-t-span txt-anim-21 p8-d-h-f-d'></span>:<span className='p8-d-h-t-span txt-anim-21 p8-d-h-f-d'>zar os resultados da campanha.</span>}

                    </div>
                    <div className='p8-d-h-t-b2'>
                        <span className='p8-d-h-t-span txt-anim-22 p8-d-h-f'>Desenvolvido na <a href="https://www.outsidethebox.pt" style={{display:`inline`, pointerEvents:`auto`}}>Outside The Box</a>, <br></br>para o cliente <a href="https://energia.pt" style={{display:`inline`, pointerEvents:`auto`}}>Energia.pt</a></span>
                    </div>
                    <div className='p8-d-h-t-b3'>
                        <span className='p8-d-h-t-span txt-anim-23 p8-d-h-f s-down-txt' style={{opacity:`0`}} onClick={ScrollDown}>Scroll Down</span>
                        <span className='p8-d-h-t-span txt-anim-23 p8-d-h-f s-down-txt1' style={{opacity:`0`}} onClick={ScrollDown}><PiArrowCircleDownThin size={!isMobile ? 40:30}/></span>
                    </div>
                </div>
            </div>
        </div>
    );
}