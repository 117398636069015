import React, { useEffect, useState, useRef, useMemo } from 'react';
import '../../../css/General.css';
import './Projeto7_Header.css';
import ContadorProjetos from '../../ContadorProjetos';
import {PiArrowCircleDownThin} from "react-icons/pi";

export const Projeto7_Header = (props) => {

  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);

  const handleResize = () => {
    if(window.matchMedia("(max-width: 768px)").matches){
      setIsMobile(true);
    }
    else{
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /*const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const ref6 = useRef(null);
    const ref7 = useRef(null);
    const ref8 = useRef(null);
    const ref9 = useRef(null);
    const ref10 = useRef(null);
    const ref11 = useRef(null);
    const ref12 = useRef(null);
    const ref13 = useRef(null);
    const ref14 = useRef(null);
    const ref15 = useRef(null);
    const ref16 = useRef(null);
    const ref17 = useRef(null);
    const ref18 = useRef(null);
    const ref19 = useRef(null);
    const ref20 = useRef(null);
    const ref21 = useRef(null);

    useIsInViewport(ref1);
    useIsInViewport(ref2);
    useIsInViewport(ref3);
    useIsInViewport(ref4);
    useIsInViewport(ref5);
    useIsInViewport(ref6);
    useIsInViewport(ref7);
    useIsInViewport(ref8);
    useIsInViewport(ref9);
    useIsInViewport(ref10);
    useIsInViewport(ref11);
    useIsInViewport(ref12);
    useIsInViewport(ref13);
    useIsInViewport(ref14);
    useIsInViewport(ref15);
    useIsInViewport(ref16);
    useIsInViewport(ref17);
    useIsInViewport(ref18);
    useIsInViewport(ref19);
    useIsInViewport(ref20);
    useIsInViewport(ref21);

    function useIsInViewport(ref) {
      const [isIntersecting, setIsIntersecting] = useState(false);
    
      const observer = useMemo(
        () =>
          new IntersectionObserver(([entry]) =>
            setIsIntersecting(entry.isIntersecting),
          ),
        [],
      );   

  useEffect(() => {
          observer.observe(ref.current);
      
          return () => {
            observer.disconnect();
          };
        }, [ref, observer]);

        if(isIntersecting){
          if(ref.current.style?.animation === undefined || ref.current.style?.animation === null || ref.current.style?.animation === ""){
              ref.current.style.animation = "abt-slide-up-text .5s ease-in-out " + ref.current.title + "s forwards";
          }
        }
      
        return isIntersecting;
  }*/


  let child1SetState;
  const invokeChild1 = (setStateCallback) => {
    if (setStateCallback) {
      child1SetState = setStateCallback[1];
    } else {
      child1SetState(1);
    }
  };

    function ScrollDown(){
      //console.log("Scroll Down..");

      props.scrollDownFromHeader();
    }

    return(
        <div className='projeto7-detail-container'>
            <div className='proj7-d-header'>
              <div className='proj7-header-contador'>
                <ContadorProjetos cbFunction={invokeChild1} limiteMaximo={window.$numeroProjetos}/>
              </div>
                <div className='p7-d-h-texto-container' style={{ transition: `transform 1s cubic-bezier(0.215, 0.61, 0.355, 1)`,  transform: `translateY(${props.scrollPosition * 0.2}vh)` }}>
                    <h1 className='p7-d-h-t-title'>
                        <span className='p7-d-h-t-span txt-anim-1'>Painéis</span>
                        <span className='p7-d-h-t-span txt-anim-2'>Sanjotec</span>
                    </h1>
                    <div className='p7-d-h-t-b1'>
                        <span className='p7-d-h-t-span txt-anim-3 p7-d-h-f'>Lead Designer</span>
                        <p/>

                        {!isMobile ? 
                          <span className='p7-d-h-t-span txt-anim-4 p7-d-h-f-d' style={{pointerEvents:`none`}}>A <a href="https://www.sanjotec.com/" style={{display:`inline`, pointerEvents:`auto`}}>SANJOTEC</a> é um Parque de Ciência e Tecnologia focado </span>
                          :
                          <span className='p7-d-h-t-span txt-anim-4 p7-d-h-f-d'>A <a href="https://www.sanjotec.com/" style={{display:`inline`}}>SANJOTEC</a> é um Parque de Ciência </span>
                        }

                        {!isMobile ? 
                          <span className='p7-d-h-t-span txt-anim-5 p7-d-h-f-d'>no apoio ao empreendedorismo e à aceleração de projetos </span>
                          :
                          <span className='p7-d-h-t-span txt-anim-5 p7-d-h-f-d'>e Tecnologia focado no apoio ao empre-</span>
                        }

                        {!isMobile ? 
                          <span className='p7-d-h-t-span txt-anim-6 p7-d-h-f-d'>de base tecnológica e criativa.</span>
                          :
                          <span className='p7-d-h-t-span txt-anim-6 p7-d-h-f-d'>endedorismo e à aceleração de projetos </span>
                        }
                        {!isMobile ? 
                          <span className='p7-d-h-t-span txt-anim-7 p7-d-h-f-d'>Neste projeto foi solicitado a renovação visual de uns painéis </span>
                          :
                          <span className='p7-d-h-t-span txt-anim-7 p7-d-h-f-d'>de base tecnológica e criativa.</span>
                          }
                        {!isMobile ? 
                          <span className='p7-d-h-t-span txt-anim-8 p7-d-h-f-d'>informativos no interior do edifício, os quais deveriam exprimir </span>
                          :
                          <span className='p7-d-h-t-span txt-anim-8 p7-d-h-f-d'>Neste projeto foi solicitado a renovação </span>
                        }
                        {!isMobile ? 
                          <span className='p7-d-h-t-span txt-anim-9 p7-d-h-f-d'>momentos e valores da Sanjotec como instituição. </span>
                          :
                          <span className='p7-d-h-t-span txt-anim-9 p7-d-h-f-d'>visual de uns painéis informativos no in-</span>
                        }
                        {!isMobile ? 
                          <span className='p7-d-h-t-span txt-anim-10 p7-d-h-f-d'>Tendo por base e inspiração os hexágonos presentes no seu </span>
                          :
                          <span className='p7-d-h-t-span txt-anim-10 p7-d-h-f-d'>terior do edifício, os quais deveriam ex-</span>
                        }
                        {!isMobile ? 
                          <span className='p7-d-h-t-span txt-anim-11 p7-d-h-f-d'>logotipo, foi concebida uma representação visual mais moder- </span>
                          :
                          <span className='p7-d-h-t-span txt-anim-11 p7-d-h-f-d'>primir momentos e valores da Sanjotec </span>
                        }
                        {!isMobile ? 
                          <span className='p7-d-h-t-span txt-anim-12 p7-d-h-f-d'>na, simples, transmitindo a sua essência de forma harmoniosa.</span>
                          :
                          <span className='p7-d-h-t-span txt-anim-12 p7-d-h-f-d'>como instituição.</span>
                        }
                        {!isMobile ? 
                          <span className='p7-d-h-t-span txt-anim-13 p7-d-h-f-d'></span>
                          :
                          <span className='p7-d-h-t-span txt-anim-13 p7-d-h-f-d'>Tendo por base e inspiração os hexágo-</span>
                        }
                        {!isMobile ? 
                          <span className='p7-d-h-t-span txt-anim-14 p7-d-h-f-d'></span>
                          :
                          <span className='p7-d-h-t-span txt-anim-14 p7-d-h-f-d'>nos presentes no seu logotipo, foi con-</span>
                        }
                        {!isMobile ? 
                          <span className='p7-d-h-t-span txt-anim-15 p7-d-h-f-d'></span>
                          :
                          <span className='p7-d-h-t-span txt-anim-15 p7-d-h-f-d'>cebida uma representação visual mais </span>
                        }
                        {!isMobile ? 
                          <span className='p7-d-h-t-span txt-anim-16 p7-d-h-f-d'></span>
                          :
                          <span className='p7-d-h-t-span txt-anim-16 p7-d-h-f-d'>moderna e simples, transmitindo a sua </span>
                        }
                        {!isMobile ? 
                          <span className='p7-d-h-t-span txt-anim-17 p7-d-h-f-d'></span>
                          :
                          <span className='p7-d-h-t-span txt-anim-17 p7-d-h-f-d'>essência de forma harmoniosa.</span>
                        }


                    </div>
                    <div className='p7-d-h-t-b2'>
                        <span className='p7-d-h-t-span txt-anim-18 p7-d-h-f'>Desenvolvido na <a href="https://www.outsidethebox.pt/" style={{display:`inline`, pointerEvents:`auto`}}>Outside The Box</a></span>
                        <span className='p7-d-h-t-span txt-anim-19 p7-d-h-f'>Para a Sanjotec</span>
                    </div>
                    <div className='p7-d-h-t-b3'>
                        <span className='p7-d-h-t-span txt-anim-20 p7-d-h-f s-down-txt' style={{opacity:`0`}} onClick={ScrollDown}>Scroll Down</span>
                        <span className='p7-d-h-t-span txt-anim-20 p7-d-h-f s-down-txt1' style={{opacity:`0`}} onClick={ScrollDown}><PiArrowCircleDownThin size={!isMobile ? 40:30}/></span>
                    </div>
                </div>
            </div>
        </div>
    );
}