import React, { useEffect, useState, useRef, useMemo } from 'react';
import '../../../css/General.css';
import './Projeto2_Header.css';
import ContadorProjetos from '../../ContadorProjetos';
import {PiArrowCircleDownThin} from "react-icons/pi";

export const Projeto2_Header_EN = (props) => {

  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);

    /****RESIZING****/
    const handleResize = () => {
      if(window.matchMedia("(max-width: 768px)").matches){
        setIsMobile(true);
      }
      else{
        setIsMobile(false);
      }
    };

    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);   

    /****-----------****/

  let child1SetState;
  const invokeChild1 = (setStateCallback) => {
    if (setStateCallback) {
      child1SetState = setStateCallback[1];
    } else {
      child1SetState(2);
    }
  };

    function ScrollDown(){
      props.scrollDownFromHeader();
    }

    return(
        <div className='projeto2-detail-container'>
            <div className='proj2-d-header'>
              <div className='proj2-header-contador'>
                <ContadorProjetos cbFunction={invokeChild1} limiteMaximo={window.$numeroProjetos}/>
              </div>
                <div className='p2-d-h-texto-container' style={{ transition: `transform 1s cubic-bezier(0.215, 0.61, 0.355, 1)`,  transform: `translateY(${props.scrollPosition * 0.2}vh)` }}>
                    <h1 className='p2-d-h-t-title'>
                        <span className='p2-d-h-t-span txt-anim-1'>Santorini</span>
                        <span className='p2-d-h-t-span txt-anim-2'>Shoes</span>
                        <span className='p2-d-h-t-span txt-anim-3'>Photography</span>
                    </h1>
                    <div className='p2-d-h-t-b1'>
                        <span className='p2-d-h-t-span txt-anim-4 p2-d-h-f'>Lead Designer</span>
                        <p/>
                        {!isMobile ? 
                          <span className='p2-d-h-t-span txt-anim-5 p2-d-h-f-d'><a href="https://santorinishoes.pt/" style={{display:`inline`, pointerEvents:`auto`}}>Santorini shoes</a> is a Portuguese brand with 100% national design </span>
                          :
                          <span className='p2-d-h-t-span txt-anim-5 p2-d-h-f-d'><a href="https://santorinishoes.pt/" style={{display:`inline`, pointerEvents:`auto`}}>Santorini shoes</a> is a Portuguese brand </span>
                        }
                        {!isMobile ? 
                          <span className='p2-d-h-t-span txt-anim-6 p2-d-h-f-d'>and production.</span>
                          :
                          <span className='p2-d-h-t-span txt-anim-6 p2-d-h-f-d'>with 100% national design and production.</span>
                        }
                        {!isMobile ? 
                          <span className='p2-d-h-t-span txt-anim-7 p2-d-h-f-d'>This work consisted of a photo session of 9 shoe models from the </span>
                          :
                          <span className='p2-d-h-t-span txt-anim-7 p2-d-h-f-d'>This work consisted of a photo session </span>
                        }
                        {!isMobile ? 
                          <span className='p2-d-h-t-span txt-anim-8 p2-d-h-f-d'>autumn/winter collection, with the aim of creating simple images, </span>
                          :
                          <span className='p2-d-h-t-span txt-anim-8 p2-d-h-f-d'>of 9 shoe models from the autumn/winter </span>
                        }
                        {!isMobile ? 
                          <span className='p2-d-h-t-span txt-anim-9 p2-d-h-f-d'>focused on the product for the website/online store.</span>
                          :
                          <span className='p2-d-h-t-span txt-anim-9 p2-d-h-f-d'>collection, with the aim of creating simple </span>
                        }
                        {!isMobile ? <span className='p2-d-h-t-span txt-anim-10 p2-d-h-f-d'></span>:<span className='p2-d-h-t-span txt-anim-10 p2-d-h-f-d'>images, focused on the product for the </span>}
                        {!isMobile ? <span className='p2-d-h-t-span txt-anim-11 p2-d-h-f-d'></span>:<span className='p2-d-h-t-span txt-anim-11 p2-d-h-f-d'>website/online store.</span>}

                        <p/>

                        {!isMobile ? 
                          <span className='p2-d-h-t-span txt-anim-12 p2-d-h-f-d'>Each model is represented by 4 photographs from different angles, </span>
                          :
                          <span className='p2-d-h-t-span txt-anim-12 p2-d-h-f-d'>Each model is represented by 4 photo-</span>
                        }
                        {!isMobile ? 
                          <span className='p2-d-h-t-span txt-anim-13 p2-d-h-f-d'>and edited to maintain authentic color and details.</span>
                          :
                          <span className='p2-d-h-t-span txt-anim-13 p2-d-h-f-d'>graphs from different angles, and edited </span>
                        }
                        {!isMobile ? <span className='p2-d-h-t-span txt-anim-14 p2-d-h-f-d'></span>:<span className='p2-d-h-t-span txt-anim-14 p2-d-h-f-d'>to maintain authentic color and details.</span>}
                        
                    </div>
                    <div className='p2-d-h-t-b2'>
                        {!isMobile ? 
                          <span className='p2-d-h-t-span txt-anim-15 p2-d-h-f'>E-Commerce photography for Santorini shoes.</span>
                          :
                          <span className='p2-d-h-t-span txt-anim-15 p2-d-h-f'>E-Commerce photography for Santorini </span>
                        }
                        {!isMobile ? <span className='p2-d-h-t-span txt-anim-16 p2-d-h-f-d'></span>:<span className='p2-d-h-t-span-b2 txt-anim-16 p2-d-h-f'>Shoes.</span>}
                        
                    </div>
                    <div className='p2-d-h-t-b3'>
                        <span className='p2-d-h-t-span txt-anim-17 p2-d-h-f s-down-txt' style={{opacity:`0`}} onClick={ScrollDown}>Scroll Down</span>
                        <span className='p2-d-h-t-span txt-anim-17 p2-d-h-f s-down-txt1' style={{opacity:`0`}} onClick={ScrollDown}><PiArrowCircleDownThin size={!isMobile ? 40:30}/></span>
                    </div>
                </div>
            </div>
        </div>
    );
}