import './AboutMinimap.css';
import '../../../css/General.css';
import React, { useEffect, useRef, useState, useCallback } from "react";

export const AboutMinimap = (props) =>{

    const boxRef = useRef(null);

    function handleCallBack(e){
        props.handleCallBack(e, boxRef);
    }

    const sections = props.sections;

    return(
        <div className="about-minimap-container" style={{ transform: `translateY(${props.scrollPosition * 0.2}vh)` }} onClick={handleCallBack}>
            
            {sections.map((section, index) => (
                <div
                    key={index}
                    className={"section-minimap" + (index + 1)}
                >
                    {section}
                </div>
            ))}

            <div ref={boxRef} className='box-minimap' 
            style={{
                        transform: `translateY(${-props.scrollPosition * 0.25}vh)`,
                    }}
            >
            </div>
        </div>
    );
}