import './AboutContent_Interests.css';
import '../../../css/General.css';
import {useEffect, useRef, useState, useMemo} from 'react';

export const AboutContent_Interests = (props) =>{

  const language = localStorage.getItem('language') || 'pt';

    const sufixo = props.minimapa ? "s":"r";

    const ref99 = useRef(null); //title

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const ref6 = useRef(null);
    const ref7 = useRef(null);
    const ref8 = useRef(null);
    const ref9 = useRef(null);
    const ref10 = useRef(null);

    useIsInViewport(ref99); //title

    useIsInViewport(ref1);
    useIsInViewport(ref2);
    useIsInViewport(ref3);
    useIsInViewport(ref4);
    useIsInViewport(ref5);
    useIsInViewport(ref6);
    useIsInViewport(ref7);
    useIsInViewport(ref8);
    useIsInViewport(ref9);
    useIsInViewport(ref10);

    function useIsInViewport(ref) {
        const [isIntersecting, setIsIntersecting] = useState(false);
      
        const observer = useMemo(
          () =>
            new IntersectionObserver(([entry]) =>
              setIsIntersecting(sufixo == "r" && entry.isIntersecting),
            ),
          [],
        );
    
    useEffect(() => {
            observer.observe(ref.current);
        
            return () => {
              observer.disconnect();
            };
          }, [ref, observer]);

          if(isIntersecting){
            if(ref.current.style?.animation === undefined || ref.current.style?.animation === null || ref.current.style?.animation === ""){
                ref.current.style.animation = "abt-slide-up-text .5s ease-in-out 0.1s forwards";
            }
          }
        
          return isIntersecting;
    }

    return(
        <div className='abt-i-container-grid'>

          <div ref={ref99} className={'abt-i-t-' + sufixo} style={{opacity:0}}>{language === "pt" ? "Interesses":"Interests"}</div>

          {
            language === "pt" ? 

            <div className={'abt-i-content-' + sufixo}>
                <div className='abt-i-r-1'>
                    <div ref={ref1} className={'abt-i-l1' + sufixo} style={{opacity:0}}><b>Eu sou</b></div>
                    <hr ref={ref2} id={'hr-60-1' + sufixo} className='hr-60' style={{opacity:0}}/>
                    <div ref={ref3} className={'abt-i-l2' + sufixo} style={{opacity:0}}>Dedicada</div>
                    <div ref={ref4} className={'abt-i-l3' + sufixo} style={{opacity:0}}>Adaptável</div>
                    <div ref={ref5} className={'abt-i-l4' + sufixo} style={{opacity:0}}>Team player</div>
                </div>
                <div className='abt-i-r-2'>
                    <div ref={ref6} className={'abt-i-l5' + sufixo} style={{opacity:0}}><b>Eu adoro</b></div>
                    <hr  ref={ref7} id={'hr-60-2' + sufixo} className='hr-60' style={{opacity:0}}/>
                    <div ref={ref8} className={'abt-i-l6' + sufixo} style={{opacity:0}}>Branding / Packaging</div>
                    <div ref={ref9} className={'abt-i-l7' + sufixo} style={{opacity:0}}>Fotografia</div>
                    <div ref={ref10} className={'abt-i-l8' + sufixo} style={{opacity:0}}>Impressão / Serigrafia/ Handmade</div>
                </div>
            </div>

            :

            <div className={'abt-i-content-' + sufixo}>
                <div className='abt-i-r-1'>
                    <div ref={ref1} className={'abt-i-l1' + sufixo} style={{opacity:0}}><b>I am</b></div>
                    <hr ref={ref2} id={'hr-60-1' + sufixo} className='hr-60' style={{opacity:0}}/>
                    <div ref={ref3} className={'abt-i-l2' + sufixo} style={{opacity:0}}>Team worker</div>
                    <div ref={ref4} className={'abt-i-l3' + sufixo} style={{opacity:0}}>Committed</div>
                    <div ref={ref5} className={'abt-i-l4' + sufixo} style={{opacity:0}}>Adaptable & Communicative</div>
                </div>
                <div className='abt-i-r-2'>
                    <div ref={ref6} className={'abt-i-l5' + sufixo} style={{opacity:0}}><b>I love</b></div>
                    <hr  ref={ref7} id={'hr-60-2' + sufixo} className='hr-60' style={{opacity:0}}/>
                    <div ref={ref8} className={'abt-i-l6' + sufixo} style={{opacity:0}}>Branding / Packaging</div>
                    <div ref={ref9} className={'abt-i-l7' + sufixo} style={{opacity:0}}>Photography </div>
                    <div ref={ref10} className={'abt-i-l8' + sufixo} style={{opacity:0}}>Screenprint / Handmade</div>
                </div>
            </div>

          }
        </div>
    );
}