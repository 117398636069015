import React, { useEffect, useState, useRef, useMemo } from 'react';
import '../../../css/General.css';
import './Projeto1_Header.css';
import ContadorProjetos from '../../ContadorProjetos';
import {PiArrowCircleDownThin} from "react-icons/pi";

export const Projeto1_Header = (props) => {

  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);

  /****RESIZING****/
  const handleResize = () => {
    if(window.matchMedia("(max-width: 768px)").matches){
      setIsMobile(true);
    }
    else{
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);   

  /****-----------****/

  let child1SetState;
  const invokeChild1 = (setStateCallback) => {
    if (setStateCallback) {
      child1SetState = setStateCallback[1];
    } else {
      child1SetState(1);
    }
  };


    function ScrollDown(){
      props.scrollDownFromHeader();
    }

    return(
        <div className='projeto1-detail-container'>
            <div className='proj1-d-header'>
              <div className='proj1-header-contador'>
                <ContadorProjetos cbFunction={invokeChild1} limiteMaximo={window.$numeroProjetos}/>
              </div>
                <div className='p1-d-h-texto-container' style={{ transition: `transform 1s cubic-bezier(0.215, 0.61, 0.355, 1)`,  transform: `translateY(${props.scrollPosition * 0.2}vh)` }}>
                    <h1 className='p1-d-h-t-title'>
                        <span className='p1-d-h-t-span txt-anim-1'>Catálogo </span>
                        <span className='p1-d-h-t-span txt-anim-2'>Vihupole</span>
                    </h1>
                    <div className='p1-d-h-t-b1'>
                        <span className='p1-d-h-t-span txt-anim-3 p1-d-h-f'>Lead Designer</span>
                        <p/>
                        {!isMobile ? 
                          <span className='p1-d-h-t-span txt-anim-4 p1-d-h-f-d'>O vihupole é um produto inovador, versátil e sustentável criado pela</span>
                          :
                          <span className='p1-d-h-t-span txt-anim-4 p1-d-h-f-d'>O vihupole é um produto inovador, </span>
                        }
                        {!isMobile ? 
                          <span className='p1-d-h-t-span txt-anim-5 p1-d-h-f-d'>Tibesystems.</span>
                          :
                          <span className='p1-d-h-t-span txt-anim-5 p1-d-h-f-d'>versátil e sustentável criado pela</span>
                        }
                        {!isMobile ? 
                          <span className='p1-d-h-t-span txt-anim-6 p1-d-h-f-d'>Este é uma solução de proteção e segurança, que se adapta até aos</span>
                          :
                          <span className='p1-d-h-t-span txt-anim-6 p1-d-h-f-d'>Tibesystems.</span>
                        }
                        {!isMobile ? 
                          <span className='p1-d-h-t-span txt-anim-7 p1-d-h-f-d'>locais mais remotos.</span>
                          :
                          <span className='p1-d-h-t-span txt-anim-7 p1-d-h-f-d'>Este é uma solução de proteção e</span>
                        }
                        {!isMobile ? 
                          <span className='p1-d-h-t-span txt-anim-8 p1-d-h-f-d'>O objectivo deste catálogo passa por demonstrar as diversas aplica-</span>
                          :
                          <span className='p1-d-h-t-span txt-anim-8 p1-d-h-f-d'>segurança, que se adapta até aos </span>
                        }
                        {!isMobile ? 
                          <span className='p1-d-h-t-span txt-anim-9 p1-d-h-f-d'>ções em vários espaços, através de montagens fotográficas, assim</span>
                          :
                          <span className='p1-d-h-t-span txt-anim-9 p1-d-h-f-d'>locais mais remotos.</span>
                        }
                        {!isMobile ? 
                          <span className='p1-d-h-t-span txt-anim-10 p1-d-h-f-d'>como apresentar as suas características e mais valias de aquisição</span>
                          :
                          <span className='p1-d-h-t-span txt-anim-10 p1-d-h-f-d'>O objectivo deste catálogo passa</span>
                        }
                        {!isMobile ? 
                          <span className='p1-d-h-t-span txt-anim-11 p1-d-h-f-d'>pelo layout e descritivos.</span>
                          :
                          <span className='p1-d-h-t-span txt-anim-11 p1-d-h-f-d'>por demonstrar as diversas aplica-</span>
                        }
                        {!isMobile ? <span className='p1-d-h-t-span txt-anim-12 p1-d-h-f-d'></span>:<span className='p1-d-h-t-span txt-anim-12 p1-d-h-f-d'>ções em vários espaços, através </span>}
                        {!isMobile ? <span className='p1-d-h-t-span txt-anim-13 p1-d-h-f-d'></span>:<span className='p1-d-h-t-span txt-anim-13 p1-d-h-f-d'>de montagens fotográficas, assim </span>}
                        {!isMobile ? <span className='p1-d-h-t-span txt-anim-14 p1-d-h-f-d'></span>:<span className='p1-d-h-t-span txt-anim-14 p1-d-h-f-d'>como apresentar as suas caracte-</span>}
                        {!isMobile ? <span className='p1-d-h-t-span txt-anim-15 p1-d-h-f-d'></span>:<span className='p1-d-h-t-span txt-anim-15 p1-d-h-f-d'>rísticas e mais valias de aquisição</span>}
                        {!isMobile ? <span className='p1-d-h-t-span txt-anim-16 p1-d-h-f-d'></span>:<span className='p1-d-h-t-span txt-anim-16 p1-d-h-f-d'>pelo layout e descritivos.</span>}

                    </div>
                    <div className='p1-d-h-t-b2'>
                        <span className='p1-d-h-t-span txt-anim-17 p1-d-h-f'>Desenvolvido na <a href="https://www.outsidethebox.pt" style={{display:`inline`, pointerEvents:`auto`}}>Outside The Box</a></span>
                        <span className='p1-d-h-t-span txt-anim-18 p1-d-h-f'>Para o cliente Tibesystems</span>
                    </div>
                    <div className='p1-d-h-t-b3'>
                        <span className='p1-d-h-t-span txt-anim-19 p1-d-h-f s-down-txt' style={{opacity:`0`}} onClick={ScrollDown}>Scroll Down</span>
                        <span className='p1-d-h-t-span txt-anim-19 p1-d-h-f s-down-txt99' style={{opacity:`0`}} onClick={ScrollDown}><PiArrowCircleDownThin size={!isMobile ? 40:30}/></span>
                    </div>
                </div>
            </div>
        </div>
    );
}