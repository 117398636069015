import React, { useEffect, useRef, useState, useCallback } from "react";
import './AboutSP.css';
import { NavLink } from 'react-router-dom';
import '../../Generic/MouseSpotlight.css';
import '../../../css/General.css';
import { AboutMinimap } from "../Minimap/AboutMinimap";
import { AboutContent_Header } from "./AboutContent_Header";
import { AboutContent_WorkExp } from "./AboutContent_WorkExp";
import { AboutContent_AcademicExp } from "./AboutContent_AcademicExp";
import { AboutContent_Skills } from "./AboutContent_Skills";
import { AboutContent_Interests } from "./AboutContent_Interests";
import { AboutContent_Socials } from "./AboutContent_Socials";

import { motion, AnimatePresence } from "framer-motion";

const animation_props = {
  animate: {
    y: '0dvh',
    clipPath: 'inset(0% 0% 0% 0%)',
  },
  initial: {
    y: '25dvh',
    clipPath: 'inset(100% 0% 0% 0%)',
  },
  exit: {
    y: '25dvh',
    clipPath: 'inset(100% 0% 0% 0%)',
  },
  transition: {
    duration: 0.5,
  }
}

const sections = [
                <AboutContent_Header minimapa={false}/>,
                <AboutContent_WorkExp minimapa={false}/>,
                <AboutContent_AcademicExp minimapa={false}/>,
                <AboutContent_Skills minimapa={false}/>,
                <AboutContent_Interests minimapa={false}/>,
                <AboutContent_Socials minimapa={false}/>
              ];

const sections_minimap = [
                <AboutContent_Header minimapa={true}/>,
                <AboutContent_WorkExp minimapa={true}/>,
                <AboutContent_AcademicExp minimapa={true}/>,
                <AboutContent_Skills minimapa={true}/>,
                <AboutContent_Interests minimapa={true}/>,
                <AboutContent_Socials minimapa={true}/>
              ];

export const AboutSP = () => {

    window.$executarIntro = false;
    window.$slideAnimation = 0;
    window.$primeiraEntradaSlider = false;

    const [scrollIndex, setScrollIndex] = useState(0);

    const language = localStorage.getItem('language') || 'pt';

    //DRAGGING
    const [scrollPosition, setScrollPosition] = useState(0);
    const [dragging, setDragging] = useState(false);
    const startY = useRef(0);

    var x = window.matchMedia("(max-width: 740px)");

    var constanteTamanhoParaScroll = 125;
    if(x.matches){
      constanteTamanhoParaScroll = 35;
    }
    const maxHeightVH = -12 - (constanteTamanhoParaScroll*(sections.length - 1));


    //-----------MOUSE DRAG
    const handleDragStart = (e) => {
      setDragging(true);
      startY.current = e.clientY;
    };

    const handleDragMove = (e) => {
      if (!dragging) return;
      const deltaY = (e.clientY - startY.current) * (e.deltaY > 0 ? 0.2:-0.2);
      startY.current = e.clientY;
      setScrollPosition((prevPosition) => (prevPosition - deltaY) > 12 ? 12:((prevPosition - deltaY)  < maxHeightVH ? maxHeightVH:(prevPosition - deltaY)));
    };

    const handleDragEnd = () => {
      setDragging(false);
    };

    //----------TOUCH DRAG

    const handleTouchStart = (e) => {
      setDragging(true);
      startY.current = e.touches[0].clientY;
      //console.log("start.." + startY.current);
      
    };
  
    const handleTouchMove = (e) => {
      //console.log("moving.." + startY.current);
      if (!dragging) return;

      /*if (startY === null) {
        return;
      }*/
  
      //console.log("moving.." + startY.current);
      //const deltaY = e.touches[0].clientY - startY.current;
      const deltaY = (e.touches[0].clientY - startY.current) * (e.touches[0].deltaY > 0 ? 0.2:-0.2);
      startY.current = e.touches[0].clientY;
      /*console.log("sp: " + scrollPosition);
      console.log("deltaY: " + deltaY);*/
      //console.log("scrollPosition.." + (scrollPosition - deltaY));
      setScrollPosition((prevPosition) => (prevPosition - deltaY) > 12 ? 12:((prevPosition - deltaY)  < maxHeightVH ? maxHeightVH:(prevPosition - deltaY)));
  
      

      
    };
  
    const handleTouchEnd = () => {
      //startY.current = null;
      setDragging(false);
    };

    //----------

    const handleScroll = (e) => {
      const deltaY = (e.deltaY > 0 ? -0.1:0.1) * 50; // Positivo - scroll down, negativo - scroll up
      setScrollPosition((prevPosition) => (prevPosition + deltaY) > 12 ? 12:((prevPosition + deltaY) < maxHeightVH ? maxHeightVH:(prevPosition + deltaY)));
    };


  useEffect(() => {

    //mouse effect
    const blob = document.getElementById("blob");

    window.onpointermove = event => { 
    const { clientX, clientY } = event;
    
    blob.animate({
        left: `${clientX}px`,
        top: `${clientY}px`
    }, { duration: 3000, fill: "forwards" });
    }

    return () => {


    };
  }, []);


const setVerticalSlideAnimation = () => {
    window.$slideAnimation = 0;
  };


function callbackMinimapa(e, minimapBox){

  const rect = minimapBox.current.getBoundingClientRect();
      const clickY = e.clientY;
      const yDistance = Math.abs(clickY - rect.top);
  
  setScrollPosition((prevPosition) => (prevPosition+e.clientY < 0) ?  
    prevPosition+(yDistance) //up
    :
    prevPosition-(e.screenY*0.2) //down
  );
}

    return(
      
      <div className="about-page-container">

        <AnimatePresence>
            {<motion.div {...animation_props} className="portal-abt-page" key="portal-abt-page!">
              <div className="about-slider-container"
              onMouseDown={handleDragStart}
              onMouseMove={handleDragMove}
              onMouseUp={handleDragEnd}
              onMouseLeave={handleDragEnd}
              onWheel={handleScroll}
              /*onTouchStart={handleDragStart}
              onTouchMove={handleDragMove}
              onTouchEnd={handleDragEnd}*/
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
            >

              <div id="blob" className="spotlight"></div>
              <div id="blur" className="spotlight"></div>
              <div className='back-btn'>
                    <NavLink to="/" className="slideUp-backbtn" onClick={setVerticalSlideAnimation}>
                      <div className="back-nav-pointer">{language === "pt" ? "Anterior":"Back"}</div>
                      </NavLink>
                      </div>



                <div className="small-menu-slider">
                  <AboutMinimap sections={sections_minimap} scrollIndex={scrollIndex} scrollPosition={scrollPosition} handleCallBack={callbackMinimapa}/>
                </div>


                <div className="about-main-container"
                    style={{ transform: `translateY(${scrollPosition}vh)` } }
                >
                          
                {sections.map((section, index) => (
                  
                    <div
                        key={index}
                        className={"section" + (index + 1)}
                    >
                        {section}
                    </div>
                    
                ))}

                </div>
            </div>
          </motion.div>}
        </AnimatePresence>

      </div>
    );
}