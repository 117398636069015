import { useState, useEffect, useCallback, useRef } from 'react';
import '../css/Main.css';
import { SmallMenuSlider } from '../components/SmallMenuSlider';
import { SmallMenuSliderV1 } from './Sliders/SmallMenuSliderV1';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { MainMenuSlider } from './MainMenuSlider';
import './Menu01.scss';
import ContadorProjetos from './ContadorProjetos';
import { RxPlus } from 'react-icons/rx';
import { CircleFlag } from 'react-circle-flags'

const slides = [
    {url:'https://camillemormal.com/static/media/d/work/gucci/home.jpg?8', title: "A Casinha da Sarinha", url_path: "/casinha"},
    {url:'https://camillemormal.com/static/media/d/work/samsung/home.jpg?8', title: "Teste", url_path: "/teste"},
    {url:'https://camillemormal.com/static/media/d/work/rituals/home.jpg?8', title: "Teste 1", url_path: "/teste1"},
    {url:'https://camillemormal.com/static/media/d/work/moco/home.jpg?8', title: "Teste 2", url_path: "/este2"},
    {url:'https://camillemormal.com/static/media/d/work/sennheiser/home.jpg?8', title: "Teste 3", url_path: "/teste3"}, 
    {url:'https://camillemormal.com/static/media/d/work/swarovski/home.jpg?8', title: "Teste 4", url_path: "/teste4"},
    {url:'https://camillemormal.com/static/media/d/work/activia/home.jpg?8', title: "Teste 5", url_path: "/teste5"},
    {url:'https://camillemormal.com/static/media/d/work/saeco/home.jpg?8', title: "Teste 6", url_path: "/teste6"},
  ]

var currentSlide = 1;
var currentRotation = 0;
export const Main = ({intro, loading, callbackLinguagem}) => {

    const [isMainMenuVisible, setIsMainMenuVisible ] = useState(false);
    const [goToMainMenu, setGoToMainMenu] = useState(false);
    const childRef = useRef(null);
    window.$executarIntro = false;
    currentSlide = window.$projetoAtivo;
    window.$tipoSlide=0;
    const numeroProjetos = 10;
    window.$numeroProjetos = 10;

    const [language, setLanguage] = useState(localStorage.getItem('language') || 'pt');
    const [linguaPTativa, setLinguaPTativa] = useState(language === "pt");

    const [smallMenuSliderDragging, setSmallMenuSliderDragging] = useState(false);

    

    //console.log("Slide atual: " + currentSlide);

    const navigate = useNavigate();  
    const goToProjetos = useCallback(() => navigate('/projetos', {replace: true}), [navigate]);

    const goToDetalhe = useCallback((nomeProjeto) => navigate('/' + nomeProjeto, {replace: true}), [navigate]);
    const goToAbout = useCallback(() => navigate('/about', {replace: true}), [navigate]);

    

    useEffect(() => {
        const timeout = setTimeout(() => {
            var slide = document.getElementById("s-" + window.$projetoAtivo.toString());
            var title = document.getElementById("s-t-" + window.$projetoAtivo.toString());

            var firstTitle = document.getElementById("s-t-1");

            if(firstTitle != null && firstTitle.classList != null){
                firstTitle.classList?.remove("s-u-t-fx");
                firstTitle.classList?.add("s-u-t");
            }

            if(title != null && title.classList != null){
                title.classList?.remove("s-u-t");
                title.classList?.add("s-u-t-fx");
            }

            if(slide != null){
                if(window.$projetoAtivo > 1){
                    currentRotation = (window.$projetoAtivo-1)*180;
                }
                var btnNext = document.getElementById("next-proj-icon");
                var btnPrev = document.getElementById("prev-proj-icon");
                btnNext.style.transition = `transform 0ms var(--o6)`;
                btnNext.style.transform = `rotate(${currentRotation}deg)`;
                btnPrev.style.transition = `transform 0ms var(--o6)`;
                btnPrev.style.transform = `rotate(${currentRotation}deg)`;
                slide.scrollIntoView({behavior: "instant", inline:"center"});
            }
        }, 1)
     
        return () => clearTimeout(timeout)
    }, [])

    const newspaperSpinning = [
        { animation: "slide-effect 1s ease-in-out 0s forwards" },
      ];

    const newspaperTiming = {
        duration: 1000,
        iterations: 1,
      };

    function EfeitoTimer(slide){
        let count = 1;
        const timer = setInterval(function() {
        count--;
        if (count === 0) {
            clearInterval(timer);
            slide.style.animation = `slide-effect 1s ease-in-out 0s forwards`;

            //slide.animate(newspaperSpinning, newspaperTiming);
        }
        }, 1000);

        count = 3;
        const timerClear = setInterval(function() {
            count--;
            if (count === 0) {
                clearInterval(timerClear);
                slide.style = ``;
            }
            }, 1000)
    }


    
    function handleSlidingNext(nextSlideIndex = -1){

        //setProjetoAtual((prevProj) => prevProj + 1 > numeroProjetos ? numeroProjetos:prevProj + 1);

        if(currentSlide >= numeroProjetos){
            currentSlide = numeroProjetos - 1;
            //setCurrentSlide(lengthWorkArray - 1);
        }

        var nextSlide = document.getElementById("s-" + (nextSlideIndex > -1 ? nextSlideIndex:currentSlide+1).toString());
        var nextTitle = document.getElementById("s-t-" + (nextSlideIndex > -1 ? nextSlideIndex:currentSlide+1).toString());
        var currentTitle = document.getElementById("s-t-" + (currentSlide).toString());
        var btnNext = document.getElementById("next-proj-icon");
        var btnPrev = document.getElementById("prev-proj-icon");

        currentTitle.classList.remove("s-u-t-fx");
        currentTitle.classList.add("s-u-t");

        nextTitle.classList.remove("s-u-t");
        nextTitle.classList.add("s-u-t-fx");
        
        nextSlide.scrollIntoView({behavior: "smooth", inline:"start"});

        if(currentSlide <= (numeroProjetos - 1)){
            //currentRotation = currentRotation + 180; 

            //console.log("Current rotation: " + currentRotation);
            if((currentRotation + 180) > ((numeroProjetos*180) - 180))
            {
                //currentRotation = numeroProjetos*180;
                //é o último slide então tenho de fazer rotation 180  visualmente mas dar rotation real ao elemento de numProj*180 graus
            }
            else{
                currentRotation = currentRotation + 180; 

                /*console.log("NEXT CURRENT ROTATION: " + currentRotation);

                //btnNext.style.transition = `transform 1s ease`;
                btnNext.style.transform = `rotate(${currentRotation}deg)`;
                btnNext.style.transition = `transform 1200ms var(--o6)`;
                //btnPrev.style.transition = `transform 1s ease`;
                btnPrev.style.transform = `rotate(${currentRotation}deg)`;
                btnPrev.style.transition = `transform 1200ms var(--o6)`;*/


                /*TESTE
                btnNext.style.transition = `transform 0ms var(--o6)`;
                btnNext.style.transform = `rotate(${-180}deg)`;
                btnNext.style.transition = `transform 1200ms var(--o6)`;
                btnNext.style.transform = `rotate(${180}deg)`;

                btnPrev.style.transition = `transform 0ms var(--o6)`;
                btnPrev.style.transform = `rotate(${-180}deg)`;
                btnPrev.style.transition = `transform 1200ms var(--o6)`;
                btnPrev.style.transform = `rotate(${180}deg)`;*/
            }

            //btnNext.style.transition = `transform 1s ease`;
            btnNext.style.transform = `rotate(${currentRotation}deg)`;
            btnNext.style.transition = `transform 1200ms var(--o6)`;
            //btnPrev.style.transition = `transform 1s ease`;
            btnPrev.style.transform = `rotate(${currentRotation}deg)`;
            btnPrev.style.transition = `transform 1200ms var(--o6)`;

            /*
            btnNext.style.transition = `transform 1s ease`;
            btnNext.style.transform = `rotate(${currentRotation}deg)`;
            btnPrev.style.transition = `transform 1s ease`;
            btnPrev.style.transform = `rotate(${currentRotation}deg)`;*/
        }


        if(nextSlideIndex > -1){
            currentSlide = nextSlideIndex;
            //setCurrentSlide(nextSlideIndex);
        }
        else
        {
            currentSlide = currentSlide + 1;
            //setCurrentSlide((previousSlide) => previousSlide + 1);
        }

        

        invokeChild1();
        //callChildMethod();

        //EfeitoTimer(nextSlide);
    }

    function handleSlidingPrev(prevSlideIndex = -1){

        //setProjetoAtual((prevProj) => prevProj - 1 < 1 ? 1:prevProj < 1);

        if(currentSlide < 2){
            currentSlide = 2;
            //setCurrentSlide(2);
        }

        
        var prevSlide = document.getElementById("s-" + (prevSlideIndex > -1 ? prevSlideIndex:currentSlide-1).toString());
        var prevTitle = document.getElementById("s-t-" + (prevSlideIndex > -1 ? prevSlideIndex:currentSlide-1).toString());
        var currentTitle = document.getElementById("s-t-" + (currentSlide).toString());
        var btnNext = document.getElementById("next-proj-icon");
        var btnPrev = document.getElementById("prev-proj-icon");

        //currentTitle.classList.remove("s-u-t-fx-ini");
        currentTitle.classList.remove("s-u-t-fx");
        currentTitle.classList.add("s-u-t");

        prevTitle.classList.remove("s-u-t");
        prevTitle.classList.add("s-u-t-fx");

        prevSlide.scrollIntoView({behavior: "smooth", inline:"end"});
        /*prevSlide.style.animation = `nothing 2s ease-in-out 0s forwards`;
        prevSlide.style.animation = `slide-effect 2s ease-in-out 0s forwards`;*/

        /*btnNext.style.transition = `transform 1s ease`;
        btnNext.style.transform = `rotate(-180deg)`;
        btnPrev.style.transition = `transform 1s ease`;
        btnPrev.style.transform = `rotate(-180deg)`;*/

        if(prevSlideIndex > -1){
            currentSlide = prevSlideIndex;
            //setCurrentSlide(prevSlideIndex);
        }
        else{
            currentSlide = currentSlide - 1;
            //setCurrentSlide((previousSlide) => previousSlide - 1);
        }

        if(currentSlide >= 1){

            if((currentRotation - 180) < 0)
            {
                currentRotation = 0;
            }
            else{
                currentRotation = currentRotation - 180; 
            }
            
            /*
            TESTEbtnNext.style.transition = `transform 0ms var(--o6)`;
            btnNext.style.transform = `rotate(${180}deg)`;
            btnNext.style.transition = `transform 1200ms var(--o6)`;
            btnNext.style.transform = `rotate(${0}deg)`;

            btnPrev.style.transition = `transform 0ms var(--o6)`;
            btnPrev.style.transform = `rotate(${180}deg)`;
            btnPrev.style.transition = `transform 1200ms var(--o6)`;
            btnPrev.style.transform = `rotate(${0}deg)`;*/

            btnNext.style.transition = `transform 1200ms var(--o6)`;
            btnNext.style.transform = `rotate(${currentRotation}deg)`;
            
            btnPrev.style.transition = `transform 1200ms var(--o6)`;
            btnPrev.style.transform = `rotate(${currentRotation}deg)`;
        }

        invokeChild1();
    }

    function CallBack(currentImageIndex){
        //console.log("CB: " + currentImageIndex);
        var indexCallback = currentImageIndex+1;
        currentRotation = currentImageIndex*180;
        var btnNext = document.getElementById("next-proj-icon");
        var btnPrev = document.getElementById("prev-proj-icon");

        if(indexCallback > currentSlide){

            currentRotation = currentRotation - 180;
            btnNext.style.transition = `transform 0ms var(--o6)`;
            btnNext.style.transform = `rotate(${currentRotation}deg)`;

            btnPrev.style.transition = `transform 0ms var(--o6)`;
            btnPrev.style.transform = `rotate(${currentRotation}deg)`;

            handleSlidingNext(indexCallback);
        }
        else if(indexCallback < currentSlide){

            //var currentRotation = indexCallback*180;
            currentRotation = currentRotation + 180;
            
            btnNext.style.transition = `transform 0ms var(--o6)`;
            btnNext.style.transform = `rotate(${currentRotation}deg)`;

            btnPrev.style.transition = `transform 0ms var(--o6)`;
            btnPrev.style.transform = `rotate(${currentRotation}deg)`;

            handleSlidingPrev(indexCallback);
        }
      }

      function ScrollDown(e){
        if(e.deltaY > 0){
            setGoToMainMenu(true);
            //goToProjetos();
        }
      }

      function CallBackMainMenu(){
        goToProjetos();
      }

      function Caralho(){
        //setGoToMainMenu(true);

        //{animation: loading ? `none`:`slideUpTitle .5s ease-in-out .5s forwards`}
        return 5;
      }

      const callChildMethod = () => {
        if (childRef.current) {
          childRef.current.someMethod(); // Replace with the actual method name
        }
      };





      let child1SetState;
      //let procProjetoAtivo = false;
  const invokeChild1 = (setStateCallback) => {
    if (setStateCallback) {
      /* When Child1 is mounted, store 
        its reference in a variable
      */
      child1SetState = setStateCallback[1];
    } else {
      /* When user clicks on a button in 
        child 2, the argument won't be 
        provided meaning we have ref to 
        child1SetState and is ready to be
        invoked and it will re-render child1 
        component.
      */
     //if(!procProjetoAtivo){
        //child1SetState(window.$projetoAtivo);
        //procProjetoAtivo = true;
     //}
     //else{
        child1SetState(currentSlide);
     //}
    }

    window.$projetoAtivo = currentSlide;
  };


  // Function to handle arrow key presses
  const handleArrowKeyPress = (event) => {
    if (event.key === "ArrowLeft" && currentSlide > 1) {
        handleSlidingPrev(currentSlide-1);
    } else if (event.key === "ArrowRight" && currentSlide < numeroProjetos) {
        handleSlidingNext(currentSlide+1);
    }
    else if(event.key == "ArrowDown"){
        //nav para o detalhe do projeto
        goToDetalhe(GetRouteCorrespondenteAoSlide(currentSlide));
    }
    else if(event.key == "ArrowUp"){
        //nav para o about
        goToAbout();
    }
  };

  function ProcSlideOnClick(e){
  }

  // Add an event listener to the window on component mount
  useEffect(() => {
    window.addEventListener('keydown', handleArrowKeyPress);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleArrowKeyPress);
    };
  });//, [focusedIndex]); // Only re-add the event listener when focusedIndex changes




  //----------TOUCH DRAG
  const [dragging, setDragging] = useState(false);
  const [canGoToMainMenu, setCanGoToMainMenu] = useState(false);
  const [startY, setStartY] = useState(0);

  const handleTouchStart = (e) => {
    if(!smallMenuSliderDragging){
        setDragging(true);
    }
    setStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    if (!dragging) return;
    
    const currentY = e.touches[0].clientY;
    const deltaY = currentY - startY;

    if(deltaY > 0){
        setCanGoToMainMenu(true);
    }
    if(deltaY < 0){
        goToDetalhe(GetRouteCorrespondenteAoSlide(currentSlide));
    }

    setStartY(currentY);
  };

  const handleTouchEnd = () => {
    if(canGoToMainMenu){
        setGoToMainMenu(true);
    }

    setDragging(false);
  };

  function GetRouteCorrespondenteAoSlide(slide){
    switch(slide){
        case 1:
            return "vihupole";
        case 2:
            return "santorini";
        case 3:
            return "conservatorio";
        case 4:
            return "azorestek";
        case 5:
            return "andrik";
        case 6:
            return "transtech";
        case 7:
            return "sanjotec";
        case 8:
            return "energia";
        case 9:
            return "aap";
        case 10:
            return "joana_beauty";
    }

    return "projetos";
  }


  function Traduzir(){
    //se tenho a PT ativa entao é porque vou mudar para a inglesa e vice-versa
    window.$linguagemAtiva = linguaPTativa ? "gb":"pt";
    callbackLinguagem(linguaPTativa ? "gb":"pt");
    setLinguaPTativa(!linguaPTativa);
    console.log("A mudar para " + (linguaPTativa ? "gb":"pt"));
    setLanguage(linguaPTativa ? "gb":"pt");
    
    //TODO traduzir
  }

  useEffect(() => {
    // Update the localStorage whenever the language changes
    localStorage.setItem('language', language);
  }, [language]);

  function SmallMenuDraggingStatus(isDragging){
    setSmallMenuSliderDragging(isDragging);
  }

    return(
        <div className={intro ? 'slider-container-intro':'slider-container'} 
        onWheel={ScrollDown} 
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onClick={ProcSlideOnClick}
        style={{opacity: loading ? 0:1}}>

            <div id="s-1" className='slide'>
            {!loading && 
                    <NavLink id="s-t-1" to='/vihupole' 
                    className={loading ? 's-u-t':'s-u-t-fx'} 
                    >
                        Vihupole
                    </NavLink>
            }
            </div>

            <div id="s-2" className='slide'>
                <NavLink id="s-t-2" to='/santorini' className='s-u-t'>Santorini Shoes</NavLink>
            </div> 

            <div id="s-3" className='slide'><NavLink id="s-t-3" to='/conservatorio' className='s-u-t'>{linguaPTativa ? "Conservatório de Música":"Conservatory TSMF"}</NavLink></div> 
            <div id="s-4" className='slide'><NavLink id="s-t-4" to='/azorestek' className='s-u-t'>Azores Tek</NavLink></div> 
            <div id="s-5" className='slide'><NavLink id="s-t-5" to='/andrik' className='s-u-t'>{linguaPTativa ? "Logótipo Andrik":"Andrik Logo"}</NavLink></div> 
            <div id="s-6" className='slide'><NavLink id="s-t-6" to='/transtech' className='s-u-t'>Transtech</NavLink></div> 
            <div id="s-7" className='slide'><NavLink id="s-t-7" to='/sanjotec' className='s-u-t'>{linguaPTativa ? "Painéis Sanjotec":"Sanjotec Panels"}</NavLink></div> 
            <div id="s-8" className='slide'><NavLink id="s-t-8" to='/energia' className='s-u-t'>{linguaPTativa ? "Redes Sociais - Energia":"Social Media - Energy"}</NavLink></div> 
            <div id="s-9" className='slide'><NavLink id="s-t-9" to='/aap' className='s-u-t'>{linguaPTativa ? "Automóveis Abílio Pinto":"Abílio Pinto Cars"}</NavLink></div> 
            <div id="s-10" className='slide'><NavLink id="s-t-10" to='/joana_beauty' className='s-u-t'>{linguaPTativa ? "Joana. beauty":"Joana. beauty"}</NavLink></div> 

            {/*REMOVIDA TEMPORARIAMENTE*/}
            {/*<div className='btn-gallery'><NavLink to='/galeria' >Galeria</NavLink></div>*/}
            
            <div id='next-proj-icon' className='main-btn-next-project' onClick={handleSlidingNext}><RxPlus size={25}/></div>
            <div id='prev-proj-icon' className='main-btn-prev-project' onClick={handleSlidingPrev}><RxPlus size={25}/></div>
            
            {!loading && <div className='main-contador'><ContadorProjetos cbFunction={invokeChild1} limiteMaximo={numeroProjetos}/></div>}
            {!loading && <SmallMenuSliderV1 imagens={slides} handleCallback={CallBack} 
            showMainMenu={goToMainMenu} callbackMainMenu={CallBackMainMenu} draggingStatusCallBack={SmallMenuDraggingStatus}/> }

            <div className='traducoes-container'>
                <div className={ linguaPTativa ? "linguagem-on" : "linguagem"} onClick={Traduzir}>
                    PT{/*<CircleFlag countryCode="pt" height="20" />*/}
                </div>
                <div className={ !linguaPTativa ? "linguagem-on" : "linguagem"} onClick={Traduzir}>
                    EN {/*<CircleFlag countryCode="gb" height="20" />*/}
                </div>
            </div>
        </div>
    );
}