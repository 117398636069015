import React, { useEffect, useState, useRef, useMemo } from 'react';
import '../../../css/General.css';
import './Projeto10_Header.css';
import ContadorProjetos from '../../ContadorProjetos';
import {PiArrowCircleDownThin} from "react-icons/pi";

export const Projeto10_Header = (props) => {

  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);

    /****RESIZING****/
    const handleResize = () => {
      if(window.matchMedia("(max-width: 768px)").matches){
        setIsMobile(true);
      }
      else{
        setIsMobile(false);
      }
    };

    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);   

    /****-----------****/ 

  let child1SetState;
  const invokeChild1 = (setStateCallback) => {
    if (setStateCallback) {
      child1SetState = setStateCallback[1];
    } else {
      child1SetState(1);
    }
  };

    function ScrollDown(){
      props.scrollDownFromHeader();
    }

    return(
        <div className='projeto10-detail-container'>
            <div className='proj10-d-header'>
              <div className='proj10-header-contador'>
                <ContadorProjetos cbFunction={invokeChild1} limiteMaximo={window.$numeroProjetos}/>
              </div>
                <div className='p10-d-h-texto-container' style={{ transition: `transform 1s cubic-bezier(0.215, 0.61, 0.355, 1)`,  transform: `translateY(${props.scrollPosition * 0.2}vh)` }}>
                    <h1 className='p10-d-h-t-title'>
                        <span className='p10-d-h-t-span txt-anim-1'>Joana. beauty</span>
                        <span className='p10-d-h-t-span txt-anim-2'>Cosméticos</span>
                    </h1>
                    <div className='p10-d-h-t-b1'>
                        <span className='p10-d-h-t-span txt-anim-3 p10-d-h-f'>Lead Designer</span>
                        <p/>

                        {!isMobile ? 
                          <span className='p10-d-h-t-span txt-anim-4 p10-d-h-f-d'>Joana. beauty é uma marca de produtos cosméticos para crianças </span>
                          :
                          <span className='p10-d-h-t-span txt-anim-4 p10-d-h-f-d'>Joana. beauty é uma marca de pro-</span>
                        }

                        {!isMobile ? 
                          <span className='p10-d-h-t-span txt-anim-5 p10-d-h-f-d'>e pré-adolescentes, produzida em Portugal.</span>
                          :
                          <span className='p10-d-h-t-span txt-anim-5 p10-d-h-f-d'>dutos cosméticos para crianças e pré-</span>
                        }

                        {!isMobile ? 
                          <span className='p10-d-h-t-span txt-anim-6 p10-d-h-f-d'>É uma marca criada pela pequena Joaninha de 10 anos, que quis </span>
                          :
                          <span className='p10-d-h-t-span txt-anim-6 p10-d-h-f-d'>adolescentes, produzida em Portugal.</span>
                        }
                        {!isMobile ? 
                          <span className='p10-d-h-t-span txt-anim-7 p10-d-h-f-d'>criar a sua própria linha de produtos para ela e para os amigos.</span>
                          :
                          <span className='p10-d-h-t-span txt-anim-7 p10-d-h-f-d'>É uma marca criada pela pequena </span>
                          }
                        {!isMobile ? 
                          <span className='p10-d-h-t-span txt-anim-8 p10-d-h-f-d'>Neste projeto desenvolvi o logotipo, a rotulagem, caixas dos </span>
                          :
                          <span className='p10-d-h-t-span txt-anim-8 p10-d-h-f-d'>Joaninha de 10 anos, que quis criar </span>
                          }
                        {!isMobile ? 
                          <span className='p10-d-h-t-span txt-anim-9 p10-d-h-f-d'>produtos, fotos e ainda a criação de conteúdos para o instagram.</span>
                          :
                          <span className='p10-d-h-t-span txt-anim-9 p10-d-h-f-d'>a sua própria linha de produtos para </span>
                          }

                        {!isMobile ? 
                          <span className='p10-d-h-t-span txt-anim-10 p10-d-h-f-d'></span>
                          :
                          <span className='p10-d-h-t-span txt-anim-10 p10-d-h-f-d'>ela e para os amigos.</span>
                        }
                        {!isMobile ? <span className='p10-d-h-t-span txt-anim-11 p10-d-h-f-d'></span>:<span className='p10-d-h-t-span txt-anim-11 p10-d-h-f-d'>Neste projeto desenvolvi o logotipo, </span>}
                        {!isMobile ? <span className='p10-d-h-t-span txt-anim-12 p10-d-h-f-d'></span>:<span className='p10-d-h-t-span txt-anim-12 p10-d-h-f-d'>a rotulagem, caixas dos produtos, fo-</span>}
                        {!isMobile ? <span className='p10-d-h-t-span txt-anim-13 p10-d-h-f-d'></span>:<span className='p10-d-h-t-span txt-anim-13 p10-d-h-f-d'>tos e ainda a criação de conteúdos </span>}
                        {!isMobile ? <span className='p10-d-h-t-span txt-anim-14 p10-d-h-f-d'></span>:<span className='p10-d-h-t-span txt-anim-14 p10-d-h-f-d'>para o instagram.</span>}

                    </div>
                    <div className='p10-d-h-t-b2'>
                        <span className='p10-d-h-t-span txt-anim-15 p10-d-h-f'>Desenvolvido na empresa <a href="https://alphanna.com/" style={{display:`inline`, pointerEvents:`auto`}}>Alphanna</a></span>
                    </div>
                    <div className='p10-d-h-t-b3'>
                        <span className='p10-d-h-t-span txt-anim-16 p10-d-h-f s-down-txt' style={{opacity:`0`}} onClick={ScrollDown}>Scroll Down</span>
                        <span className='p10-d-h-t-span txt-anim-16 p10-d-h-f s-down-txt1' style={{opacity:`0`}} onClick={ScrollDown}><PiArrowCircleDownThin size={!isMobile ? 40:30}/></span>
                    </div>
                </div>
            </div>
        </div>
    );
}