import React, { useEffect, useState, useRef, useMemo } from 'react';
import '../../../css/General.css';
import './Projeto6_Header.css';
import ContadorProjetos from '../../ContadorProjetos';
import {PiArrowCircleDownThin} from "react-icons/pi";

export const Projeto6_Header = (props) => {

  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);

    /****RESIZING****/
    const handleResize = () => {
      if(window.matchMedia("(max-width: 768px)").matches){
        setIsMobile(true);
      }
      else{
        setIsMobile(false);
      }
    };

    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);   

    /****-----------****/ 

  let child1SetState;
  const invokeChild1 = (setStateCallback) => {
    if (setStateCallback) {
      child1SetState = setStateCallback[1];
    } else {
      child1SetState(1);
    }
  };

    function ScrollDown(){
      props.scrollDownFromHeader();
    }

    return(
        <div className='projeto6-detail-container'>
            <div className='proj6-d-header'>
              <div className='proj6-header-contador'>
                <ContadorProjetos cbFunction={invokeChild1} limiteMaximo={window.$numeroProjetos}/>
              </div>
                <div className='p6-d-h-texto-container' style={{ transition: `transform 1s cubic-bezier(0.215, 0.61, 0.355, 1)`,  transform: `translateY(${props.scrollPosition * 0.2}vh)` }}>
                    <h1 className='p6-d-h-t-title'>
                        <span className='p6-d-h-t-span txt-anim-1'>Transtech</span>
                    </h1>
                    <div className='p6-d-h-t-b1'>
                        <span className='p6-d-h-t-span txt-anim-2 p6-d-h-f'>Lead Designer</span>
                        <p/>
                        {!isMobile ? 
                          <span className='p6-d-h-t-span txt-anim-3 p6-d-h-f-d'>O projeto <a href='https://www.transtech.pt' className='tstaev' style={{display: `inline`}}>TRANSTECH</a> tem como objetivo dinamizar na Região</span>
                          :
                          <span className='p6-d-h-t-span txt-anim-3 p6-d-h-f-d'>O projeto <a href='https://www.transtech.pt' style={{display: `inline`}}>TRANSTECH</a> tem como obje-</span>
                        }
                        {!isMobile ? 
                          <span className='p6-d-h-t-span txt-anim-4 p6-d-h-f-d'>Norte, Centro e Alentejo, um conjunto de iniciativas que visam a</span>
                          :
                          <span className='p6-d-h-t-span txt-anim-4 p6-d-h-f-d'>tivo dinamizar na Região Norte, Centro</span>
                        }
                        {!isMobile ? 
                          <span className='p6-d-h-t-span txt-anim-5 p6-d-h-f-d'>inovação, transformação digital e a integração em cadeias de va-</span>
                          :
                          <span className='p6-d-h-t-span txt-anim-5 p6-d-h-f-d'>e Alentejo, um conjunto de iniciativas que</span>
                        }
                        {!isMobile ? 
                          <span className='p6-d-h-t-span txt-anim-6 p6-d-h-f-d'>lor globais das empresas portuguesas. </span>
                          :
                          <span className='p6-d-h-t-span txt-anim-6 p6-d-h-f-d'>visam a inovação, transformação digital e</span>
                        }
                        {!isMobile ? 
                          <span className='p6-d-h-t-span txt-anim-7 p6-d-h-f-d'>Este projecto consistiu na criação de toda a identidade gráfica do</span>
                          :
                          <span className='p6-d-h-t-span txt-anim-7 p6-d-h-f-d'>a integração em cadeias de valor globais</span>
                        }
                        {!isMobile ? 
                          <span className='p6-d-h-t-span txt-anim-8 p6-d-h-f-d'>projecto como criação do logotipo, design do website, programas,</span>
                          :
                          <span className='p6-d-h-t-span txt-anim-8 p6-d-h-f-d'>das empresas portuguesas.</span>
                        }
                        {!isMobile ? 
                          <span className='p6-d-h-t-span txt-anim-9 p6-d-h-f-d'>credenciais, rollups, photowalls, brindes e gestão de redes sociais.</span>
                          :
                          <span className='p6-d-h-t-span txt-anim-9 p6-d-h-f-d'>Este projecto consistiu na criação de toda</span>
                        }
                        {!isMobile ? <span className='p6-d-h-t-span txt-anim-10 p6-d-h-f-d'></span>:<span className='p6-d-h-t-span txt-anim-10 p6-d-h-f-d'>a identidade gráfica do projecto como cri-</span>}
                        {!isMobile ? <span className='p6-d-h-t-span txt-anim-11 p6-d-h-f-d'></span>:<span className='p6-d-h-t-span txt-anim-11 p6-d-h-f-d'>ação do logotipo, design do website, pro-</span>}
                        {!isMobile ? <span className='p6-d-h-t-span txt-anim-12 p6-d-h-f-d'></span>:<span className='p6-d-h-t-span txt-anim-12 p6-d-h-f-d'>gramas, credenciais, rollups, photowalls,</span>}
                        {!isMobile ? <span className='p6-d-h-t-span txt-anim-13 p6-d-h-f-d'></span>:<span className='p6-d-h-t-span txt-anim-13 p6-d-h-f-d'>brindes e gestão de redes sociais.</span>}
                        <p/>
                        {!isMobile ? 
                          <span className='p6-d-h-t-span txt-anim-14 p6-d-h-f-d'>Este projeto decorreu ao longo de mais de 2 anos, com a criação</span>
                          :
                          <span className='p6-d-h-t-span txt-anim-14 p6-d-h-f-d'>Este projeto decorreu ao longo de mais </span>
                        }
                        {!isMobile ? 
                          <span className='p6-d-h-t-span txt-anim-15 p6-d-h-f-d'>de dezenas de Workshops e Roteiros, disponibilizando assim inf-</span>
                          :
                          <span className='p6-d-h-t-span txt-anim-15 p6-d-h-f-d'>de 2 anos, com a criaçãode dezenas de </span>
                        }
                        {!isMobile ? 
                          <span className='p6-d-h-t-span txt-anim-16 p6-d-h-f-d'>ormação de forma gratuita a todos os interessados.</span>
                          :
                          <span className='p6-d-h-t-span txt-anim-16 p6-d-h-f-d'>Workshops e Roteiros, disponibilizando</span>
                        }
                        {!isMobile ? <span className='p6-d-h-t-span txt-anim-17 p6-d-h-f-d'></span>:<span className='p6-d-h-t-span txt-anim-17 p6-d-h-f-d'>assim informação de forma gratuita a to-</span>}
                        {!isMobile ? <span className='p6-d-h-t-span txt-anim-18 p6-d-h-f-d'></span>:<span className='p6-d-h-t-span txt-anim-18 p6-d-h-f-d'>dos os interessados.</span>}

                    </div>
                    <div className='p6-d-h-t-b2'>
                        {!isMobile ? 
                          <span className='p6-d-h-t-span txt-anim-19 p6-d-h-f'>Desenvolvido na empresa Outside The Box, em colaboração com</span>
                          :
                          <span className='p6-d-h-t-span txt-anim-19 p6-d-h-f'>Desenvolvido na empresa Outside The Box,</span>
                        }
                        {!isMobile ? 
                          <span className='p6-d-h-t-span txt-anim-20 p6-d-h-f'>as empresas PortusPark e Tecparques, responsáveis pelo projeto.</span>
                          :
                          <span className='p6-d-h-t-span txt-anim-20 p6-d-h-f'>em colaboração com as empresas PortusPark</span>
                        }
                        {!isMobile ? 
                          <span className='p6-d-h-t-span txt-anim-21 p6-d-h-f'>Este projeto contou também com o contributo da empresa Like a pro.</span>
                          :
                          <span className='p6-d-h-t-span txt-anim-21 p6-d-h-f'> e Tecparques, responsáveis pelo projeto.</span>
                        }
                        {!isMobile ? <span className='p6-d-h-t-span txt-anim-22 p6-d-h-f-d'></span>:<span className='p6-d-h-t-span txt-anim-22 p6-d-h-f'>Este projeto contou também com o contributo</span>}
                        {!isMobile ? <span className='p6-d-h-t-span txt-anim-23 p6-d-h-f-d'></span>:<span className='p6-d-h-t-span txt-anim-23 p6-d-h-f'>da empresa Like a pro.</span>}

                    </div>
                    <div className='p6-d-h-t-b3'>
                        <span className='p6-d-h-t-span txt-anim-24 p6-d-h-f s-down-txt' style={{opacity:`0`}} onClick={ScrollDown}>Scroll Down</span>
                        <span className='p6-d-h-t-span txt-anim-24 p6-d-h-f s-down-txt1' style={{opacity:`0`}} onClick={ScrollDown}><PiArrowCircleDownThin size={!isMobile ? 40:30}/></span>
                    </div>
                </div>
            </div>
        </div>
    );
}