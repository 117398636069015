import '../css/NavBar.css';
import { NavLink } from 'react-router-dom';
import { GiQueenCrown } from "react-icons/gi";


export const NavBar = ({callback, linguagem}) => {

    const setVerticalSlideAnimation = () => {
        window.$slideAnimation = 0;
        callback();
    }

    return(
            <nav id="navbar" className="navbar">
                {/*<div className='nav-logo'>
                    <GiQueenCrown size={25}/>
                </div>*/}
                <div onClick={setVerticalSlideAnimation}>
                    <NavLink to="/" end
                    className={({ isActive }) =>
                    isActive ? "nav-a on" : "nav-a"}>
                        {linguagem==="pt" ? "Projetos":"Projects"}
                    </NavLink>
                </div>
                <div onClick={setVerticalSlideAnimation}>
                    <NavLink to="/about"
                    className={({ isActive }) =>
                    isActive ? "nav-a on" : "nav-a"}>
                        {linguagem==="pt" ? "Sobre":"About"}
                    </NavLink>
                </div>
            </nav>
    );
}