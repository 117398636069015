import React, { useEffect, useState, useRef, useMemo } from 'react';
import '../../../css/General.css';
import './Projeto3_Header.css';
import ContadorProjetos from '../../ContadorProjetos';
import {PiArrowCircleDownThin} from "react-icons/pi";

export const Projeto3_Header_EN = (props) => {

  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);

    /****RESIZING****/
    const handleResize = () => {
      if(window.matchMedia("(max-width: 768px)").matches){
        setIsMobile(true);
      }
      else{
        setIsMobile(false);
      }
    };

    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);   

    /****-----------****/

  let child1SetState;
  const invokeChild1 = (setStateCallback) => {
    if (setStateCallback) {
      child1SetState = setStateCallback[1];
    } else {
      child1SetState(3);
    }
  };

    function ScrollDown(){
      props.scrollDownFromHeader();
    }

    return(
        <div className='projeto3-detail-container'>
            <div className='proj3-d-header'>
              <div className='proj3-header-contador'>
                <ContadorProjetos cbFunction={invokeChild1} limiteMaximo={window.$numeroProjetos}/>
              </div>
                <div className='p3-d-h-texto-container' style={{ transition: `transform 1s cubic-bezier(0.215, 0.61, 0.355, 1)`,  transform: `translateY(${props.scrollPosition * 0.2}vh)` }}>

                    <h1 className='p3-d-h-t-title'>
                        <span className='p3-d-h-t-span txt-anim-1'>Music</span>
                        <span className='p3-d-h-t-span txt-anim-2'>Conservatory</span>
                        <span className='p3-d-h-t-span txt-anim-3'>Course {!isMobile ? "TSMF":""}</span>
                        {!isMobile ? <span className='p3-d-h-t-span txt-anim-4 p3-d-h-f-d'></span>:<span className='p3-d-h-t-span txt-anim-4'>Terras SMF</span>}
                    </h1>
                    <div className='p3-d-h-t-b1'>
                        <span className='p3-d-h-t-span txt-anim-5 p3-d-h-f'>Lead Designer</span>
                        <p/>
                        {!isMobile ? 
                          <span className='p3-d-h-t-span txt-anim-6 p3-d-h-f-d'>The development of the image for the Basic Music Course at the </span>
                          :
                          <span className='p3-d-h-t-span txt-anim-6 p3-d-h-f-d'>The development of the image for </span>
                        }
                        {!isMobile ? 
                          <span className='p3-d-h-t-span txt-anim-7 p3-d-h-f-d'>Terras de Santa Maria da Feira Conservatory, arises from the need </span>
                          :
                          <span className='p3-d-h-t-span txt-anim-7 p3-d-h-f-d'>the Basic Music Course at the Terras</span>
                        }
                        {!isMobile ? 
                          <span className='p3-d-h-t-span txt-anim-8 p3-d-h-f-d'>to promote and inspire young children to play music.</span>
                          :
                          <span className='p3-d-h-t-span txt-anim-8 p3-d-h-f-d'>de Santa Maria da Feira  Conservatory, </span>
                        }
                        {!isMobile ? 
                          <span className='p3-d-h-t-span txt-anim-9 p3-d-h-f-d'></span>
                          :
                          <span className='p3-d-h-t-span txt-anim-9 p3-d-h-f-d'>arises from the need to promote and </span>
                        }
                        {!isMobile ? 
                          <span className='p3-d-h-t-span txt-anim-10 p3-d-h-f-d'>An image able to capture the attention of the children was created, </span>
                          :
                          <span className='p3-d-h-t-span txt-anim-10 p3-d-h-f-d'>inspire young children to play music.</span>
                        }
                        {!isMobile ? 
                          <span className='p3-d-h-t-span txt-anim-11 p3-d-h-f-d'>with colors and simple-to-understand elements that captivate but </span>
                          :
                          <span className='p3-d-h-t-span txt-anim-11 p3-d-h-f-d'>An image able to capture the attention </span>
                        }
                        {!isMobile ? 
                          <span className='p3-d-h-t-span txt-anim-12 p3-d-h-f-d'>also, more abstract elements that illustrate the abstract part of the </span>
                          :
                          <span className='p3-d-h-t-span txt-anim-12 p3-d-h-f-d'>of the children was created, with colors </span>
                        }
                        {!isMobile ? 
                          <span className='p3-d-h-t-span txt-anim-13 p3-d-h-f-d'>music like the sound.</span>
                          :
                          <span className='p3-d-h-t-span txt-anim-13 p3-d-h-f-d'>and simple-to-understand elements </span>
                        }
                        {!isMobile ? 
                          <span className='p3-d-h-t-span txt-anim-14 p3-d-h-f-d'>In this project, several materials were developed, such as: regis-</span>
                          :
                          <span className='p3-d-h-t-span txt-anim-14 p3-d-h-f-d'>that captivate but also, more abstract </span>
                        }
                        {!isMobile ? 
                          <span className='p3-d-h-t-span txt-anim-15 p3-d-h-f-d'>tration forms, flyers for the different schools, roll ups and pop ups.</span>
                          :
                          <span className='p3-d-h-t-span txt-anim-15 p3-d-h-f-d'>elements that illustrate the abstract </span>
                        }
                        
                        {!isMobile ? <span className='p3-d-h-t-span txt-anim-16 p3-d-h-f-d'></span>:<span className='p3-d-h-t-span txt-anim-16 p3-d-h-f-d'>part of the music like the sound.</span>}
                        {!isMobile ? <span className='p3-d-h-t-span txt-anim-17 p3-d-h-f-d'></span>:<span className='p3-d-h-t-span txt-anim-17 p3-d-h-f-d'>In this project, several materials were </span>}
                        {!isMobile ? <span className='p3-d-h-t-span txt-anim-18 p3-d-h-f-d'></span>:<span className='p3-d-h-t-span txt-anim-18 p3-d-h-f-d'>developed, such as: registration forms, </span>}
                        {!isMobile ? <span className='p3-d-h-t-span txt-anim-19 p3-d-h-f-d'></span>:<span className='p3-d-h-t-span txt-anim-19 p3-d-h-f-d'>flyers for the different schools, roll ups </span>}
                        {!isMobile ? <span className='p3-d-h-t-span txt-anim-20 p3-d-h-f-d'></span>:<span className='p3-d-h-t-span txt-anim-20 p3-d-h-f-d'>and pop ups.</span>}

                    </div>
                    <div className='p3-d-h-t-b2'>
                        <span className='p3-d-h-t-span txt-anim-21 p3-d-h-f'>Developed at Outside The Box</span>
                        {!isMobile ? 
                          <span className='p3-d-h-t-span txt-anim-22 p3-d-h-f'>For the client Conservatório de Música Terras</span>
                          :
                          <span className='p3-d-h-t-span txt-anim-22 p3-d-h-f'>For the client Conservatório de</span>
                        }
                        {!isMobile ? 
                          <span className='p3-d-h-t-span txt-anim-23 p3-d-h-f'>de Santa Maria da Feira.</span>
                          :
                          <span className='p3-d-h-t-span txt-anim-23 p3-d-h-f'>Música Terras de Santa Maria da</span>
                        }
                        {!isMobile ? 
                          <span className='p3-d-h-t-span txt-anim-24 p3-d-h-f-d'></span>
                          :
                          <span className='p3-d-h-t-span txt-anim-24 p3-d-h-f'>Feira.</span>
                        }
                    </div>
                    <div className='p3-d-h-t-b3'>
                        <span className='p3-d-h-t-span txt-anim-25 p3-d-h-f s-down-txt' style={{opacity:`0`}} onClick={ScrollDown}>Scroll Down</span>
                        <span className='p3-d-h-t-span txt-anim-25 p3-d-h-f s-down-txt1' style={{opacity:`0`}} onClick={ScrollDown}><PiArrowCircleDownThin size={!isMobile ? 40:30}/></span>
                    </div>
                </div>
            </div>
        </div>
    );
}