import './Projeto7_Content.css';
import {useEffect, useRef, useState, useMemo} from 'react';

export const Projeto7_Content = (props) =>{

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const ref6 = useRef(null);
    const ref7 = useRef(null);

    useIsInViewport(ref1);
    useIsInViewport(ref2);
    useIsInViewport(ref3);
    useIsInViewport(ref4);
    useIsInViewport(ref5);
    useIsInViewport(ref6);
    useIsInViewport(ref7);

    function useIsInViewport(ref) {
        const [isIntersecting, setIsIntersecting] = useState(false);
      
        const observer = useMemo(
          () =>
            new IntersectionObserver(([entry]) =>
              setIsIntersecting(entry.isIntersecting),
            ),
          [],
        );
    
    useEffect(() => {
            observer.observe(ref.current);
        
            return () => {
              observer.disconnect();
            };
          }, [ref, observer]);

          if(isIntersecting){
            if(ref.current.style?.animation === undefined || ref.current.style?.animation === null || ref.current.style?.animation === ""){
                ref.current.style.opacity = "1";
                //ref.current.style.filter = "blur(0px)";
            }

            props.handleCallBack(ref.current.id.charAt(ref.current.id.length-1));
          }
        
          return isIntersecting;
    }
   

    return(
        <div className='p7-d-c-container'>

            <div className='p7-d-c-images'>

                <div ref={ref1} id='id-p7-content0' className='p7-d-c-i i0'/>
                <div ref={ref2} id='id-p7-content1' className='p7-d-c-i i1'/>
                <div ref={ref3} id='id-p7-content2' className='p7-d-c-i i2'/>
                <div ref={ref4} id='id-p7-content3' className='p7-d-c-i i3'/>
                <div ref={ref5} id='id-p7-content4' className='p7-d-c-i i4'/>
                <div ref={ref6} id='id-p7-content5' className='p7-d-c-i i5'/>
                <div ref={ref7} id='id-p7-content6' className='p7-d-c-i i6'/>
                
            </div>

            
        </div>
    );
}