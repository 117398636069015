import React, { useEffect, useState, useRef, useMemo } from 'react';
import '../../../css/General.css';
import './Projeto8_Header.css';
import ContadorProjetos from '../../ContadorProjetos';
import {PiArrowCircleDownThin} from "react-icons/pi";

export const Projeto8_Header_EN = (props) => {

  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);

    /****RESIZING****/
    const handleResize = () => {
      if(window.matchMedia("(max-width: 768px)").matches){
        setIsMobile(true);
      }
      else{
        setIsMobile(false);
      }
    };

    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);   

    /****-----------****/


  let child1SetState;
  const invokeChild1 = (setStateCallback) => {
    if (setStateCallback) {
      child1SetState = setStateCallback[1];
    } else {
      child1SetState(1);
    }
  };

    function ScrollDown(){
      props.scrollDownFromHeader();
    }

    return(
        <div className='projeto8-detail-container'>
            <div className='proj8-d-header'>
              <div className='proj8-header-contador'>
                <ContadorProjetos cbFunction={invokeChild1} limiteMaximo={window.$numeroProjetos}/>
              </div>
                <div className='p8-d-h-texto-container' style={{ transition: `transform 1s cubic-bezier(0.215, 0.61, 0.355, 1)`,  transform: `translateY(${props.scrollPosition * 0.2}vh)` }}>
                    <h1 className='p8-d-h-t-title'>
                        <span className='p8-d-h-t-span txt-anim-1'>Social Media </span>
                        <span className='p8-d-h-t-span txt-anim-2'>Energia.pt</span>
                    </h1>
                    <div className='p8-d-h-t-b1'>
                        <span className='p8-d-h-t-span txt-anim-3 p8-d-h-f'>Lead Designer</span>
                        <p/>
                        {!isMobile ? 
                          <span className='p8-d-h-t-span txt-anim-4 p8-d-h-f-d'>Energia.pt is a renewable energy company focused on sustainability.</span>
                          :
                          <span className='p8-d-h-t-span txt-anim-4 p8-d-h-f-d'>Energia.pt is a renewable energy </span>
                        }
                        {!isMobile ? 
                          <span className='p8-d-h-t-span txt-anim-5 p8-d-h-f-d'>In this project, the creation of a paid media campaign for Facebook </span>
                          :
                          <span className='p8-d-h-t-span txt-anim-5 p8-d-h-f-d'>company focused on sustainability. </span>
                        }
                        {!isMobile ? 
                          <span className='p8-d-h-t-span txt-anim-6 p8-d-h-f-d'>and Instagram was requested. The task involved increasing brand </span>
                          :
                          <span className='p8-d-h-t-span txt-anim-6 p8-d-h-f-d'>In this project, the creation of a paid </span>
                        }
                        {!isMobile ? 
                          <span className='p8-d-h-t-span txt-anim-7 p8-d-h-f-d'>awareness, generating leads, and boosting sales. </span>
                          :
                          <span className='p8-d-h-t-span txt-anim-7 p8-d-h-f-d'>media campaign for Facebook and </span>
                        }
                        {!isMobile ? 
                          <span className='p8-d-h-t-span txt-anim-8 p8-d-h-f-d'>Besides developing the strategy, identifying the target audience and </span>
                          :
                          <span className='p8-d-h-t-span txt-anim-8 p8-d-h-f-d'>Instagram was requested. The task </span>
                        }
                        {!isMobile ? 
                          <span className='p8-d-h-t-span txt-anim-9 p8-d-h-f-d'>setting objectives, I've also created the creative content. Throughout </span>
                          :
                          <span className='p8-d-h-t-span txt-anim-9 p8-d-h-f-d'>involved increasing brand awareness, </span>
                        }
                        {!isMobile ? 
                          <span className='p8-d-h-t-span txt-anim-10 p8-d-h-f-d'>the campaign, I've monitored the ads performance and made the ne-</span>
                          :
                          <span className='p8-d-h-t-span txt-anim-10 p8-d-h-f-d'>generating leads and boosting sales.</span>
                        }
                        {!isMobile ? 
                          <span className='p8-d-h-t-span txt-anim-11 p8-d-h-f-d'>cessary adjustments to optimize the campaign's results.</span>
                          :
                          <span className='p8-d-h-t-span txt-anim-11 p8-d-h-f-d'>Besides developing the strategy, iden-</span>
                        }

                        
                        {!isMobile ? <span className='p8-d-h-t-span txt-anim-12 p5-d-h-f-d'></span>:<span className='p8-d-h-t-span txt-anim-12 p8-d-h-f-d'>tifying the target audience and setting </span>}
                        {!isMobile ? <span className='p8-d-h-t-span txt-anim-13 p5-d-h-f-d'></span>:<span className='p8-d-h-t-span txt-anim-13 p8-d-h-f-d'>objectives, I've also created the creative </span>}
                        {!isMobile ? <span className='p8-d-h-t-span txt-anim-14 p5-d-h-f-d'></span>:<span className='p8-d-h-t-span txt-anim-14 p8-d-h-f-d'>content.</span>}
                        {!isMobile ? <span className='p8-d-h-t-span txt-anim-15 p5-d-h-f-d'></span>:<span className='p8-d-h-t-span txt-anim-15 p8-d-h-f-d'>Throughout the campaign, I've monito- </span>}
                        {!isMobile ? <span className='p8-d-h-t-span txt-anim-16 p5-d-h-f-d'></span>:<span className='p8-d-h-t-span txt-anim-16 p8-d-h-f-d'>red the ads performance and made the </span>}
                        {!isMobile ? <span className='p8-d-h-t-span txt-anim-17 p5-d-h-f-d'></span>:<span className='p8-d-h-t-span txt-anim-17 p8-d-h-f-d'>necessary adjustments to optimize the </span>}
                        {!isMobile ? <span className='p8-d-h-t-span txt-anim-18 p5-d-h-f-d'></span>:<span className='p8-d-h-t-span txt-anim-18 p8-d-h-f-d'>campaign's results.</span>}
                        
                    </div>
                    <div className='p8-d-h-t-b2'>
                        <span className='p8-d-h-t-span txt-anim-19 p8-d-h-f'>Developed at <a href="https://www.outsidethebox.pt" style={{display:`inline`, pointerEvents:`auto`}}>Outside The Box</a>, <br></br>for <a href="https://energia.pt" style={{display:`inline`, pointerEvents:`auto`}}>Energia.pt</a></span>
                    </div>
                    <div className='p8-d-h-t-b3'>
                        <span className='p8-d-h-t-span txt-anim-20 p8-d-h-f s-down-txt' style={{opacity:`0`}} onClick={ScrollDown}>Scroll Down</span>
                        <span className='p8-d-h-t-span txt-anim-20 p8-d-h-f s-down-txt1' style={{opacity:`0`}} onClick={ScrollDown}><PiArrowCircleDownThin size={!isMobile ? 40:30}/></span>
                    </div>
                </div>
            </div>
        </div>
    );
}