import './AboutContent_WorkExp.css';
import '../../../css/General.css';
import {useEffect, useRef, useState, useMemo} from 'react';

export const AboutContent_WorkExp = (props) =>{

  const language = localStorage.getItem('language') || 'pt';
    const sufixo = props.minimapa ? "s":"r";

    const ref99 = useRef(null); //title

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const ref6 = useRef(null);
    const ref7 = useRef(null);
    const ref8 = useRef(null);
    const ref9 = useRef(null);
    const ref10 = useRef(null);
    const ref11 = useRef(null);
    const ref12 = useRef(null);
    const ref13 = useRef(null);
    const ref14 = useRef(null);
    const ref15 = useRef(null);

    useIsInViewport(ref99); //title

    useIsInViewport(ref1);
    useIsInViewport(ref2);
    useIsInViewport(ref3);
    useIsInViewport(ref4);
    useIsInViewport(ref5);
    useIsInViewport(ref6);
    useIsInViewport(ref7);
    useIsInViewport(ref8);
    useIsInViewport(ref9);
    useIsInViewport(ref10);
    useIsInViewport(ref11);
    useIsInViewport(ref12);
    useIsInViewport(ref13);
    useIsInViewport(ref14);
    useIsInViewport(ref15);

    function useIsInViewport(ref) {
        const [isIntersecting, setIsIntersecting] = useState(false);
      
        const observer = useMemo(
          () =>
            new IntersectionObserver(([entry]) =>
              setIsIntersecting(sufixo == "r" && entry.isIntersecting),
            ),
          [],
        );
    
    useEffect(() => {
            observer.observe(ref.current);
        
            return () => {
              observer.disconnect();
            };
          }, [ref, observer]);

          if(isIntersecting){
            if(ref.current.style?.animation === undefined || ref.current.style?.animation === null || ref.current.style?.animation === ""){
                ref.current.style.animation = "abt-slide-up-text .5s ease-in-out 0.1s forwards";
            }
          }
        
          return isIntersecting;
    }


    return(
        <div className='abt-w-e-container-grid'>

            <div ref={ref99} className={'abt-w-e-t-' + sufixo}>{language === "pt" ? "Experiência Profissional":"Working Experience"}</div>

            {
              language === "pt" ? 

              <div className={'abt-w-e-content-' + sufixo}>
                <div className='abt-w-e-r-1'>
                      <div ref={ref1} className={'abt-w-e-l1' + sufixo} style={{opacity:0}}><b>Designer Gráfico</b></div>
                      <div ref={ref2} className={'abt-w-e-l2' + sufixo} style={{opacity:0}}><i>Abril 2024 - Presente</i></div>
                      <div ref={ref3} className={'abt-w-e-l3' + sufixo} style={{opacity:0}}><b>Alphanna</b>, Porto</div>
                  </div>
                  <div className='abt-w-e-r-2'>
                      <div ref={ref4} className={'abt-w-e-l4' + sufixo} style={{opacity:0}}><b>Designer Gráfico</b></div>
                      <div ref={ref5} className={'abt-w-e-l5' + sufixo} style={{opacity:0}}><i>Janeiro 2016 - Março 2024</i></div>
                      <div ref={ref6} className={'abt-w-e-l6' + sufixo} style={{opacity:0}}><b>Outside The Box</b>, São João da Madeira</div>
                      <div ref={ref7} className={'abt-w-e-l7' + sufixo} style={{opacity:0}}>Funções - Desenvolvimento de marcas;</div>
                      <div ref={ref8} className={'abt-w-e-l8' + sufixo} style={{opacity:0}}>Criação de posters, flyers, exposições (etc);</div>
                      <div ref={ref9} className={'abt-w-e-l9' + sufixo} style={{opacity:0}}>Captação e edição fotográfica;</div>
                  </div>
                  <div className='abt-w-e-r-3'>
                      <div ref={ref10} className={'abt-w-e-l10' + sufixo} style={{opacity:0}}>Outubro 2015 - Janeiro 2016</div>
                      <div ref={ref11} className={'abt-w-e-l11' + sufixo} style={{opacity:0}}>Designer Gráfico</div>
                      <div ref={ref12} className={'abt-w-e-l12' + sufixo} style={{opacity:0}}>Amazing Field, <span>Canedo</span></div>
                  </div>
                  <div className='abt-w-e-r-4'>
                      <div ref={ref13} className={'abt-w-e-l13' + sufixo} style={{opacity:0}}>Junho 2012 - Setembro 2012</div>
                      <div ref={ref14} className={'abt-w-e-l14' + sufixo} style={{opacity:0}}>Designer de Calçado - Estágio</div>
                      <div ref={ref15} className={'abt-w-e-l15' + sufixo} style={{opacity:0}}>Project ID, <span>Arrifana</span></div>
                  </div>
              </div>

              :

              <div className={'abt-w-e-content-' + sufixo}>
                <div className='abt-w-e-r-1'>
                      <div ref={ref1} className={'abt-w-e-l1' + sufixo} style={{opacity:0}}><b>Graphic Designer</b></div>
                      <div ref={ref2} className={'abt-w-e-l2' + sufixo} style={{opacity:0}}><i>April 2024 - Present</i></div>
                      <div ref={ref3} className={'abt-w-e-l3' + sufixo} style={{opacity:0}}><b>Alphanna</b>, Porto</div>
                  </div>
                  <div className='abt-w-e-r-2'>
                      <div ref={ref4} className={'abt-w-e-l4' + sufixo} style={{opacity:0}}><b>Graphic Designer</b></div>
                      <div ref={ref5} className={'abt-w-e-l5' + sufixo} style={{opacity:0}}><i>January  2016 - March 2024</i></div>
                      <div ref={ref6} className={'abt-w-e-l6' + sufixo} style={{opacity:0}}><b>Outside The Box</b>, São João da Madeira</div>
                      <div ref={ref7} className={'abt-w-e-l7' + sufixo} style={{opacity:0}}>Brand development;</div>
                      <div ref={ref8} className={'abt-w-e-l8' + sufixo} style={{opacity:0}}>Posters, flyers, exhibitions planning & creation</div>
                      <div ref={ref9} className={'abt-w-e-l9' + sufixo} style={{opacity:0}}>Photography and editing;</div>
                  </div>
                  <div className='abt-w-e-r-3'>
                      <div ref={ref10} className={'abt-w-e-l10' + sufixo} style={{opacity:0}}>October 2015 - January 2016</div>
                      <div ref={ref11} className={'abt-w-e-l11' + sufixo} style={{opacity:0}}>Graphic Designer</div>
                      <div ref={ref12} className={'abt-w-e-l12' + sufixo} style={{opacity:0}}>Amazing Field, <span>Canedo</span></div>
                  </div>
                  <div className='abt-w-e-r-4'>
                      <div ref={ref13} className={'abt-w-e-l13' + sufixo} style={{opacity:0}}>June 2012 - September 2012</div>
                      <div ref={ref14} className={'abt-w-e-l14' + sufixo} style={{opacity:0}}>Shoe Designer Internship</div>
                      <div ref={ref15} className={'abt-w-e-l15' + sufixo} style={{opacity:0}}>Project ID, <span>Arrifana</span></div>
                  </div>
                </div>

              }

        </div>
    );
}