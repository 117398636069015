import React, { useEffect, useState, useCallback } from "react";
import '../css/MainMenuSlider.css';
import { NavLink, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from "framer-motion";

const animation_props = {
    animate: {
      y: '0dvh',
      clipPath: 'inset(-25% 0% -100% 0%)',
    },
    initial: {
      y: '25dvh',
      clipPath: 'inset(50% 0% 0% 0%)',
    },
    exit: {
      y: '25dvh',
      clipPath: 'inset(50% 0% 0% 0%)',
    },
    transition: {
      duration: 3,
    }
  }

export const MainMenuSlider = ({callback}) => { //{metodoShowMenuPrincipal}) => {

    window.$executarIntro = false;
    window.$slideAnimation = 0;
    window.$primeiraEntradaSlider = false;

    const language = localStorage.getItem('language') || 'pt';

    const setVerticalSlideAnimation = () => {
      window.$slideAnimation = 0;
      callback();
    };
    
  function SelecionarProjeto(projeto){
    window.$projetoAtivo = projeto;
    //metodoShowMenuPrincipal();

    GoToMainPage(projeto);
  }

  const navigate = useNavigate();
  const goToHome = useCallback(() => navigate('/', {replace: true}), [navigate]);

  //var fullScreen = false;
  const [fullscreen, setFullscreen] = useState(false);

  function GoToMainPage(projetoSelecionado){
    //transiciono as imagens que não foram selecionadas
      var imagens = document.getElementsByClassName("m-m-image");
            if(imagens != null && imagens.length > 0){
                var i = 1;

               for (const imagem of imagens) {
                //if(i != projetoSelecionado){
                  setTimeout(function() {
                    imagem.style.animation = `slideBackToMainPage 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s forwards`;
                  }, 100*i);
                //}
                      i++;
                  }
            }

      
      /*var divSelecionada = document.getElementById("m-m-d-" + projetoSelecionado);
      var imagemSelecionada = document.getElementById("m-m-i-" + projetoSelecionado);
      if(imagemSelecionada != null && imagemSelecionada != undefined)
      {*/
        /*divSelecionada.style.transformOrigin = `center`;
        divSelecionada.style.height = `100dvh`;
        divSelecionada.style.width = `100dvw`;
        divSelecionada.style.top = `0`;
        divSelecionada.style.left = `0`;*/

        /*imagemSelecionada.style.zIndex = `-1`;
        imagemSelecionada.style.animation = `zoomInFullMainMenu 5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s forwards`;
        imagemSelecionada.style.border= `none`;*/

        //setFullscreen(true);

        /*imagemSelecionada.style.transformOrigin = `center`;
        imagemSelecionada.style.height = `100dvh`;
        imagemSelecionada.style.width = `100dvw`;
        imagemSelecionada.style.top = `0`;
        imagemSelecionada.style.left = `0`;*/
        
        /*imagemSelecionada.classList.remove("m-m-image");
        imagemSelecionada.classList.add("m-m-image-zoomed");*/
      //}

      setTimeout(function() {
        goToHome();
      }, 800);
  }



  const [nextPerc, setNextPerc] = useState(0);



  const track = document.getElementById("image-track-main-menu");

      const handleOnDown = e => { const track = document.getElementById("image-track-main-menu");
      track.dataset.mouseDownAt = e.clientX;}
      
      const handleOnUp = () => {
        const track = document.getElementById("image-track-main-menu");
        track.dataset.mouseDownAt = "0";  
        track.dataset.prevPercentage = track.dataset.percentage;
      }
      
      const handleOnMove = e => {
        const track = document.getElementById("image-track-main-menu");
        if(track.dataset.mouseDownAt === "0") return;
        
        const mouseDelta = parseFloat(track.dataset.mouseDownAt) - e.clientX,
              maxDelta = window.innerWidth / 2;
        
        const percentage = (mouseDelta / maxDelta) * -100,
              nextPercentageUnconstrained = parseFloat(track.dataset.prevPercentage) + percentage,
              nextPercentage = Math.max(Math.min(nextPercentageUnconstrained, 0), -100);
        
        track.dataset.percentage = nextPercentage;

        setNextPerc(nextPercentage);
        
        /*track.animate({
          transform: `translate(${nextPercentage}%, -50%)`
        }, { duration: 2000, fill: "forwards" });
        
        for(const image of track.getElementsByClassName("image")) {
          image.animate({
            objectPosition: `${100 + nextPercentage}% center`
          }, { duration: 2000, fill: "forwards" });
        }*/
      }

      //TOUCH EVENTS
      const handleTouchStart = (e) => {
        const track = document.getElementById("image-track-main-menu");
        track.dataset.mouseDownAt = e.touches[0].clientX;
      };
    
      const handleTouchMove = (e) => {
        const track = document.getElementById("image-track-main-menu");
        if(track.dataset.mouseDownAt === "0") return;
        
        const mouseDelta = parseFloat(track.dataset.mouseDownAt) - e.touches[0].clientX,
              maxDelta = window.innerWidth / 2;
        
        const percentage = (mouseDelta / maxDelta) * -100,
              nextPercentageUnconstrained = parseFloat(track.dataset.prevPercentage) + percentage,
              nextPercentage = Math.max(Math.min(nextPercentageUnconstrained, 0), -100);
        
        track.dataset.percentage = nextPercentage;

        setNextPerc(nextPercentage);
      };
    
      const handleTouchEnd = () => {
        const track = document.getElementById("image-track-main-menu");
        track.dataset.mouseDownAt = "0";  
        track.dataset.prevPercentage = track.dataset.percentage;
      };


      useEffect(() => {
        if(fullscreen){
          return;
        }

        const track = document.getElementById("image-track-main-menu");
        
        track.animate({
          transform: `translate(${nextPerc}%, -50%)`
        }, { duration: 2000, fill: "forwards" });
        
        for(const image of track.getElementsByClassName("m-m-image")) {
          image.animate({
            objectPosition: `${100 + nextPerc}% center`
          }, { duration: 2000, fill: "forwards" });
        }
      });




    /*useEffect(() => {
      const track = document.getElementById("image-track-main-menu");

      const handleOnDown = e => track.dataset.mouseDownAt = e.clientX;
      
      const handleOnUp = () => {
        track.dataset.mouseDownAt = "0";  
        track.dataset.prevPercentage = track.dataset.percentage;
      }
      
      const handleOnMove = e => {
        if(track.dataset.mouseDownAt === "0") return;
        
        const mouseDelta = parseFloat(track.dataset.mouseDownAt) - e.clientX,
              maxDelta = window.innerWidth / 2;
        
        const percentage = (mouseDelta / maxDelta) * -100,
              nextPercentageUnconstrained = parseFloat(track.dataset.prevPercentage) + percentage,
              nextPercentage = Math.max(Math.min(nextPercentageUnconstrained, 0), -100);
        
        track.dataset.percentage = nextPercentage;
        
        track.animate({
          transform: `translate(${nextPercentage}%, -50%)`
        }, { duration: 2000, fill: "forwards" });
        
        for(const image of track.getElementsByClassName("image")) {
          image.animate({
            objectPosition: `${100 + nextPercentage}% center`
          }, { duration: 2000, fill: "forwards" });
        }
      }
        
        // -- Had to add extra lines for touch events -- 
        
        window.onmousedown = e => handleOnDown(e);
        
        window.ontouchstart = e => handleOnDown(e.touches[0]);
        
        window.onmouseup = e => handleOnUp(e);
        
        window.ontouchend = e => handleOnUp(e.touches[0]);
        
        window.onmousemove = e => handleOnMove(e);
        
        window.ontouchmove = e => handleOnMove(e.touches[0]);
});*/

    return(

        <div className="main-menu-slider-container"
          onMouseDown={handleOnDown}
          //onTouchStart={handleOnDown}
          onMouseUp={handleOnUp}
          //onTouchEnd={handleOnUp}
          onMouseMove={handleOnMove}
          //onTouchMove={handleOnMove}

          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >

        {/*<AnimatePresence>
          {<motion.div {...animation_props} className="m-m-portal" key="m-m-portal!">*/}

          <div className='back-btn' onClick={setVerticalSlideAnimation}>
                <NavLink to="/" className="slideUp-backbtn">
                        {language === "pt" ? "Anterior":"Back"}
                    </NavLink>
                    </div>
            <div id="image-track-main-menu" data-mouse-down-at="0" data-prev-percentage="0">

            {/*https://live.staticflickr.com/65535/53358365641_4d70f22f1b_k.jpg*/}

            <div id="m-m-d-1" className="m-m-div">
              <img id="m-m-i-1" className="m-m-image" src="https://live.staticflickr.com/65535/53371696504_fd4e92c5ef_o.jpg" draggable="false" onClick={() => {SelecionarProjeto(1)}}/>
              {/*<div className="main-menu-overlay">
                    <div className="main-menu-block m-m-block-1"></div>
                    <div className="main-menu-block m-m-block-2"></div>
                    <div className="main-menu-block m-m-block-3"></div>
                    <div className="main-menu-block m-m-block-4"></div>
              </div>*/}
            </div>
              
            <div className="m-m-div"><img className="m-m-image" src="https://live.staticflickr.com/65535/53358824965_b6524ffa52_k.jpg" draggable="false" onClick={() => {SelecionarProjeto(2)}}/></div>
            <div className="m-m-div"><img className="m-m-image" src="https://live.staticflickr.com/65535/53358698349_4deef9c547_k.jpg" draggable="false" onClick={() => {SelecionarProjeto(3)}}/></div>
            <div className="m-m-div"><img className="m-m-image" src="https://live.staticflickr.com/65535/53358698354_4f5a538ddd_k.jpg" draggable="false" onClick={() => {SelecionarProjeto(4)}}/></div>
            <div className="m-m-div"><img className="m-m-image" src="https://live.staticflickr.com/65535/53358698344_a4ce1c4a3f_k.jpg" draggable="false" onClick={() => {SelecionarProjeto(5)}}/></div>
            <div className="m-m-div"><img className="m-m-image" src="https://live.staticflickr.com/65535/53357489817_f655a07dcf_k.jpg" draggable="false" onClick={() => {SelecionarProjeto(6)}}/></div>
            <div className="m-m-div"><img className="m-m-image" src="https://live.staticflickr.com/65535/53411229118_2e18d53dff_k.jpg" draggable="false" onClick={() => {SelecionarProjeto(7)}}/></div>
            <div className="m-m-div"><img className="m-m-image" src="https://live.staticflickr.com/65535/53905940222_9fb68a8efa_k.jpg" draggable="false" onClick={() => {SelecionarProjeto(8)}}/></div>
            <div className="m-m-div"><img className="m-m-image" src="https://live.staticflickr.com/65535/53907277175_76e768579e_k.jpg" draggable="false" onClick={() => {SelecionarProjeto(9)}}/></div>
            <div className="m-m-div"><img className="m-m-image" src="https://live.staticflickr.com/65535/53906836611_8f15402094_k.jpg" draggable="false" onClick={() => {SelecionarProjeto(10)}}/></div>

            {/*<div className="m-m-div"><img className="m-m-image" src="https://camillemormal.com/static/media/d/work/activia/home.jpg?8" draggable="false" onClick={() => {SelecionarProjeto(7)}}/></div>
            <div className="m-m-div"><img className="m-m-image" src="https://camillemormal.com/static/media/d/work/saeco/home.jpg?8" draggable="false" onClick={() => {SelecionarProjeto(8)}}/></div>*/}

            



                {/*<NavLink to="/projeto1" className="m-m-image" onClick={() => {SelecionarProjeto(1)}}>*/}
                {/*<div className="main-menu-overlay">
                    <div className="main-menu-block m-m-block-1"></div>
                    <div className="main-menu-block m-m-block-2"></div>
                    <div className="main-menu-block m-m-block-3"></div>
                    <div className="main-menu-block m-m-block-4"></div>
    </div>*/}
                  {/*<img className="m-m-image" src="https://camillemormal.com/static/media/d/work/gucci/home.jpg?8" draggable="false" onClick={() => {SelecionarProjeto(1)}}/>
                </NavLink>

                <NavLink to="/projeto2" className="m-m-image" onClick={() => {SelecionarProjeto(2)}}>
                  <img className="m-m-image" src="https://camillemormal.com/static/media/d/work/samsung/home.jpg?8" draggable="false" />
                </NavLink>

                <NavLink to="/projeto3" className="m-m-image" onClick={() => {SelecionarProjeto(3)}}>
                <img className="m-m-image" src="https://camillemormal.com/static/media/d/work/rituals/home.jpg?8" draggable="false" onClick={() => {SelecionarProjeto(3)}}/>
                </NavLink>

                <NavLink to="/projeto4" className="m-m-image" onClick={() => {SelecionarProjeto(4)}}>
                <img className="m-m-image" src="https://camillemormal.com/static/media/d/work/moco/home.jpg?8" draggable="false" onClick={() => {SelecionarProjeto(4)}}/>
                </NavLink>

                <NavLink to="/projeto5" className="m-m-image" onClick={() => {SelecionarProjeto(5)}}>
                <img className="m-m-image" src="https://camillemormal.com/static/media/d/work/sennheiser/home.jpg?8" draggable="false" onClick={() => {SelecionarProjeto(5)}}/>
                </NavLink>

                <NavLink to="/projeto6" className="m-m-image" onClick={() => {SelecionarProjeto(6)}}>
                <img className="m-m-image" src="https://camillemormal.com/static/media/d/work/swarovski/home.jpg?8" draggable="false" onClick={() => {SelecionarProjeto(6)}}/>
                </NavLink>

                <NavLink to="/projeto7" className="m-m-image" onClick={() => {SelecionarProjeto(7)}}>
                <img className="m-m-image" src="https://camillemormal.com/static/media/d/work/activia/home.jpg?8" draggable="false" onClick={() => {SelecionarProjeto(7)}}/>
                </NavLink>

                <NavLink to="/projeto8" className="m-m-image" onClick={() => {SelecionarProjeto(8)}}>
                <img className="m-m-image" src="https://camillemormal.com/static/media/d/work/saeco/home.jpg?8" draggable="false" onClick={() => {SelecionarProjeto(8)}}/>
  </NavLink>*/}

                {/*<img className="image" src="https://camillemormal.com/static/media/d/work/gucci/home.jpg?8" draggable="false" onClick={() => {SelecionarProjeto(1)}}/>
                <img className="image" src="https://camillemormal.com/static/media/d/work/samsung/home.jpg?8" draggable="false" onClick={() => {SelecionarProjeto(2)}}/>
                <img className="image" src="https://camillemormal.com/static/media/d/work/rituals/home.jpg?8" draggable="false" onClick={() => {SelecionarProjeto(3)}}/>
                <img className="image" src="https://camillemormal.com/static/media/d/work/moco/home.jpg?8" draggable="false" onClick={() => {SelecionarProjeto(4)}}/>
                <img className="image" src="https://camillemormal.com/static/media/d/work/sennheiser/home.jpg?8" draggable="false" onClick={() => {SelecionarProjeto(5)}}/>
                <img className="image" src="https://camillemormal.com/static/media/d/work/swarovski/home.jpg?8" draggable="false" onClick={() => {SelecionarProjeto(6)}}/>
                <img className="image" src="https://camillemormal.com/static/media/d/work/activia/home.jpg?8" draggable="false" onClick={() => {SelecionarProjeto(7)}}/>
                <img className="image" src="https://camillemormal.com/static/media/d/work/saeco/home.jpg?8" draggable="false" onClick={() => {SelecionarProjeto(8)}}/>*/}

                {/*BACK UP IMAGENS
                <img className="image" src="https://images.unsplash.com/photo-1524781289445-ddf8f5695861?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80" draggable="false" />
                <img className="image" src="https://images.unsplash.com/photo-1610194352361-4c81a6a8967e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1674&q=80" draggable="false" />
                <img className="image" src="https://images.unsplash.com/photo-1618202133208-2907bebba9e1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80" draggable="false" />
                <img className="image" src="https://images.unsplash.com/photo-1495805442109-bf1cf975750b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80" draggable="false" />
                <img className="image" src="https://images.unsplash.com/photo-1548021682-1720ed403a5b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80" draggable="false" />
                <img className="image" src="https://images.unsplash.com/photo-1496753480864-3e588e0269b3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2134&q=80" draggable="false" />
                <img className="image" src="https://images.unsplash.com/photo-1613346945084-35cccc812dd5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1759&q=80" draggable="false" />
                <img className="image" src="https://images.unsplash.com/photo-1516681100942-77d8e7f9dd97?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80" draggable="false" /> */}
            </div>
            {/*</motion.div>}
        </AnimatePresence>*/}
        </div>
    );
}