import React, { useEffect, useState, useRef, useMemo } from 'react';
import '../../../css/General.css';
import './Projeto4_Header.css';
import ContadorProjetos from '../../ContadorProjetos';
import {PiArrowCircleDownThin} from "react-icons/pi";

export const Projeto4_Header = (props) => {

  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);

    /****RESIZING****/
    const handleResize = () => {
      if(window.matchMedia("(max-width: 768px)").matches){
        setIsMobile(true);
      }
      else{
        setIsMobile(false);
      }
    };

    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);   

    /****-----------****/

  let child1SetState;
  const invokeChild1 = (setStateCallback) => {
    if (setStateCallback) {
      child1SetState = setStateCallback[1];
    } else {
      child1SetState(4);
    }
  };

    function ScrollDown(){
      props.scrollDownFromHeader();
    }

    return(
        <div className='projeto4-detail-container'>
            <div className='proj4-d-header'>
              <div className='proj4-header-contador'>
                <ContadorProjetos cbFunction={invokeChild1} limiteMaximo={window.$numeroProjetos}/>
              </div>
                <div className='p4-d-h-texto-container' style={{ transition: `transform 1s cubic-bezier(0.215, 0.61, 0.355, 1)`,  transform: `translateY(${props.scrollPosition * 0.2}vh)` }}>
                    <h1 className='p4-d-h-t-title'>
                        <span className='p4-d-h-t-span txt-anim-1'>Azores Tek</span>
                    </h1>
                    <div className='p4-d-h-t-b1'>
                        <span className='p4-d-h-t-span txt-anim-2 p4-d-h-f'>Lead Designer</span>
                        <p/>
                        {!isMobile ? 
                          <span className='p4-d-h-t-span txt-anim-3 p4-d-h-f-d'>O AZORESTEK é um evento dedicado à tecnologia, às empresas,</span>
                          :
                          <span className='p4-d-h-t-span txt-anim-3 p4-d-h-f-d'>O AZORESTEK é um evento dedicado</span>
                        }
                        {!isMobile ? 
                          <span className='p4-d-h-t-span txt-anim-4 p4-d-h-f-d'>à inovação e ao empreendedorismo, que se realizou na cidade de</span>
                          :
                          <span className='p4-d-h-t-span txt-anim-4 p4-d-h-f-d'>à tecnologia, às empresas, à inovação</span>
                        }
                        {!isMobile ? 
                          <span className='p4-d-h-t-span txt-anim-5 p4-d-h-f-d'>Lagoa, na ilha de São Miguel.</span>
                          :
                          <span className='p4-d-h-t-span txt-anim-5 p4-d-h-f-d'>e ao empreendedorismo, que se reali-</span>
                        }
                        {!isMobile ? 
                          <span className='p4-d-h-t-span txt-anim-6 p4-d-h-f-d'>O evento tinha como objetivo alavancar projetos e negócios, tra-</span>
                          :
                          <span className='p4-d-h-t-span txt-anim-6 p4-d-h-f-d'>zou na cidade de Lagoa, na ilha de São </span>
                        }
                        {!isMobile ? 
                          <span className='p4-d-h-t-span txt-anim-7 p4-d-h-f-d'>zendo à cidade os mais diversos oradores e projetos de tech.</span>
                          :
                          <span className='p4-d-h-t-span txt-anim-7 p4-d-h-f-d'>Miguel. </span>
                        }
                        {!isMobile ? 
                          <span className='p4-d-h-t-span txt-anim-8 p4-d-h-f-d'></span>
                          :
                          <span className='p4-d-h-t-span txt-anim-8 p4-d-h-f-d'>O evento tinha como objetivo alavancar </span>                        
                        }
                        {!isMobile ? 
                          <span className='p4-d-h-t-span txt-anim-9 p4-d-h-f-d'>Este projeto consistiu na criação de toda a imagem gráfica para o</span>
                          :
                          <span className='p4-d-h-t-span txt-anim-9 p4-d-h-f-d'>projetos e negócios, trazendo à cidade </span>
                        }
                        {!isMobile ? 
                          <span className='p4-d-h-t-span txt-anim-10 p4-d-h-f-d'>evento, desde logotipo, programa, lonas, decoração do espaço,</span>
                          :
                          <span className='p4-d-h-t-span txt-anim-10 p4-d-h-f-d'>os mais diversos oradores e projetos de </span>
                        }
                        {!isMobile ? 
                          <span className='p4-d-h-t-span txt-anim-11 p4-d-h-f-d'>website, publicidade online e offline, T-shirts para o staff, credenci-</span>
                          :
                          <span className='p4-d-h-t-span txt-anim-11 p4-d-h-f-d'>tech. Este projeto consistiu na criação </span>
                        }
                        {!isMobile ? 
                          <span className='p4-d-h-t-span txt-anim-12 p4-d-h-f-d'>ais , e até brindes como lápis, blocos, sacos, etc.</span>
                          :
                          <span className='p4-d-h-t-span txt-anim-12 p4-d-h-f-d'>de toda a imagem gráfica para o evento, </span>
                        }
                        {!isMobile ? <span className='p4-d-h-t-span txt-anim-13 p4-d-h-f-d'></span>:<span className='p4-d-h-t-span txt-anim-13 p4-d-h-f-d'>desde o logotipo ao programa, lonas, de-</span>}
                        {!isMobile ? <span className='p4-d-h-t-span txt-anim-14 p4-d-h-f-d'></span>:<span className='p4-d-h-t-span txt-anim-14 p4-d-h-f-d'>coração do espaço, website, publicidade </span>}
                        {!isMobile ? <span className='p4-d-h-t-span txt-anim-15 p4-d-h-f-d'></span>:<span className='p4-d-h-t-span txt-anim-15 p4-d-h-f-d'>online e offline, T-shirts para o staff, cre-</span>}
                        {!isMobile ? <span className='p4-d-h-t-span txt-anim-16 p4-d-h-f-d'></span>:<span className='p4-d-h-t-span txt-anim-16 p4-d-h-f-d'>denciais e até brindes como lápis, blocos, </span>}
                        {!isMobile ? <span className='p4-d-h-t-span txt-anim-17 p4-d-h-f-d'></span>:<span className='p4-d-h-t-span txt-anim-17 p4-d-h-f-d'>sacos, etc.</span>}

                    </div>
                    <div className='p4-d-h-t-b2'>
                        {!isMobile ? 
                          <span className='p4-d-h-t-span txt-anim-18 p4-d-h-f'>Desenvolvido na empresa Outside The Box</span>
                          :
                          <span className='p4-d-h-t-span txt-anim-18 p4-d-h-f'>Desenvolvido na Outside The Box</span>
                        }
                        {!isMobile ? 
                          <span className='p4-d-h-t-span txt-anim-19 p4-d-h-f'>Com cenografia dos designers Joana Santos e Hugo Silva</span>
                          :
                          <span className='p4-d-h-t-span txt-anim-19 p4-d-h-f'>Com cenografia dos designers </span>
                        }
                        {!isMobile ? 
                          <span className='p4-d-h-t-span txt-anim-20 p4-d-h-f'>Evento organizado pela Like a Pro e com apoio da Nonagon </span>
                          :
                          <span className='p4-d-h-t-span txt-anim-20 p4-d-h-f'>Joana Santos e Hugo Silva. </span>
                        }
                        {!isMobile ? 
                          <span className='p4-d-h-t-span txt-anim-21 p4-d-h-f'>Parque de ciência e tecnologia de São Miguel</span>
                          :
                          <span className='p4-d-h-t-span txt-anim-21 p4-d-h-f'>Evento organizado pela Like a Pro </span>
                        }
                        {!isMobile ? <span className='p4-d-h-t-span txt-anim-22 p4-d-h-f-d'></span>:<span className='p4-d-h-t-span txt-anim-22 p4-d-h-f'>e com apoio da Nonagon Parque de</span>}
                        {!isMobile ? <span className='p4-d-h-t-span txt-anim-23 p4-d-h-f-d'></span>:<span className='p4-d-h-t-span txt-anim-23 p4-d-h-f'>ciência e tecnologia de São Miguel</span>}
                        
                    </div>
                    <div className='p4-d-h-t-b3'>
                        <span className='p4-d-h-t-span txt-anim-24 p4-d-h-f s-down-txt' style={{opacity:`0`}} onClick={ScrollDown}>Scroll Down</span>
                        <span className='p4-d-h-t-span txt-anim-24 p4-d-h-f s-down-txt1' style={{opacity:`0`}} onClick={ScrollDown}><PiArrowCircleDownThin size={!isMobile ? 40:30}/></span>
                    </div>
                </div>
            </div>
        </div>
    );
}