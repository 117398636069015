import './AboutContent_Socials.css';
import '../../../css/General.css';
import {useEffect, useRef, useState, useMemo} from 'react';

export const AboutContent_Socials = (props) =>{

  const language = localStorage.getItem('language') || 'pt';
  
    const sufixo = props.minimapa ? "s":"r";

    const ref99 = useRef(null); //title
    const ref100 = useRef(null); //title credits

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);

    useIsInViewport(ref99); //title
    useIsInViewport(ref100); //title credits

    useIsInViewport(ref1);
    useIsInViewport(ref2);
    useIsInViewport(ref3);
    useIsInViewport(ref4);

    function useIsInViewport(ref) {
        const [isIntersecting, setIsIntersecting] = useState(false);
      
        const observer = useMemo(
          () =>
            new IntersectionObserver(([entry]) =>
              setIsIntersecting(sufixo == "r" && entry.isIntersecting),
            ),
          [],
        );
    
    useEffect(() => {
            observer.observe(ref.current);
        
            return () => {
              observer.disconnect();
            };
          }, [ref, observer]);

          if(isIntersecting){
            if(ref.current.style?.animation === undefined || ref.current.style?.animation === null || ref.current.style?.animation === ""){
                ref.current.style.animation = "abt-slide-up-text .5s ease-in-out 0.1s forwards";
            }
          }
        
          return isIntersecting;
    }

    return(
        <div className='abt-soc-container-grid'>

            <div ref={ref99} className={'abt-soc-t-' + sufixo} style={{opacity:0}}>Socials</div>

            <div className={'abt-soc-content-' + sufixo}>
                <div className='abt-soc-r-1'>
                    <a ref={ref1} className={'a-s-l abt-soc-l1' + sufixo} style={{opacity:0}} href="mailto:saraferreira1993@gmail.com">E-mail</a>
                    <a ref={ref2} className={'a-s-l abt-soc-l2' + sufixo} style={{opacity:0}} href="https://www.linkedin.com/in/do-better-sara">Linkedin</a>
                    <a ref={ref3} className={'a-s-l abt-soc-l3' + sufixo} style={{opacity:0}} href="https://www.behance.net/SaraFerreira_">Behance</a>
                </div>
            </div>

            <div ref={ref100} className={'abt-soc-t-c' + sufixo} style={{opacity:0}}>Credits</div>
            <a ref={ref4} className={'a-s-l abt-soc-l4' + sufixo} style={{opacity:0}} href="https://www.instagram.com/ricardo.santos.dev">{language === "pt" ? "Desenvolvido por":"Developed by"} Ricardo Oliveira</a>
        </div>
    );
}