import React, { useState, useEffect } from 'react';
import '../css/ContadorProjetos.css';
import '../css/General.css';

const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

const ContadorProjetos = ({cbFunction, limiteMaximo}) =>{

    const [proximoProjeto, setProximoProjeto] = useState(window.$projetoAtivo);
    const numeroProjetos = limiteMaximo > 0 ? limiteMaximo:10;
    const [projetoAtual, setProjetoAtual] = useState(window.$projetoAtivo);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
            if(!loaded){
                var numero = document.getElementById("c-p-num" + window.$projetoAtivo);
                if(numero != null && numero != undefined){
                    numero.classList.remove('contador-numero-inativo');
                    numero.classList.add('contador-numero-ativo');
                }
                setLoaded(true);
            }
        }
    );

    useEffect(() => {
        cbFunction([proximoProjeto, setProximoProjeto]);
        
        var numero = document.getElementById("c-p-num" + projetoAtual);
        var proxNumero = document.getElementById("c-p-num" + proximoProjeto);
        
        if(numero === undefined || proxNumero === null)
            return;

        if(proxNumero === undefined || proxNumero === null)
            return;

        numero.classList.remove('contador-numero-ativo');
        numero.classList.add('contador-numero-inativo');

        proxNumero.classList.remove('contador-numero-inativo');
        proxNumero.classList.add('contador-numero-ativo');

        setProjetoAtual(proximoProjeto);
    }, [cbFunction, proximoProjeto]);

    return(
        <div className='counter-p-container'>

            <div className="contador-holder"> 
                <span id='c-p-num1' className='contador-numero-inativo'>1</span>
                <span id='c-p-num2' className='contador-numero-inativo'>2</span>
                <span id='c-p-num3' className='contador-numero-inativo'>3</span>
                <span id='c-p-num4' className='contador-numero-inativo'>4</span>
                <span id='c-p-num5' className='contador-numero-inativo'>5</span>
                <span id='c-p-num6' className='contador-numero-inativo'>6</span>
                <span id='c-p-num7' className='contador-numero-inativo'>7</span>
                <span id='c-p-num8' className='contador-numero-inativo'>8</span>
                <span id='c-p-num9' className='contador-numero-inativo'>9</span>
                <span id='c-p-num10' className='contador-numero-inativo'>10</span>
            </div>
            <div>
                &nbsp;— {numeroProjetos}
            </div>
        </div>
    );
}

export default ContadorProjetos;