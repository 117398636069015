import React, { useState, useRef, useEffect } from 'react';
import '../../css/SmallMenuSliderV1.css';
import '../../css/SmallMenuSlider.css';
import { FaAngleDoubleRight } from "react-icons/fa";
import { FaAngleDoubleLeft } from "react-icons/fa";
import {useLocation} from 'react-router-dom';
import { SmallMenuSlider } from '../SmallMenuSlider';

export const SmallMenuSliderV1 = (props) => {
  const [scrollIndex, setScrollIndex] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollStartIndex, setScrollStartIndex] = useState(0);

  const [transicaoInicial, setTransicaoInicial] = useState(!props.showMainMenu);
  const [bootingComponente, setBootingComponente] = useState(true);

  const [mobileMenu, setMobileMenu] = useState(window.innerWidth <= 1580);

  
  useEffect(() => {
    const handleResize = () => {
      if(window.innerWidth <= 1580){
        setMobileMenu(true);
      }
      else{
        setMobileMenu(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const totalImages = 10;
  const visibleImages = 6;
  /*const images = Array.from({ length: totalImages }, (_, index) => ({
    id: index + 1,
    src: `https://via.placeholder.com/150?text=Image+${index + 1}`,
  }));*/

  //-------------STARTMENU MOBILE----------------

  const images = [
    {id:1, alt:"Vihupole", url:"https://live.staticflickr.com/65535/53371696504_fd4e92c5ef_o.jpg"},
    {id:2, alt:"Santorini Shoes", url:"https://live.staticflickr.com/65535/53358824965_b6524ffa52_k.jpg"},
    {id:3, alt:"Conservatório Música", url:"https://live.staticflickr.com/65535/53358698349_4deef9c547_k.jpg"},
    {id:4, alt:"Azores Tek", url:"https://live.staticflickr.com/65535/53358698354_4f5a538ddd_k.jpg"},
    {id:5, alt:"Andrik", url:"https://live.staticflickr.com/65535/53358698344_a4ce1c4a3f_k.jpg"},
    {id:6, alt:"Transtech", url:"https://live.staticflickr.com/65535/53357489817_f655a07dcf_k.jpg"},
    {id:7, alt:"Sanjotec", url:"https://live.staticflickr.com/65535/53411229118_2e18d53dff_k.jpg"},
    {id:8, alt:"Energia.pt", url:"https://live.staticflickr.com/65535/53905940222_9fb68a8efa_k.jpg"},
    {id:9, alt:"Stand Abílio Pinto", url:"https://live.staticflickr.com/65535/53907277175_76e768579e_k.jpg"},
    {id:10, alt:"Joana. beauty", url:"https://live.staticflickr.com/65535/53906836611_8f15402094_k.jpg"}
  ];

  const handleTouchStart = (e) => {
    setDragging(true);
    props.draggingStatusCallBack(true);
    setStartX(e.touches[0].clientX);
    setScrollStartIndex(scrollIndex);
  };

  const handleTouchMove = (e) => {
    if (!dragging) return;
    const touchX = e.touches[0].clientX;
    const diffX = startX - touchX;
    const imageWidth = e.currentTarget.offsetWidth / visibleImages;

    const newScrollIndex = scrollStartIndex + Math.round(diffX / imageWidth);
    setScrollIndex(Math.max(0, Math.min(totalImages - visibleImages, newScrollIndex)));
  };

  const handleTouchEnd = () => {
    setDragging(false);
    props.draggingStatusCallBack(false);
  };

  const scrollLeft = () => {
    setScrollIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const scrollRight = () => {
    setScrollIndex((prevIndex) => Math.min(prevIndex + 1, totalImages - visibleImages));
  };


  //OPACITY DOS BOTÕES - EFEITO PISCAR
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    // Set up the interval to toggle opacity every 2 seconds
    const intervalId = setInterval(() => {
      setOpacity(prevOpacity => (prevOpacity === 1 ? 0.2 : 1));
    }, 1500);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);


  //---------------END MENU MOBILE---------------

  //----------------ANIMAÇÃO SLIDE INICIAL-------------

  function andaLaMenu(inverso){
    /*var isMobileMenu = mobileMenu;
    if(inverso){isMobileMenu = !isMobileMenu;}
    isMobileMenu = true;*/
    if(transicaoInicial){
      //var track = document.getElementById("sm-i-t");
      var imagens = document.getElementsByClassName("sm-image-item");
      var btnLeft = document.getElementById("sm-btn-left");
      var btnRight = document.getElementById("sm-btn-right");
      if(imagens != null && imagens.length > 0){
        //track.style.animation = `generic_fade_in 2s ease`;
          var i = 1;

          btnLeft.style.animation = `slideInTrack3 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s forwards`;
          for (const imagem of imagens) {
              setTimeout(function() {
                  imagem.style.animation = `slideInTrack3 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s forwards`;
                }, 100*i);

                i++;
            }
            btnRight.style.animation = `slideInTrack3 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s forwards`;
          setTransicaoInicial(false);
          setBootingComponente(false);
      }
    }
    else{

        if(props.showMainMenu && location.pathname === '/'){
            var imagens = document.getElementsByClassName("sm-image-item");
            var btnLeft = document.getElementById("sm-btn-left");
            var btnRight = document.getElementById("sm-btn-right");
            if(imagens != null && imagens.length > 0){
                var i = 1;

                btnLeft.style.animation = `slideInTrack2 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s forwards`;
                for (const imagem of imagens) {
                    setTimeout(function() {
                        imagem.style.animation = `slideInTrack2 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s forwards`;
                      }, 100*i);

                      canChangeToMainMenu = i === window.$numeroProjetos;
                      i++;
                  }
                  btnRight.style.animation = `slideInTrack2 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s forwards`;
            }

            if(canChangeToMainMenu){
                setTimeout(function() {
                    props.callbackMainMenu();
                  }, 800);
            }
        }
        /*else if(!bootingComponente){
          console.log("BOOTING COMPONENTE");
          var imagens = document.getElementsByClassName("sm-image-item");
          if(imagens != null && imagens.length > 0){
              var i = 1;

              for (const imagem of imagens) {
                imagem.style.opacity = `1`;
                }
          }
        }*/
    }
    clearTimeout(timerRef.current);
  }


  const location = useLocation();

    const timerRef = useRef(null);
    var canChangeToMainMenu = false;

    useEffect(() => {
      andaLaMenu();
      //andaLaMenu(true);
    });

    //--------------END ANIMAÇÃO SLIDE INICIAL-----------------

  return (
    <>{mobileMenu ? 
    <div className="sm-image-slider">
      <button id="sm-btn-left" className="sm-scroll-button" onClick={scrollLeft} disabled={scrollIndex === 0} style={{ opacity: opacity, transition: "opacity 3s ease" }}>
        <FaAngleDoubleLeft size={20}/>
      </button>
      <div
        className="sm-image-container"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div id='sm-i-t'
          className="sm-image-track"
          style={{
            transform: `translateX(-${scrollIndex * (100 / (mobileMenu ? visibleImages - 1 : visibleImages))}%)`,
          }}
        >
          {images.map((image) => (
            <div key={image.id} className="sm-image-item" style={{opacity:`0`}} onClick={() => {props.handleCallback(image.id - 1)}}>
              <img src={image.url} alt={image.alt} draggable={false} />
            </div>
          ))}
        </div>
      </div>
      <button id="sm-btn-right" className="sm-scroll-button" onClick={scrollRight} disabled={scrollIndex === totalImages - visibleImages} style={{ opacity: opacity, transition: "opacity 3s ease" }}>
        <FaAngleDoubleRight size={20}/>
      </button>
    </div>

    :

    <SmallMenuSlider handleCallback={props.handleCallback} showMainMenu={props.showMainMenu} callbackMainMenu={props.callbackMainMenu}/>
    }
    </>
  );
};